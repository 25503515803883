import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string'

import { withStyles } from '@material-ui/core/styles';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

/*import Button from "CustomButtons/Button.jsx";*/

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from "Card/Card.jsx";
/*import CardBody from "Card/CardBody.jsx";*/
import { cardTitle } from "assets/jss/material-kit-react.jsx";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "CustomButtons/Button.jsx";

import exam_status_success_icon from "assets/img/newicons/exam_status_success.svg";
import exam_status_unattempted_icon from "assets/img/newicons/exam_status_unattempted.svg";
//import app_icon from "assets/img/newicons/application.svg";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import TextStyle from "styles/TextStyle.jsx";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";


/*
	TO BE USED ONLY FOR TESTING OR DEMO PURPOSE
*/
// var testDataForTesting = {
// 	"tests":[
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1},
// 	{"id":20,"name":"Test reattempt 123","identifier":"TR1245","description":"","global_user_groups_info_id":1,"status":null,"total_papers":1,"created_at":"2017-09-02T11:58:37.000Z","updated_at":"2017-09-02T11:58:37.000Z","global_test_type_id":1,"seconds_to_start":-30556859,"start_time":"2017-09-02T17:29:00.000Z","end_time":null,"num_of_papers":1}
// 	]
// }

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingBottom: "5px",
    margin: "auto",
    minWidth: "150px",
    maxWidth: "200px",
    textAlign: "center",
  	whiteSpace: "nowrap",
  	overflow: "hidden",
  	textOverflow: "ellipsis"
  	
  },
  title:{
  	padding: "0 24px",
  	...cardTitle
  },
  paper: {
    height: 140,
    width: 100,
    margin: "auto",
    marginBottom: "10px"
  },
  selected: {
   backgroundColor: "#3f51b5",
   "color": "white !important"},
  ...imagesStyle,
  ...TextStyle
});

class TestsListingContainer extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	testTypesData: null,
	    	selectedTabIndex: 0,
	    	liveTestsDisplayed: null,
	    	attemptedTestsDisplayed: null
	    };
	}
	
	componentDidMount() {
		if(!isUserSessionActive())
		{
		 	this.props.history.push('/login');
		 	return
		}

		var test_type_id_url = qs.parse(this.props.location.search).test_type_id;
		var index_url = qs.parse(this.props.location.search).index;
      
      $.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-test-types.json',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({testTypesData: data});
        	//Default test for first test type
        	if(!data["test_types"] || data["test_types"].length < 1)
        	{
        		alert("You do not have any scheduled tests. Please ask test administrator to schedule a test.");
        		return
        	}


        	var test_type_id =  data["test_types"][0].id; // TODO Null pointer handling

        	if(test_type_id_url!= null && test_type_id_url!=""){
        		test_type_id = test_type_id_url;
        		if(index_url!=null){
        			this.setState({ selectedTabIndex: parseInt(index_url) });
        		}
        	}

	  		$.ajax({
	          type: "GET",
	          url: getBaseURL() + '/get-user-upcoming-tests?test_type_ids='+ test_type_id+'&limit=10',
	          dataType: "JSON",
	          //headers: JSON.parse(localStorage.user)
	        }).done((data) => {
	        	this.setState({liveTestsDisplayed: data});
	        });    

	        $.ajax({
	          type: "GET",
	          url: getBaseURL() + '/get-user-attempted-tests?test_type_ids='+ test_type_id+'&limit=10',
	          dataType: "JSON",
	          //headers: JSON.parse(localStorage.user)
	        }).done((data) => {
	        	this.setState({attemptedTestsDisplayed: data});
	        });    


        });    
  	}

  	handleSelectedTab = (event, value) => {
  		if(!isUserSessionActive())
  		{
  		 	this.props.history.push('/login');
  		 	return
  		}

  		const testTypesData = this.state.testTypesData["test_types"];
  		//TODO Throw error when above data doesn't exist 
  		const test_type_id =  testTypesData[value].id; // TODO Null pointer handling

  		$.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-upcoming-tests?test_type_ids='+ test_type_id+'&limit=10',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({liveTestsDisplayed: data});
        });    

        $.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-attempted-tests?test_type_ids='+ test_type_id+'&limit=10',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({attemptedTestsDisplayed: data});
        });    

        this.setState({ selectedTabIndex: value });
   };

  	goToTest = (e) =>{
  		this.props.history.push('/home/enterprise/testHome?test_id=' + e.currentTarget.id); //TODO error handling
  	}


	render() {
		 const { classes} = this.props;
		 const { testTypesData, selectedTabIndex, liveTestsDisplayed, attemptedTestsDisplayed} = this.state;
		return (
			<div>
			<GridContainer style={{margin: "auto"}}>
			{/*testTypesData ? testTypesData["test_types"].map((item, index) => (
          		<GridItem key={index} xs={12} sm={12} md={12} style={{margin: "auto"}}> 
	          		<Card style={{backgroundColor: "#e4e7ed", marginTop: "10px", marginBottom: "10px" }}>
					<h4 className={classes.title}>{item.name}</h4>
					<ExpansionPanel  defaultExpanded>
			          <ExpansionPanelSummary   expandIcon={<ExpandMoreIcon />}>
			           {/* <Typography className={classes.heading}></Typography>}
			            <Typography className={classes.secondaryHeading}>Total Tests: 5</Typography>
			            <Typography className={classes.secondaryHeading}>Attempted Tests: 3</Typography>
			            <Typography className={classes.secondaryHeading}>Unattempted Tests: 2</Typography>
			          </ExpansionPanelSummary>
			          <ExpansionPanelDetails >
			           	<Grid container spacing={16}>
		                  	<Grid item xs={12}>
		                  		
			                    <Grid container justify="space-around" spacing={16}>
			                      {[0, 1, 2, 3, 4, 5].map(value => (
			                        <Grid key={value} item>
			                          <Paper className={classes.paper} >	
			                          </Paper>
			                          <Typography className={classes.secondaryHeading}>Name</Typography>
			                          <Typography className={classes.secondaryHeading}>Details...</Typography>
			                        </Grid>
			                      ))}
			                    </Grid>
		                	</Grid>
		                </Grid>
			          </ExpansionPanelDetails>
			        </ExpansionPanel>
			        </Card>
		        </GridItem>
		    )) : null */}
		    <GridItem  xs={12} sm={12} md={12} style={{margin: "auto",  padding: "20px", paddingTop: "0px"}}> 
		    <div style={{paddingBottom: "5px"}}>
		    	<span className={classes.robotoFontRegularLabel}>Test Type : </span>
		    	<span className={classes.robotoFontRegular} >{testTypesData && testTypesData["test_types"] && testTypesData["test_types"].length > 1 ? testTypesData["test_types"][this.state.selectedTabIndex].name : null}</span>
		    </div>
		    <Tabs
	            value={selectedTabIndex}
	            onChange={this.handleSelectedTab}
	            indicatorColor="primary"
	            textColor="primary"
	            fullWidth
	          >

	          {testTypesData ? testTypesData["test_types"].map((item, index) => (
				<Tab key={index} label={item.short_name} 
				style={{minWidth: "100px",fontSize: "13px",
					boxShadow: "0px 2px 10px 0px rgba(153, 153, 153, 0.2), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.2)",
					margin: "5px", padding:"6px 24px"}}
					classes={{
					    selected: classes.selected
					  }}
				/>
			)): null}	
	      	
	      	</Tabs>
	      	</GridItem>
	      	{/*<GridItem  xs={12} sm={12} md={12} style={{margin: "auto"}}>
	      		<h4 >{testTypesData ? testTypesData["test_types"][this.state.selectedTabIndex].name : null}</h4>
	      		<hr/>
	      	</GridItem>*/}
	      	<GridItem  xs={12} sm={12} md={12} style={{margin: "auto", padding: "20px", paddingTop: "0px"}}>
	      		<div>
	      			<hr className={classes.lineBreak} style={{margin: "5px", width: "5%", display: "inline-block"}}/>
	      			<span className={classes.subtitle} >Upcoming Tests</span>
			    	<hr className={classes.lineBreak} style={{margin: "5px", width: "70%", display: "inline-block"}}/>	 		
			    </div>
			        <Grid container justify="flex-start" spacing={16}>
				      	{liveTestsDisplayed ? liveTestsDisplayed["tests"].map((testItem, index) => (
								<Grid key={index}  item style={{padding: "20px"}} >
						          <Paper className={classes.paper} style={{boxShadow:"none"}}>	
						          <Button
						          color="transparent"
						          onClick={this.goToTest}
						          id={testItem.id}
						          style={{ height: "100%", padding: "20px"}}
						          >
						          <img
						            id={testItem.id}
						              className={
						                classes.imgRounded +
						                " " +
						                classes.imgFluid
						              }
						              style={{width: "100%"}}
						              src={exam_status_unattempted_icon}
						             alt=""
						            />
						          </Button>
			                      </Paper>
			                      <Typography className={classes.secondaryHeading}>{testItem.name}</Typography>
			                     <Typography className={classes.secondaryHeading}>{formatDate((testItem.start_time))}</Typography>
			                    {/* <Typography className={classes.secondaryHeading}>Start Test</Typography>*/}
			                    </Grid>
							)): <p>No Tests to display.</p>}
	                {liveTestsDisplayed ? liveTestsDisplayed["tests"].length == 0 ? <p style={{padding: "20px"}}>No Tests to display.</p>: null :null}
	                </Grid>

	        </GridItem>
	        <GridItem  xs={12} sm={12} md={12} style={{margin: "auto", padding: "20px", paddingTop: "0px"}}>
	      		<div style={{marginTop: "5px", marginBottom: "5px"}}>
	      			<hr className={classes.lineBreak} style={{margin: "5px", width: "5%", display: "inline-block"}}/>
	      			<span className={classes.subtitle} >Completed Tests</span>
			    	<hr className={classes.lineBreak} style={{margin: "5px", width: "70%", display: "inline-block"}}/>	 		
			    </div>
			        		
			        <Grid container justify="flex-start" spacing={16}>
				      	{attemptedTestsDisplayed ? attemptedTestsDisplayed["tests"].map((testItem, index) => (
								<Grid key={index}  item style={{padding: "20px"}} >
			                      <Paper className={classes.paper} style={{boxShadow:"none"}}>	
						          <Button
						          color="transparent"
						          onClick={this.goToTest}
						          id={testItem.id}
						          style={{height: "100%", padding: "20px"}}
						          >
						          <img
						              id={testItem.id}
						              className={
						                classes.imgRounded +
						                " " +
						                classes.imgFluid
						              }
						              style={{width: "100%"}}
						              src={exam_status_success_icon}
						             alt=""
						            />
						          </Button>
			                      </Paper>
			                      <Typography className={classes.secondaryHeading}>{testItem.name}</Typography>
			                      <Typography className={classes.secondaryHeading}>{formatDate((testItem.start_time))}</Typography>
			                      {/*<Typography className={classes.secondaryHeading}>Analysis | Reattempt Test</Typography>*/}
			                    </Grid>
							)): <p>No Tests to display.</p>}
				      	{attemptedTestsDisplayed ? attemptedTestsDisplayed["tests"].length == 0 ? <p style={{padding: "20px"}}>No Tests to display.</p>: null :null}
	                </Grid>
	        </GridItem>

			</GridContainer>	
			</div>	
			);
	}

}

export default withStyles(styles)(TestsListingContainer);
