import React from "react";

/*import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";*/

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import QuestionImages from "components/test/view/QuestionImages.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import QuestionText from "components/test/view/QuestionText.jsx";
/*import test_img1 from "assets/img/image1.png";*/


class MultipleAnswerOptions extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    value: "",
		    checked: [],
		    answer: ""
		  };
	  }


	handleToggle(value) {
	    var { checked } = this.state;
	    const currentIndex = checked.indexOf(value);
	    const newChecked = [...checked];

	    if (currentIndex === -1) {
	      newChecked.push(value);
	    } else {
	      newChecked.splice(currentIndex, 1);
	    }
	    
	    this.setState({
	      checked: newChecked
	    });

	    var answer = ""
	    for(var i in newChecked){
	    	if(newChecked[i]){
	    		if(!answer){
	    			answer = newChecked[i];
	    		}else{
	    			answer = answer + ',' + newChecked[i];
	    		}
	    	}
	    }

	    this.props.answerChanged(answer);
	  }
	
	render() {
		 const { classes,options, answer, answerChanged, AnswerKey,
		 isEditMode,handleEditTestData,handlePasteEvent,handleRemoveImageComponent,handleImageFileUpload} = this.props;
		 const showSolutions = true;
		 const keyStyle = {
		 	backgroundColor: "#00800033",
		 	paddingTop: "5px",
		 	paddingRight: "20px"
		 }
		return (
			<GridContainer style={{margin: "auto"}}>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					{options?.map((item, index) => (
						<div key={index}
	                  className={
	                    classes.checkboxAndRadio
	                  }>
	                  <FormControlLabel
	                    control={
	                      <Checkbox
	                        tabIndex={-1}
	                        checked={this.state.checked.indexOf(item.label) !== -1 ? true : false}
	                        onClick={() => this.handleToggle(item.label)}
	                        checkedIcon={<Check className={classes.checkedIcon} />}
	                        icon={<Check className={classes.uncheckedIcon} />}
	                        classes={{ checked: classes.checked }}
	                      />
	                    }
	                    style ={ showSolutions && AnswerKey && AnswerKey.indexOf(item.label)  != -1 ? keyStyle : {} }
	                    disabled={showSolutions} 
	                    label={
							isEditMode ?				
							item.content && item.content.map((itemContent, index2) => (
							  <React.Fragment>
							{ index2%2 == 0 &&
							  <QuestionTextInput text={itemContent} name={"options_"+item.label+"_"+index2}
							  rows="1"
							  isSingleComponent={true}
							  label={item.label} 
							  handleEditTestData={handleEditTestData}
							  handlePasteEvent={handlePasteEvent}
							  handleImageFileUpload={handleImageFileUpload}
							  />
							}
							{ index2%2 == 1 && itemContent &&
								<QuestionImages 
								src={itemContent} 
								label={item.label} 
								name={"options_"+item.label+"_"+index2}
								isEditMode={isEditMode}
								handleRemoveImageComponent={handleRemoveImageComponent} 
								/>
							}
							</React.Fragment>
							))
						:	<React.Fragment>
							{item.content && item.content.map((itemContent, index3) => (
				 		     	<React.Fragment key={index3}>
				 		     	{ index3%2 == 0 && 
									<QuestionText text={index3 == 0 ? item.label +") " +itemContent : itemContent }/>
								}
								{index3%2 == 1 && itemContent &&
								 	<QuestionImages 
									src={itemContent} 
									name={"option_"+item.label+'_'+index3}
									isEditMode={false}/> 
								}
								</React.Fragment>
							))}
							</React.Fragment>
						}
	                  />
	                </div>
					))}
				</GridItem> 
			</GridContainer>
		);
	}
}

export default withStyles(customCheckboxRadioSwitch)(MultipleAnswerOptions);
