import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ScheduleTestCard from "../core/ScheduleTestCard.jsx"
import {  withStyles } from '@material-ui/core/styles';

import MuiTable from '../core/MuiTable.jsx';
import qs from 'query-string';
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import Link from '@material-ui/core/Link';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = ((theme) => ({
  	section:{
  		padding: "16px 0",
      [theme.breakpoints.down('sm')]: {
       padding: "16px 0"
      },
  	},
    backLink:{
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    filters:{
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    filterSection:{
      paddingTop: "32px",
      marginRight: "16px",
      [theme.breakpoints.down('xs')]: {
        margin: "0px",
        padding: "16px 0 0"
      },
    } 
  }));

class AnalystTestSubjectSections extends Component {

	state ={
		schedule_data: "",
    columns: ["Campus", "Section", "Average","Percentage", "Rank"],
    rows: [],
    errorMessages: [],
    tabs: [],
    subjectTab: 0,
    allSubArray: []
	}

  componentDidMount(){
    this.fetchMarksReport();
  }


  fetchMarksReport(){
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    var url = '/analysis/test/subject-wise-sections.json?paper_id=' + paper_id
    $.ajax({
      type: 'GET',
      url: getBaseURL() + url,
    }).done((data, status, jqXHR)=>{
      this.processDataForTable(data); 
      this.processDataForScheduleCard(data["schedule_data"]); 
    }).fail((jqXHR, textStatus, errorThrown) => {
      //TODO: handle properly
        var errorMessages = this.state.errorMessages;
        errorMessages.push({message: jqXHR["responseJSON"].message})
        this.setState({errorMessages: jqXHR["responseJSON"].message});
    })
  }


  processDataForTable(data){
    var subjects = Object.keys(data["subjects_data"]);
    this.setState({tabs: subjects})
    var allSubArray = []
    for (let sub of subjects) {
      var sub_rank = 1
      var subRows = []
      for (let section of data["subjects_data"][sub]) {
         subRows.push([section.branch, section.section, section.average_marks, section.marks_percentage, sub_rank])
         sub_rank++;
      }
      allSubArray.push(subRows)
    } 
    this.setState({allSubArray: allSubArray,rows: allSubArray[0]})
  }


  processDataForScheduleCard(schedule_data){
    var schedule_data = {
      name: schedule_data["schedule_name"],
      program: schedule_data["schedule_program"],
      test_type: schedule_data["schedule_test_type"],
      user_group: schedule_data["schedule_user_group"],
      start_time: formatDate(schedule_data["schedule_start_time"]),
    }
    this.setState({schedule_data: schedule_data})
  }


  goBack = (e) => {
    e.preventDefault();
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    this.props.history.push('/analysis/test?paper_id='+paper_id);
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  handleSubjectTabChange = (event, newValue) => {
    this.setState({subjectTab: newValue, rows: this.state.allSubArray[newValue]})
  };

  getReportName(name){
    return (name ? "_" + name : "")
  }

  render () {
  	const {classes} = this.props;  
    const {schedule_data,columns,rows,tabs,subjectTab} = this.state;  
  	return(
  		<React.Fragment>
        <Link className={classes.backLink}
          component="button"
          variant="body2" 
          onClick={this.goBack}>
          Back to Test Reports
        </Link>
        { schedule_data &&
         <ScheduleTestCard data={schedule_data}/>
        } 
        {tabs && tabs.length>0 &&
         <div className={classes.section}>
          <Tabs value={subjectTab} onChange={this.handleSubjectTabChange}>
             {tabs.map((sub, index) => 
              <Tab label={sub} {...this.a11yProps(index)} />
              )}
          </Tabs>
        </div>}
          <div>
            <MuiTable columns={columns} rows={rows} title={"Sections Analysis"} downloadTitle={"Sections Analysis"+this.getReportName(schedule_data["name"])+this.getReportName(tabs[subjectTab])}/>
          </div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystTestSubjectSections);