import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import {  withStyles } from '@material-ui/core/styles';
import ComboBox from "./core/ComboBox.jsx"
import ReportListItem from "./core/ReportListItem.jsx"
import Grid from '@material-ui/core/Grid';
import {formatDateWithoutTime} from "components/helpers/DateTimeHelper.jsx";
import qs from 'query-string';


const styles = ((theme) => ({
  
  }));

class AnalystStudentHome extends Component {

	state = {
		selectedStudentId: "",
		allStudents: [],
		studentSearchString: ""
	}

	componentDidMount(){
		//const paper_id = qs.parse(this.props.location.search).paper_id; 
    	this.getUserResults(this.state.studentSearchString)
  	}

  	getUserResults(searchString){
  		var uri = '/analysis/students.json';
  		if(searchString){
  			uri = uri+"?search="+searchString
  		}
		$.ajax({
	      type: 'GET',
	      url: getBaseURL() + uri,
	    }).done((data, status, jqXHR)=>{
	    	this.setState({allStudents: data["students"]})
	    }).fail((jqXHR, textStatus, errorThrown) => {
	      //TODO: handle properly
	    })
  	}

	goToStudentReport = (e) => {
		var selectedStudentId = this.state.selectedStudentId;
		if(selectedStudentId){
			this.props.history.push('/analysis/student/report?student_id='+selectedStudentId);
		}else{
			alert("Please select a student")
		}
	}

	onStudentSearchInputChange = (e) =>{
		this.setState({studentSearchString: e.target.value})
		if(e.target.value)
		{
	  		if(this.debounceTimer){
	  			clearTimeout(this.debounceTimer);
	  		}
	  		this.debounceTimer = setTimeout(()=>this.getUserResults(this.state.studentSearchString), 1000);
		}
	}


	onSelectStudent = (event,option) => {
		this.setState({selectedStudentId: option.id})
	}

	allStudentsOptionLabel = (option) =>  option.username + " (" + option.name+")"
	
  render () {
  	const {classes} = this.props;
  	const {selectedStudentId,allStudents} = this.state;
  	return(
  		<React.Fragment>  		
  			<ComboBox onInputChange={this.onStudentSearchInputChange} optionLabel={this.allStudentsOptionLabel} options={allStudents} onChange={this.onSelectStudent} label={"Search Student"}/>
  			<div style={{padding: "30px 0px", minHeight: "10vh"}}>
	  		<Grid container spacing={4}>
		  		<Grid item sm={12} md={4}>
		  			 <ReportListItem isDisabled={selectedStudentId ? false : true} onView={this.goToStudentReport} role={{title: "Full Student Report", subtitle: "Complete report of a student"}}/>
		  		</Grid>
	  		</Grid>
  		</div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystStudentHome);