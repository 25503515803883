import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from "@material-ui/core/Grid";

import CheckCircle from "@material-ui/icons/CheckCircle";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import brand from "assets/img/images/brand_black.png";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root:{
  	padding: "4px 24px",
  	paddingRight: "56px"
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 0,
  },
  row: {
  	  height: "36px",
  	  width: "100%",
  },
  titleRow: {
  	 height: "36px",
    backgroundColor: theme.palette.background.default,
  },
  ...imagesStyle
});

class TestInstructionsPreview extends React.Component {


	constructor(props) {
	    super(props);

	    this.state = {
	      testInstructionsData: {},
	    };
	  }

	componentDidMount() {
		var data = this.props.testInstructionsData;
      {/*const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
      //TODO what happens if test_paper_id and question_no are not passed
      $.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-test-paper-instructions.json?test_paper_id='+test_paper_id, //Hard Coded TODO
          dataType: "JSON",
          headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({testInstructionsData: data, testId: test_paper_id});
        });
    	*/}

  	}

	render() {
		const style3={
		fontFamily: "sans-serif",
		paddingTop: "0px",
		paddingBottom: "20px",
		textAlign: "center",
		margin: "auto",
		color: "rgb(60, 72, 88)"
		};
		const style4={
		fontFamily: "sans-serif",
		fontSize: 16,
		color: "rgb(60, 72, 88)"
		};
		const style={
			 padding: "5px 5px", 
			 minWidth: "48px",
			 borderRadius: "0.2rem",
			 boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 3px 1px -2px, rgba(0, 0, 0, 0.3) 0px 1px 5px 0px",
			 borderRadius:"10",
			 borderWidth: "1",
			 borderColor: "black"
			};
		const style2={
			fontWeight: "400",
			fontFamily: "serif",
			marginBottom: "0px"
			};
		 const { testInstructionsData, testInfoData} = this.props;
		  const { classes} = this.props;
		return (
			<div>  		 	
			 <GridContainer style={{margin: "auto", width: "100%"}} spacing={16} >
			 	<GridItem  xs={12} sm={12} md={12} lg={12} style={{paddingBottom: "0px"}}>
					{/*<h4 style={style2}>AssessCore</h4>*/}
					<img
					  name="home"
					  src={brand}
					  alt="..."
					  style={{height: "60px"}}
					  className={
					    classes.imgRounded +
					    " " +
					    classes.imgFluid
					  }
					/>
				</GridItem>
			 	<GridItem xs={12} sm={12} md={12} style={{padding: "8px",paddingTop: "0px"}}>
			 	<div style={{height: "auto", backgroundColor: "white",paddingBottom: "20px"}}>	
			 		<h3 style={style3}>Test Instructions</h3>
			 		<ul>
				 		<li>
				 		<p style={style4}>The test paper consists of {testInstructionsData && testInstructionsData.length ? testInstructionsData.length : ""} subjects and {testInfoData && testInfoData.total_questions ? testInfoData.total_questions: ""} questions</p>
				 		</li>
				 		<li>
				 		<p style={style4}>The test paper carries a total of {testInfoData && testInfoData.total_marks ? testInfoData.total_marks : ""} marks</p>
				 		</li>
				 		<li>
				 		<p style={style4}>Each subject has following sections detailed as follows</p>
				 		</li>
			 		</ul>
			 	<div style={{margin: "auto", padding: "10px",textAlign: "center",paddingTop: "20px", overflowX: "overlay"}} spacing={16}  justify="center">
			 			
			 		<Table className={classes.table} style={{width: "100%"}}>
					    
					    <TableBody>	
					    
					     <TableRow className={classes.row} >
			            <CustomTableCell component="th" colSpan={1} >
			 				Sections
			 			</CustomTableCell>
			 			<CustomTableCell colSpan={3}>
			 			 	Pattern
			 			</CustomTableCell>
			 			<CustomTableCell colSpan={1}>
			 				Questions
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Positive
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Negative
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Partial
			 			</CustomTableCell>
					      </TableRow>
					    </TableBody>	

			 		{testInstructionsData.map((item, index) => (
			 			<TableBody key={index}>	
			 			
			 			<TableRow className={classes.titleRow} key={index}>
			            <CustomTableCell component="th" scope="row" colSpan={12} style={{textAlign: "center", padding:"4px 24px"}}>
			              {item.subject}
			            </CustomTableCell>
			          </TableRow>  
			 			{item && item.patterns && item.patterns.map((item, index2) => (
			          <TableRow className={classes.row} key={index}>
			            <CustomTableCell component="th" scope="row"  colSpan={1}>
			              Section-{index+1}
			            </CustomTableCell>
			 			<CustomTableCell component="th" scope="row" colSpan={3}>
			 			 	{item["pattern"]}
			 			</CustomTableCell>
			 			<CustomTableCell component="th" scope="row" colSpan={1}>
			 				{item["first_question"]} - {item["last_question"]}
			 			</CustomTableCell>
			 			<CustomTableCell  colSpan={1}>
			 			 {item["positive_marks"] ? "+ "+ item["positive_marks"] : 0} 
			 			</CustomTableCell>
			 			<CustomTableCell  colSpan={1}>
			 			 {item["negative_marks"]  ? "- " + item["negative_marks"]: 0} 
			 			</CustomTableCell>
			 			<CustomTableCell  colSpan={1}>
			 				{item["partial_marks"]} 
			 			</CustomTableCell>
			          </TableRow>
			 			))}
			 			</TableBody>	
			 		))} 
					</Table>
				</div>
			 	</div>
			 	</GridItem>
			 </GridContainer>	
			 </div>	
			);
	}
 

}

export default withStyles(styles)(TestInstructionsPreview);
