import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

/*import Button from "CustomButtons/Button.jsx";*/

import Grid from "@material-ui/core/Grid";


import { withStyles } from '@material-ui/core/styles';

import Button from "CustomButtons/Button.jsx";

import exam_status_success_icon from "assets/img/newicons/exam_status_success.svg";
import exam_status_unattempted_icon from "assets/img/newicons/exam_status_unattempted.svg";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import TestResultSummary from "components/analysis/TestResultSummary.jsx";

import {formatDate, formatDuration, isDateOld,offsetISTTimezone} from "components/helpers/DateTimeHelper.jsx";
import TextStyle from "styles/TextStyle.jsx";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
     //fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
    paddingBottom: "5px",
    minWidth: "150px",
    fontWeight: "100",
    fontSize: "0.9rem",
    fontFamily: "'Noto Serif', serif"
  },
  paper: {
    height: 140,
    width: 120,
    margin: "auto",
    marginBottom: "10px"
  },
  ...imagesStyle,
  ...TextStyle
});

class TestHomeRegion extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	testPapersData: null,
	    	testPapersResultData: null,
	    	justFinishedTest: false
	    }
	}

	componentDidMount() {
		if(!isUserSessionActive())
		{
		 	this.props.history.push('/login');
		 	return
		}

		const test_id = qs.parse(this.props.location.search).test_id;
		const finishTest = qs.parse(this.props.location.search).finishTest;

	  	this.getTestPapers(test_id);

	    if(finishTest == "true"){
			this.setState({justFinishedTest: true})
			setTimeout((e) => this.getTestResults(test_id), 5000);
		}else{
			this.getTestResults(test_id);
		}   
  	}

  	refreshPageAtStartTime(testPapersData,test_id){
  		var currentDate = new Date()
  		testPapersData.forEach((testPaper)=>{
  			var startTime = offsetISTTimezone(testPaper.start_time)
  			var timeDifference = startTime.getTime() - currentDate.getTime()
  			if (timeDifference > 0){
  				setTimeout((e)=>this.getTestPapers(test_id),timeDifference)
  			}
  		})
  	}

  	getTestPapers(test_id){
  		$.ajax({
	      type: "GET",
	      url: getBaseURL() + '/get-user-test-papers.json?test_id='+ test_id,
	      dataType: "JSON",
	      //headers: JSON.parse(localStorage.user)
	    }).done((data) => {
	    	//TODO Error Handling
	    	if(data && data["test_data"] && data["test_papers"] && data["test_papers"].length > 0){
	    		this.setState({testPapersData: data});
	    		this.refreshPageAtStartTime(data["test_papers"],test_id);

	    	}else{
	    		this.props.history.goBack();
	    	}
	    }).fail((jqXHR, textStatus, errorThrown) => {
			this.props.history.goBack();
		});
  	}

  	

  	getTestResults(test_id){
  		var justFinishedTest = this.state.justFinishedTest;
  		$.ajax({
	      type: "GET",
	      url: getBaseURL() + '/api/student-test-report.json?scheduled_test_id='+ test_id,
	      dataType: "JSON",
	      //headers: JSON.parse(localStorage.user)
	    }).done((data) => {//TODO Error Handling
	    	//TODO Error Handling
	    	this.setState({testPapersResultData: data, justFinishedTest: false});
	    }).fail((jqXHR, textStatus, errorThrown) => {
	    	if(justFinishedTest){
	    		setTimeout((e) => this.getTestResults(test_id), 15000);
	    		this.setState({justFinishedTest: false})
	    	}
	    }); 
  	}


	goToHome = (e) => {
  		this.props.history.push('/home');
  	}

  	goToTest = (e) => {
 		const test_id = qs.parse(this.props.location.search).test_id; //TODO HardCoded Values
 		const test_paper_id = e.currentTarget.id;
  		this.props.history.push('/home/test/instructions?test_id=' + test_id +'&test_paper_id='+test_paper_id);
  	}

  	viewTestSolution = (e) =>{
  		if(!isUserSessionActive())
  		{
  		 	this.props.history.push('/login');
  		 	return
  		}

 		const test_id = qs.parse(this.props.location.search).test_id; //TODO HardCoded Values
		const test_paper_id = e.currentTarget.id;
		$.ajax({
	      type: "GET",
	      url: getBaseURL() + '/max-out-attempts?test_paper_id='+ test_paper_id,
	      dataType: "JSON",
	      //headers: JSON.parse(localStorage.user)
	    }).done((data) => {//TODO Error Handling
	    	this.props.history.push('/home/test/solutions?test_id=' + test_id +'&test_paper_id=' + test_paper_id);
	    });    
  	}

	render() {
		 const { testPapersData, testPapersResultData} = this.state;
		  const { classes} = this.props;
		return (
			<div>	
			  	<GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
			 		
			 		<GridItem xs={12} sm={12} md={12} style={{height: "auto", backgroundColor: "white", margin: "15px", marginTop: "20px" }}>
					 
					 	<GridContainer style={{margin: "auto"}}>
					      	<GridItem  xs={12} sm={12} md={4} style={{margin: "auto", padding: "20px", paddingTop: "0px"}}>
							 	<h3 className={classes.robotoFontBold} >
					 	          Test Home
					 	    	</h3>
					 	    	<GridContainer style={{margin: "auto"}}>
					 	    		<GridItem  xs={12} sm={12} md={5}>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegularLabel}>Name</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegularLabel}>Identifier</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegularLabel}>Status</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegularLabel}>Total Papers</span>
						 	    	</div>
						 	    	<div >
						 	    		<span className={classes.robotoFontRegularLabel}>Description</span>
						 	    	</div>
						 	    	</GridItem>
						 	    	<GridItem  xs={12} sm={12} md={7}>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegular} >{testPapersData ? testPapersData["test_data"].name : null}</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegular} >{testPapersData ? testPapersData["test_data"].identifier : null}</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegular} >{ testPapersResultData ? 'Attempted' 
						 	    	: this.state.justFinishedTest ? 'Evaluating...'  
						 	    	: testPapersData ? 
						 	    	isDateOld(testPapersData["test_papers"][0]["start_time"]) 
						 	    	? 'Upcoming' 
						 	    	: 'Live' 
						 	        : null}</span>
						 	    	</div>
						 	    	<div style={{paddingBottom: "5px"}}>
						 	    		<span className={classes.robotoFontRegular} >{testPapersData ? testPapersData["test_data"].total_papers : null}</span>
						 	    	</div>
						 	    	<div >
						 	    		<span className={classes.robotoFontRegular} >{testPapersData ? testPapersData["test_data"].description ? testPapersData["test_data"].description : 'Not Provided' : 'Not Provided'}</span>
						 	    	</div>
						 	    	</GridItem>
					 	    	</GridContainer>
					 	    	{/*<h5>Name: {testPapersData ? testPapersData["test_data"].name : null}</h5>
					 	    	<h5>Identifier: {testPapersData ? testPapersData["test_data"].identifier : null}</h5>
					 	    	<h5>Status: { testPapersResultData ? 'Attempted' 
					 	    	: testPapersData ? 
					 	    	new Date().toISOString() >  testPapersData["test_papers"][0]["start_time"] 
					 	    	? 'Live' 
					 	    	: 'Upcoming' 
					 	        : null}</h5> 
					 	    //TODO Status logic to be corrected. there seems to be a bug
					 	    	<h5>Total Papers: {testPapersData ? testPapersData["test_data"].total_papers : null}</h5>
					 	    	<h5>Description:  {testPapersData ? testPapersData["test_data"].description ? testPapersData["test_data"].description : 'Not Provided' : 'Not Provided'}</h5>*/}
			 	    	  </GridItem>
			 	    	  <GridItem  xs={12} sm={12} md={7} style={{margin: "auto", padding: "20px", paddingTop: "0px"}}>
							 	<div style={{width: "fit-content", minWidth: "60%", margin: "auto"}}>
							 	{testPapersResultData && testPapersResultData["total_results"]? 
							 		<TestResultSummary 
									title="Test Results Summary"
							 		marksAwarded={testPapersResultData["total_results"]["total_marks"]}
							 		rank={testPapersResultData["total_results"]["rank"]}
							 		unattemptedQ={testPapersResultData["total_results"]["unattempted"]}
							 		negativeQ={testPapersResultData["total_results"]["incorrect"]}
							 		{...this.props}/>
			 	    	  			:null}
			 	    	  		</div>
			 	    	  </GridItem>
			 	    	   
						</GridContainer>
{/*			 	    	<hr className={classes.lineBreak} style={{margin: "10px"}}/>*/}
					 	<GridContainer style={{margin: "auto"}}>
					      	<GridItem  xs={12} sm={12} md={12} style={{margin: "auto", padding: "20px", paddingTop: "0px"}}>
					      		{/*<h5 className={classes.subtitle}></h5>*/}
				          		<div>
				          			<hr className={classes.lineBreak} style={{margin: "5px", width: "5%", display: "inline-block"}}/>
				          			<span className={classes.subtitle} >Test Papers</span>
				    		    	<hr className={classes.lineBreak} style={{margin: "5px", width: "70%", display: "inline-block"}}/>	 		
				    		    </div>    	 		
							        
								      	{testPapersData ? testPapersData["test_papers"].map((testPaperItem, index) => (
								      		<Grid key={index} container justify="flex-start" spacing={16} style={{margin:"-8px",marginBottom: "20px"}}>
												<Grid   xs={12} sm={12} md={2} item style={{padding: "20px", margin: "auto", overflow: "auto"}} >
										          <Paper className={classes.paper} style={{boxShadow:"none"}}>	
										          <Button
										          color="transparent"
										          id={testPaperItem.id}
										          style={{ height: "100%", padding: "20px"}}
										          >
										          <img
										            id={testPaperItem.id}
										              className={
										                classes.imgRounded +
										                " " +
										                classes.imgFluid
										              }
										              style={{width: "100%"}}
										              src={
										              	(testPaperItem.no_of_attempts_completed) > 0 ?
										               exam_status_success_icon :exam_status_unattempted_icon
										              }
										             alt=""
										            />
										          </Button>
							                      </Paper>
							                      	{(testPaperItem.end_time==null || isDateOld(testPaperItem.end_time)) && (testPaperItem.no_of_attempts-testPaperItem.no_of_attempts_completed) > 0 ?
								                  		isDateOld(testPaperItem.start_time) ?
									                    	<div style={{ margin: "auto", width: "fit-content"}}>
									                    	<Button
												          	style={{ color: "#3b4856", width: "100%", margin: "auto",
												          	 		backgroundColor: "#eeeeee", boxShadow: "none"}}
												          	id={testPaperItem.id} data-cy="not live"
												          	>
									                       	Not Live
									                      	</Button>
									                      	</div>
								                      	:	<div style={{ margin: "auto", width: "fit-content"}}>
								                      		<Button
												          	style={{ margin: "auto", backgroundColor: "#57849b"}}
												          	onClick={this.goToTest}
												          	id={testPaperItem.id} 
												          	data-cy="start test">
									                       	Start Test
									                      	</Button>
									                      	</div>
							                  	   : null}
							                  	   {(testPaperItem.no_of_attempts_completed) > 0 ? 
							                      <div style={{ margin: "auto", width: "fit-content"}}>
							                      <Button
										          style={{margin: "auto", marginTop: "5px", backgroundColor: "#57849b"}}
										          onClick={this.viewTestSolution}
										          id={testPaperItem.id}
										          disabled={isDateOld(testPaperItem["end_time"])}
										          data-cy="view solutions btn"
										          >
							                       View Solutions
							                      </Button>
							                      </div> 
							                      : null}
							                    </Grid>
							                    <Grid  xs={12} sm={12} md={6}  item style={{padding: "20px"}} >
			                    	 	    	<GridContainer style={{margin: "auto"}}>
			                    	 	    		<GridItem  xs={12} sm={12} md={5}>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Paper</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Start Time</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>End Time</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Duration</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Total Attempts</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Attempts Left</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Total Questions</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegularLabel}>Maximum Marks</span>
			                    		 	    	</div>
			                    		 	    	</GridItem>
			                    		 	    	<GridItem  xs={12} sm={12} md={7} style={{marginLeft: "-20px"}}>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.paper_no}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{formatDate(testPaperItem.start_time)}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.end_time ? formatDate(testPaperItem.end_time) : '--'}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{formatDuration(testPaperItem.duration)}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.no_of_attempts}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.no_of_attempts-testPaperItem.no_of_attempts_completed}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.total_questions}</span>
			                    		 	    	</div>
			                    		 	    	<div style={{paddingBottom: "5px"}}>
			                    		 	    		<span className={classes.robotoFontRegular} >{testPaperItem.total_marks}</span>
			                    		 	    	</div>
			                    		 	    	</GridItem>
			                    	 	    	</GridContainer>
							                    {/*<Typography className={classes.secondaryHeading}>Paper: {testPaperItem.paper_no}</Typography>
							                    <Typography className={classes.secondaryHeading}>Start Time: {formatDate(testPaperItem.start_time)}</Typography>
							                    <Typography className={classes.secondaryHeading}>End Time: {testPaperItem.end_time ? formatDate(testPaperItem.end_time) : '--'}</Typography>
							                    <Typography className={classes.secondaryHeading}>Duration: {formatDuration(testPaperItem.duration)}</Typography>
							                    <Typography className={classes.secondaryHeading}>Attempts Possible: {testPaperItem.no_of_attempts}</Typography>
							                    <Typography className={classes.secondaryHeading}>Attempts Left: {testPaperItem.no_of_attempts-testPaperItem.no_of_attempts_completed}</Typography>
							                    <Typography className={classes.secondaryHeading}>Total Questions: {testPaperItem.total_questions}</Typography>
							                    <Typography className={classes.secondaryHeading}>Maximum Marks: {testPaperItem.total_marks}</Typography>*/}
							                    </Grid>
							                    <Grid  xs={12} sm={12} md={4}  item style={{margin: "auto", padding: "20px", paddingTop: "0px"}} >
		                    					 	{testPapersResultData && testPapersResultData["paper_results"] 
		                    					 	&& testPapersResultData["paper_results"].length > 1 
		                    					 	&& testPapersResultData["paper_results"][index]["paper_no"]==testPaperItem.paper_no ?		            									
			                    					 		<TestResultSummary 
			                    					 		title="Result Summary"
			                    					 		marksAwarded={testPapersResultData["paper_results"][index]["total_marks"]}
			                    					 		rank={testPapersResultData["paper_results"][index]["rank"]}
			                    					 		unattemptedQ={testPapersResultData["paper_results"][index]["unattempted"]}
			                    					 		negativeQ={testPapersResultData["paper_results"][index]["incorrect"]}
			                    					 		test_paper_id={testPaperItem.id}
			                    					 		{...this.props}/>			                    	 	    	  			
		                    	 	    	  		:null
		                    	 	    	  		}
							                    </Grid>
							                </Grid>
											)): <p>No Tests Papers to display.</p>}
					               
					                
					                 {testPapersData ? testPapersData["test_papers"].length == 0 ? <p style={{padding: "20px"}}>No Test Papers to display.</p>: null :null}
					        </GridItem>
						</GridContainer>
						</GridItem>	
			 	</GridContainer>
			 </div>	
			);
	}

}

export default withStyles(styles)(TestHomeRegion);
