import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';


class Subjects extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){

    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_subjects'
    }).done((response, status, jqXHR)=>{
        var subjects = JSON.parse(response)["subjects"];

        this.setState({
          subjects: subjects
        });

    });
  }

  getIdForLOV(value){
    const subjects = this.state.subjects;
    const filteredSubjects = subjects.filter(subject => subject.short_name == value);
    if (filteredSubjects && filteredSubjects.length > 0){
     return {id: filteredSubjects[0].id};
    }
    return;
  }


  render() {
    const { name, value, index, change,formControlWidth, includeId } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={includeId ? (e) => change(e,this.getIdForLOV(e.target.value)) :change} 
          style={{fontSize: 'inherit'}} data-cy="select subject">
          { this.state.subjects.map((subject, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={subject.short_name} data-cy="select one subject">{subject.short_name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default Subjects;
