import React from "react";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Menu from "@material-ui/icons/Menu";
import { withStyles } from '@material-ui/core/styles';
import TextStyle from "styles/TextStyle.jsx";
import Button from "CustomButtons/Button.jsx";


class TestResultSummary extends React.Component {


	goToTestResultsDashboard =(e) => {
		const test_id = qs.parse(this.props.location.search).test_id; //TODO HardCoded Values
 		if(!test_id){
			alert("Test not provided.");
			return
		}

 		const test_paper_id = e.currentTarget.id;
 		if(test_paper_id != ""){
  			this.props.history.push('/home/analytics/test/results?test_id=' + test_id +'&test_paper_id='+test_paper_id);
		}else{
			this.props.history.push('/home/analytics/test/results?test_id=' + test_id); 
		}
	}

	render() {		
		const { classes,title, marksAwarded, rank, unattemptedQ, negativeQ,test_paper_id} = this.props;
		return(
			<div>
			<h4 style={{textAlign: "center"}} className={classes.robotoFontRegular} >
 	          {title}
 	    	</h4>
 	    	<Paper elevation={3}>
			<GridContainer style={{margin: "auto", textAlign: "center"}}>
				<GridItem  xs={12} sm={12} md={5} style={{margin: "auto"}}>
					<div style={{marginTop: "10px"}}>
							<p className={classes.smallRobotoFontRegular} style={{marginBottom: "0px"}}>Marks</p>
							<h4 style={{marginTop: "0px", fontSize: "1.5rem"}}>{marksAwarded}</h4>
						</div>	
				</GridItem>
				<hr style={{marginLeft: "0px", marginRight: "0px"}}/>
				<GridItem  xs={12} sm={12} md={5} style={{margin: "auto"}}>
					<div style={{marginTop: "10px"}}>
							<p className={classes.smallRobotoFontRegular} style={{marginBottom: "0px"}}>Rank</p>
							<h4 style={{marginTop: "0px", fontSize: "1.5rem"}}>{rank}</h4>
						</div>
				</GridItem>
			</GridContainer>
			<Divider />
			<GridContainer style={{margin: "auto", textAlign: "center"}}>
				<GridItem  xs={12} sm={12} md={5} style={{margin: "auto"}}>
						<div style={{marginTop: "10px"}}>
							<p className={classes.smallRobotoFontRegular} style={{marginBottom: "0px"}}>Negative</p>
							<h4 style={{marginTop: "0px", fontSize: "1.5rem"}}>{negativeQ}</h4>
						</div>
				</GridItem>
				<hr style={{marginLeft: "0px", marginRight: "0px"}}/>
				<GridItem  xs={12} sm={12} md={5} style={{margin: "auto"}}>
							<div style={{marginTop: "10px"}}>
							<p className={classes.smallRobotoFontRegular} style={{marginBottom: "0px"}}>Unattempted</p>
							<h4 style={{marginTop: "0px", fontSize: "1.5rem"}}>{unattemptedQ}</h4>
							</div>
				</GridItem>
			</GridContainer>
			</Paper>
			<Button
 	    	color="transparent"
 	    	id={test_paper_id}
 	    	size="sm"
 	    	style={{textTransform: "none", textAlign: "right", 
 	    	backgroundColor: "#57849b", color: "white", width: "100%"}}
 	    	onClick={this.goToTestResultsDashboard}
 	    	data-cy='view detailed analysis btn'
 	    	>
 	    	View Detailed Analysis
 	    	</Button>
			</div>
		);
	}

}

export default withStyles(TextStyle)(TestResultSummary);
