import React, { Component } from 'react'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import Close from "@material-ui/icons/Close";
import Button from "CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";


const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTable: {
      	responsiveStacked: {
      		overflowY: "hidden"
      	}
      },
      MUIDataTableToolbarSelect:{
      	title:{
      		display: "none"
      	},
      	root:{
      		backgroundColor: "none",
      		boxShadow: "none",
      		justifyContent: "normal"
      	}
      },
      MuiTableCell:{
      	root:{
      		padding: "4px 24px 4px 24px"
      	},
      },
      MuiToolbar:{
      	gutters:{
      		paddingLeft: "16px !important",
      		paddingRight: "16px !important",
      	}
      },
    typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  })


const getMuiThemeForModal =  createMuiTheme({
    overrides: {
    	MuiDialog:{
    		paper:{
    			width: '600px',
    			height: '314px'
    		}
    	},
      MuiModal:{
      	root:{
      		zIndex: "90000"
      	}
    },
	    typography: {
	    	useNextVariants: true,
	  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  })

const selectStyles = { 
  	container: (base) => ({ ...base,
    	width: "100%",
    	border: "1px solid #aaacb1",
    	borderRadius: "3px 0px 0px 3px",
  	}),
  	control: (base) => ({ ...base,
    	borderStyle: "none",
  	}),
  	menu: (base) => ({...base,
  		textAlign: "left",
  		color:"#676a6c",
  		width: "auto",
  		maxWidth: "400px"
  	})
	}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...modalStyle,
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class AutoSuggestSelect extends React.Component{

	constructor(props) {
	    super(props);
	    this.state = {
		    advancedSearchModal: false,
		    advSearchFormData: {}
		};
	}


	handleClickOpen(advancedSearchModal) {
		var x = [];
	    x[advancedSearchModal] = true;
	    this.setState(x);
	}
	handleClose(advancedSearchModal) {
		var x = [];
	    x[advancedSearchModal] = false;
	    this.setState(x);
	}

	handleAdvanceSearch = (e) =>{
		e.preventDefault();
		var advSearchFormData = this.state.advSearchFormData;
		this.props.advSearchExecuteCallback(advSearchFormData);
	}

	render() {
		const{classes,formControlWidth, options, onValueChange, name, value, isComponentDisabled,
			advSearchFormComponent, advSearchExecuteCallback, advSearchResults} = this.props;
		const{advSearchFormData} = this.state;
		const tableTitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "14px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px"
		};
		const advSearchTableOptions = {
			filter: false,
			selectableRows: "single",
			search: false,
			print: false,
			download: false,
			viewColumns: false,
			responsive: 'stacked',
			onRowsSelect:(currentRowsSelected, allRowsSelected) => {
				const advSearchResultsData = this.props.advSearchResults["data"]
				var selectedRow = advSearchResultsData[currentRowsSelected[0].index] //only one row selected
				this.props.onValueChange({
					value: selectedRow[0], 
					name: selectedRow[1], 
					label: selectedRow[2]},{name: this.props.name});
				this.handleClose("advancedSearchModal");
				},
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      },
		     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
		 	    	return (<div >
		 	    			<p style={tableTitleStyle} >Search Results</p>
		 	    			<p>Option Selected</p>
		 	    			</div>);
		 	    },
		    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
		        return; //No Footer needed overriding defaut footer
	    	}
		}
		return(
			<div style={ formControlWidth ? {width: formControlWidth} : null}>
			<div className={classes.root}>
				<Select options={options} styles={selectStyles}
				onChange={onValueChange} name={name} value={value}
				menuPlacement="auto"
				isDisabled={isComponentDisabled ? isComponentDisabled : false}
				components={{ DropdownIndicator: null, IndicatorSeparator: null }}
				/>
				<IconButton className={classes.IconButton} aria-label="Search"
					style={isComponentDisabled ? {border: "1px solid #00000042"}: {}}
					disabled={isComponentDisabled ? isComponentDisabled : false}
					onClick={() => this.handleClickOpen("advancedSearchModal")} data-cy="auto suggest search btn">
			    	<SearchIcon/>
			    </IconButton>
			   	<MuiThemeProvider theme={getMuiThemeForModal}>
			    <Dialog
		          classes={{	
		            root: classes.center,
		            paper: classes.modal
		          }}
		          open={this.state.advancedSearchModal}
		          TransitionComponent={Transition}
		          keepMounted
		          onClose={() => this.handleClose("advancedSearchModal")}
		          aria-labelledby="modal-slide-title"
		          aria-describedby="modal-slide-description">
		          <DialogTitle
		            id="classic-modal-slide-title"
		            disableTypography
		            className={classes.modalHeader}>
		            <IconButton
		              className={classes.modalCloseButton}
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={() => this.handleClose("advancedSearchModal")}>
		              <Close className={classes.modalClose} />
		            </IconButton>
		            <h4 className={classes.modalTitle}>Advanced Search</h4>
		          </DialogTitle>
		          <DialogContent style={{paddingBottom: "0px", overflowY: "visible"}}
		            id="modal-slide-description"
		            className={classes.modalBody}>
		            {advSearchFormComponent}
		          </DialogContent>
		          <DialogActions
		          style={{paddingTop: "0px", paddingBottom: "0px", paddingRight: "30px"}}
		            className={classes.modalFooter + " " + classes.modalFooterRight }>
		            <Button    
		              size="sm"
		              onClick={this.handleAdvanceSearch}
		              className={classes.searchButton}
		              data-cy="adv search btn">
		              Search
		            </Button>
		            <Button    
		              size="sm"
		              onClick={() => this.handleClose("advancedSearchModal")}
		              className={classes.cancelButton}>
		              Cancel
		            </Button>
		          </DialogActions>
		          <DialogContent style={{paddingTop: "0px", paddingBottom: "10px", overflowY: "visible"}}
		            id="modal-slide-description"
		            className={classes.modalBody}>
			        { advSearchResults  && advSearchResults["columns"] &&
	      			<div >
	      			<MuiThemeProvider theme={getMuiThemeForTable}>
	      				<MUIDataTable 
	      			  	title={<p style={tableTitleStyle} >Search Results</p>}
	      			  	options={advSearchTableOptions}
	      			  	columns={advSearchResults["columns"]}  
	      			  	data={advSearchResults["data"]}              
	      				/>
	      			</MuiThemeProvider>
	      			</div>
	      			}
		          </DialogContent>
		        </Dialog>
		        </MuiThemeProvider>
		    </div>
			</div>
		);
	}
}

export default  withStyles(styles)(AutoSuggestSelect);
