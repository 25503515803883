import React, {Component} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import MUIDataTable from "mui-datatables";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";


import Hidden from '@material-ui/core/Hidden';
import Slide from "@material-ui/core/Slide";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import UserGroupModal from './UserGroupModal';
import TestModelFormStyle from "../styles/TestModelFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";


const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px',
      		// width: '200px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...TestModelFormStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  userBtn:{
  	textTransform: "none",
    backgroundColor: "white",
    color: "#3f51b5",
    borderRadius: "3px",
    padding: "0.3rem",
    boxShadow: "none",
    fontSize: "14px",
    width: "40%",
    margin: "0px"
  },
  inputLabel:{
  	width: '96%' 
  }
};

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class ManageUserGroups extends Component{
	constructor(props){
		super(props);
		this.state={
			userGroupsTableData:[],
			userGroupsTableDataMob:[],
			newUserGroupInfo:{},
			isOpen: false,
			alertOpen: false,
			successMessage:"",
			errorMessage:""
		}
	}

	handleOnChangeAddUserGroupInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var newUserGroupInfo = this.state.newUserGroupInfo;
 	newUserGroupInfo[attr] = e.target.value;
}

	handleToggleModal = (e) => {
		var open= this.state.isOpen;
		this.setState({newUserGroupInfo:{}});
		this.setState({isOpen: !open});
	}

	handleAddNewUserGroup = (e) => {
		var {name,identifier,description}= this.state.newUserGroupInfo;

		var postData=  {global_user_groups_info:{name,identifier,description}};
		 $.ajax({
      type: 'POST',
      url: getBaseURL()+'/global_user_groups_infos',
      data: postData
    }).done((response) => {	
    	var message="New User Group Added Successfully";
    	this.setState({successMessage:message});
    	this.setState({alertOpen: true})
    	this.handleToggleModal();
    	this.fetchUserGroups();
    }).fail((error)=>{
	    var errorMessage = "Something went wrong!";
	    this.setState({errorMessage});
    	this.setState({alertOpen: true})
    	// this.handleToggleModal();
	    })

	}

	handleDelete = (id) => {
		$.ajax({
	      type: 'DELETE',
	      url: getBaseURL()+'/global_user_groups_infos/'+id.toString()+'.json',
	     headers: {
	      	'X-CSRF-Token': getCSRFToken()
	      }
	    }).done((response) => {
	    	var message="User Group Deleted Successfully";
	    	this.setState({successMessage:message});
	    	this.setState({alertOpen: true})
	    	this.fetchUserGroups();
	    }).fail((error)=>{
	    	const {responseJSON}=error;
	    	var errorMessage = responseJSON.message;
		    this.setState({errorMessage});
	    	this.setState({alertOpen: true})
	    })
	}

	populateUserGroupsFromResults(data){
	var globalUserGroups = data;
	var userGroupsTableData = [];
	var userGroupsTableDataMob = [];
	if(globalUserGroups && globalUserGroups.length > 0){
		for(var i in globalUserGroups){
			userGroupsTableData.push([
				globalUserGroups[i].id,
				globalUserGroups[i].name,
				globalUserGroups[i].user_count,
				globalUserGroups[i].identifier,
				globalUserGroups[i].description,
			]);

			userGroupsTableDataMob.push([
				globalUserGroups[i].id,
				globalUserGroups[i].name,
				globalUserGroups[i].user_count
			]);
		}
		this.setState({userGroupsTableData});
		this.setState({userGroupsTableDataMob});
	}else{
		this.setState({userGroupsTableData: []});
	}
}

	fetchUserGroups = () => {
			$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/search-user-groups.json?limit=30',
    }).done((data)=>{
    	this.populateUserGroupsFromResults(data);
    })
	}

	

	Transition =(props) => {
  return (<Slide direction="down" {...props} />);
	}

	handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successMessage:""});
    this.setState({errorMessage:""})
    this.setState({alertOpen: false});
  };

	goToUserMapping= (id) => {
		this.props.history.push(`/user-group-mappings?user_group_id=${id}`);
	}

	componentDidMount(){
		this.fetchUserGroups();
	}

	render(){

		const {classes}= this.props;

		const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
		};
		const subtitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			paddingBottom: "10px",
		};
		const tableTitleStyle={
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px",
		};
		const userGroupsTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: true,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const userGroupsTableColumns = [
			{name: "userGroupId",
		        options: {
		          display: false,
		        }
		  },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
              	const id= rowData[0];
              const linkButtonStyle = {
                textTransform: "none",
                backgroundColor: "white",
                color: "#3f51b5",
                borderRadius: "3px",
                padding: "0.3rem 0px",
                boxShadow: "none",
                fontSize: "12px"
              }
              return (
              <div>
                  <Button
                    onClick={() => {this.goToUserMapping(id)}}
                    style={linkButtonStyle}
                    size="sm"
                    >
                     {value}
                  </Button>
              </div>
              );
              }
          }
			},
			{name: "Users Count"},
			{name: "Identifier"},
			{name: "Description"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}= tableMeta;
              	const id=rowData[0];
              return (
 	
                <IconButton fontSize="sm" onClick={(e) => {this.handleDelete(id)}}><DeleteIcon /></IconButton>
            
              );
              }
          }
		},     
		];

		const userGroupsTableMobOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const userGroupsTableMobColumns = [
			{name: "userGroupId",
		        options: {
		          display: false,
		        }
		  },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (<div style={{width: "100px"}}>{value}</div>);
              }
          }
			},
			{name: "Count"},
		{	
				name: "",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
              	const id= rowData[0];
              return (
 
                <IconButton fontSize="sm" onClick={() => this.goToUserMapping(id)}><NavigateNextIcon /></IconButton>       
              );
              }
          }
		},     
		];

		const FormComponent =  (props) => {
			const {newUserGroupInfo, handleOnChangeAddUserGroupInfo, formError}= props;
				return(
					<React.Fragment>
		      	<GridContainer style={{
		      	backgroundColor: "white",
						paddingTop: "0px",
		      	width: "100%", 
		      	margin: "10px 0px 0px",
		      	marginTop: "0px"}}
		      	spacing={24} >	
							<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
				  			<form className={classes.form}>
		      				<TextField variant="outlined" label="User Group Name" fullWidth placeholder="Enter User Group Name" 
		      				 required className={classes.inputLabel}
		      				 value={newUserGroupInfo["name"]}
	      					 name="newUserGroupInfo_name"
	      					 onChange={handleOnChangeAddUserGroupInfo}
		      				 />

	              	<TextField variant="outlined" label="User Group Identifier" fullWidth placeholder="Enter User Group Identifier" 
	              	 required className={classes.inputLabel}
	              	 value={newUserGroupInfo["identifier"]}
	      					 name="newUserGroupInfo_identifier"
	      					 onChange={handleOnChangeAddUserGroupInfo}
	              	 />

	              	<TextField variant="outlined" label="User Group Description" fullWidth placeholder="Enter User Group Description"
	              	 required multiline className={classes.inputLabel}
	              	 value={newUserGroupInfo["description"]}
	      					 name="newUserGroupInfo_description"
	      					 onChange={handleOnChangeAddUserGroupInfo}
	              	 />

	              </form>
		          </GridItem>
	          </GridContainer>
        </React.Fragment>
				);		
    };
		return(
			<React.Fragment>
		  		<GridContainer style={{
		      		backgroundColor: "white",
							paddingTop: "0px",
		      		paddingBottom: "20px",
		      		width: "100%", 
		      		margin: "10px 0px 0px",
		      		marginTop: "0px"}}
		      		spacing={24}>
		      		{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage User Groups</h1>
		      			<hr/>
		      		</GridItem>
		      		<GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			      			<UserGroupModal formComponent={<FormComponent newUserGroupInfo={this.state.newUserGroupInfo} 
			      			 handleOnChangeAddUserGroupInfo={this.handleOnChangeAddUserGroupInfo} formError={this.state.formError}/>} titleModal={"Create New User Group"}
			      			 buttonTitle={"Create User Group"} open={this.state.isOpen}
			      			 handleToggleModal={this.handleToggleModal} handleClick={this.handleAddNewUserGroup}
			      			 />		
			      	</GridItem>	
			      	<Hidden smDown>	      		
			      		<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >User Groups</p>}
			      			  	options={userGroupsTableOptions}
			      			  	columns={userGroupsTableColumns}  
			      			  	data={this.state.userGroupsTableData}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
	      			<Hidden mdUp>
	      				<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >User Groups</p>}
			      			  	options={userGroupsTableMobOptions}
			      			  	columns={userGroupsTableMobColumns}  
			      			  	data={this.state.userGroupsTableDataMob}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
		      	</GridContainer>
		    </React.Fragment>
		);
	}
}

export default withStyles(styles)(ManageUserGroups);
