import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from '@material-ui/core/Divider';
import Button from "CustomButtons/Button.jsx";
import $ from 'jquery';


export default class ExpandableRowSchTestPaper extends React.Component {


		 handleFullScheduleReports = (e) =>{
			e.preventDefault();
			var schTestId = e.currentTarget.name;
			$.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/total-test-analysis?scheduled_test_id='+ schTestId,
		    }).done((data, status, jqXHR)=>{
		    	var message = data['message']
		    	if (message){
		    		this.props.errorMessageCallback({message: message});
		    	} else{
		    		this.props.storeStateCallback();
		    		this.props.history.push('/total-test-analysis?scheduled_test_id=' + schTestId);
		    	}
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured while fetching schedule analysis: " + jqXHR["responseJSON"].message});
		    })
		}

		handleViewReports = (e) =>{
			e.preventDefault();
			var schTestPaperId = e.currentTarget.name;
			$.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/test-paper-analysis?scheduled_test_paper_id='+ schTestPaperId,
		    }).done((data, status, jqXHR)=>{
		    	var message = data['message']
		    	if (message){
		    		this.props.errorMessageCallback({message: message});
		    	} else{
		    		this.props.storeStateCallback();
		    		this.props.history.push('/test-paper-analysis?scheduled_test_paper_id=' + schTestPaperId);
		    	}
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured while fetching test analysis: " + jqXHR["responseJSON"].message});
		    })
		}

		handleReevaluateTest = (e) =>{
			e.preventDefault();
			var schTestPaperId = e.currentTarget.name;
			var confirm_flag = confirm('Are you sure?')
		    if(!confirm_flag){  
		      e.preventDefault();
		      return;
		    }
		    $.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/re-evaluate-analysis?scheduled_test_paper_id='+ schTestPaperId,
		    }).done((data, status, jqXHR)=>{
		    	//TODO show some indicator that re-evaluation process was submitted in the background
		    	this.props.errorMessageCallback({message: "Test is being re-evaluated in the background. Please wait for upto 10 minutes for new results to reflect."});
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured while re-evaluating test due to " + jqXHR["responseText"]});
		    })

		}

		handleRescheduleTest = (e) => {
			e.preventDefault();
			var schTestId = e.currentTarget.name;
			//this.props.storeStateCallback();
			this.props.history.push('/scheduled_tests/new?edit=true&scheduled_test_id='+ schTestId);
		}

		handleCancelSchedule = (e) => {
			//TODO to be implemented
		}

		getLiveStats = (e) => {
			e.preventDefault();
			var schTestPaperId = e.currentTarget.name;
			this.props.history.push('/test-live-users?scheduled_test_paper_id='+ schTestPaperId);
		}

		endOpenTest = (e) => {
			e.preventDefault()
			var schTestPaperId = e.currentTarget.name;
			$.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/end-open-test?scheduled_test_paper_id='+ schTestPaperId,
		    }).done((data, status, jqXHR)=>{
		    	this.props.refreshSchedules()
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured while ending test due to " + jqXHR["responseText"]});
		    })
		}
		
		render () {
			const{schTestPapersData,schTestInfoData, errorMessageCallback} = this.props;
			const searchButtonStyle = {
			  	textTransform: "none",
			    backgroundColor: "#3f51b5",
			    color: "white",
			    borderRadius: "3px",
			    textAlign: "right",
			    "&:hover,&:focus": {
			      color: "white",
			      background: "#57849b",
			    },
			}
			return (
				<React.Fragment>
	        	{ schTestPapersData && schTestPapersData.length > 0 && schTestPapersData.map((schTestPaper,index) => (
		        	<React.Fragment key={schTestPaper[0]}>
			        <TableRow style={{backgroundColor: "rgba(224, 224, 224, 0.3)", height: "36px"}}>
			            <TableCell colSpan={2} style={{border: "none"}}>
			              Paper No : {schTestPaper[1]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			              Start Time : {schTestPaper[3]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			              Duration : {schTestPaper[5]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			            {schTestInfoData && schTestInfoData[3] && schTestInfoData[3] == 'UPCOMING' &&
			            	<React.Fragment>
			            	<Button
			            	  name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleRescheduleTest}
				              style={searchButtonStyle}>
				              Re-schedule
				            </Button>
				            {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
				            </React.Fragment>
				        }
				        {schTestInfoData && schTestInfoData[3] && (schTestInfoData[3] == 'LIVE' || schTestInfoData[3] == 'OPEN') &&
			            	<React.Fragment>
			            	<Button
			            	  name={schTestPaper[0]}    
				              size="sm"
				              onClick={this.getLiveStats}
				              style={searchButtonStyle}>
				              Live Stats
				            </Button>
				            {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
				            </React.Fragment>
				        }
				        {schTestInfoData && schTestInfoData[3] && schTestInfoData[3] == 'ENDED' &&
			            	<React.Fragment>
			            	<Button    
			            	  name={schTestPaper[0]}
				              size="sm"
				              onClick={this.handleViewReports}
				              style={searchButtonStyle}
				              data-cy="manage schedules view reports btn">
				              View Reports
				            </Button>
				            {/*<Button   
				              name={schTestPaper[0]} 
				              size="sm"
				              onClick={this.handleReevaluateTest}
				              style={searchButtonStyle}>
				              Re-evaluate Test
				            </Button>*/}
				            </React.Fragment>
				        }
			            </TableCell>
			        </TableRow>
			        <TableRow style={{backgroundColor: "rgba(224, 224, 224, 0.3)", height: "36px"}}>
			          	<TableCell colSpan={2} style={{border: "none"}}>
			              {/*Custom expandable row option. Data: {JSON.stringify(rowData)}*/}
			              Test: {schTestPaper[2]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			              End Time : {schTestPaper[4]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			              Possible Attempts : {schTestPaper[6]}
			            </TableCell>
			            <TableCell colSpan={2} style={{border: "none"}}>
			            {schTestInfoData && schTestInfoData[3] && schTestInfoData[3] == 'ENDED' &&
			            	<React.Fragment>
				            <Button   
				              name={schTestPaper[0]} 
				              size="sm"
				              onClick={this.handleReevaluateTest}
				              style={searchButtonStyle}>
				              Re-evaluate Test
				            </Button>
				            </React.Fragment>
				        }
				        {schTestInfoData && schTestInfoData[3] && schTestInfoData[3] == 'OPEN' &&
			            	<React.Fragment>
			            	<Button
			            	  name={schTestPaper[0]}    
				              size="sm"
				              onClick={this.endOpenTest}
				              style={searchButtonStyle} data-cy="manage schedules end test btn">
				              End Test
				            </Button>
				            {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
				            </React.Fragment>
				        }
			            </TableCell>
			        </TableRow>
			        { schTestPapersData.length > 1 && (index == schTestPapersData.length-1) &&
				        <React.Fragment>
		                    <TableRow style={{backgroundColor: "white", height: "36px", borderBottom: "1px solid rgba(228,228,228)"}}>
		                      	<TableCell colSpan={3} style={{border: "none"}}>
		                        </TableCell>
		                        <TableCell colSpan={2} style={{border: "none"}}>
		                        {schTestInfoData && schTestInfoData[3] && schTestInfoData[3] == 'ENDED' &&
    					            <Button   
    					              name={schTestInfoData[0]} 
    					              size="sm"
    					              onClick={this.handleFullScheduleReports}
    					              style={searchButtonStyle}>
    					              Full Schedule Summary
    					            </Button>
    					        }
		                        </TableCell>
		                        <TableCell colSpan={3} style={{border: "none"}}>
		                        </TableCell>
		                    </TableRow>
				        </React.Fragment>
			        }
			        <Divider style={{backgroundColor: "white", height: "3px"}}/>
			        </React.Fragment>
		        ))
	        	}
	        	</React.Fragment>
	        );
		}
}