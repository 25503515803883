import React, {Component} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';
import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import TestModelFormStyle from "../styles/TestModelFormStyle.js";

import TextField from "@material-ui/core/TextField";
import Hidden from '@material-ui/core/Hidden';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import UserGroupModal from './UserGroupModal';

const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...TestModelFormStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  userBtn:{
  	textTransform: "none",
    backgroundColor: "white",
    color: "#3f51b5",
    borderRadius: "3px",
    padding: "0.3rem",
    boxShadow: "none",
    fontSize: "14px",
    width: "40%",
    margin: "0px"
  },
  inputLabel:{
  	width: '96%' 
  },
  accordion:{
  	boxShadow: "none",
  	border: '1px solid rgba(0, 0, 0, 0.50)',
  	margin: "8px"
  },
  tableDetails:{
  	display: "flex",
  	flexDirection:"column",
  }
};

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class ManageBranches extends Component{
	constructor(props){
		super(props);
		this.state={
			branchesTableData:[],
			branchesTableDataMob:[],
			newBranchInfo:{},
			isOpen: false,
			successMessage:"",
			errorMessage:"",
			alertOpen:false
		}
	}

	handleOnChangeAddBranchInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var newBranchInfo = this.state.newBranchInfo;
 	newBranchInfo[attr] = e.target.value;
}

	handleAddNewBranch = async(e) => {
		var {name,short,description}= this.state.newBranchInfo;

		var postData= {global_branch:{name, short_name: short, description}};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL()+'/global_branches',
	    data:postData
    }).done((data)=>{
    	var message="New Branch Added Successfully";
    	this.setState({successMessage:message});
    	this.setState({alertOpen: true})
    	this.handleToggleModal();
    	this.fetchBranches();
    }).fail((error)=>{
	    var errorMessage = "Something went wrong!";
	    this.setState({errorMessage});
    	this.setState({alertOpen: true});
	    })

		

	}
	handleToggleModal = (e) => {
		var open= this.state.isOpen;
		this.setState({newBranchInfo:{}});
		this.setState({isOpen: !open});
	}

	hanldeDelete = (id) => {

		$.ajax({
	      type: 'DELETE',
	      url: getBaseURL()+'/global_branches/'+id.toString()+'.json',
	     	headers: {
	      	'X-CSRF-Token': getCSRFToken()
	      }
	    }).done((response) => {
	    	var message="Branch Deleted Successfully";
	    	this.setState({successMessage:message});
	    	this.setState({alertOpen: true})
	    	this.fetchBranches();
	    }).fail((error)=>{
	    	const {responseJSON}=error;
	    	var errorMessage = responseJSON.message;
		    this.setState({errorMessage});
	    	this.setState({alertOpen: true})
	    })
	}

	populateBranchesFromResults(data){
	var globalBranches = data["global_branches"];
	
	var branchesTableData = [];
	var branchesTableDataMob = [];
	if(globalBranches && globalBranches.length > 0){
		for(var i in globalBranches){
			branchesTableData.push([
				globalBranches[i].id,
				globalBranches[i].name,
				globalBranches[i].short_name,
				globalBranches[i].description,
			]);

			branchesTableDataMob.push([
				globalBranches[i].id,
				globalBranches[i].name,
				globalBranches[i].short_name
			]);
		}
		this.setState({branchesTableData});
		this.setState({branchesTableDataMob});
	}else{
		this.setState({branchesTableData: []});
	}
}


	fetchBranches = () => {
			$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/branches.json',
    }).done((data)=>{
    	this.populateBranchesFromResults(data);
    })
	}

	Transition =(props) => {
  return (<Slide direction="down" {...props} />);
	}

	handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successMessage:""});
    this.setState({errorMessage:""})
    this.setState({alertOpen: false});
  };


	componentDidMount(){
		
		this.fetchBranches();

	}

	
	render(){

		const {classes}= this.props;

		const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
		};
		const subtitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			paddingBottom: "10px",
		};
		const tableTitleStyle={
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px",
		};
		const branchesTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: true,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const branchesTableColumns = [
			{name: "branchId",
	        options: {
	          display: false,
	        }
	    },
			{name: "Name"},
			{name: "Short Name"},
			{name: "Description"},
			{	
					name: " ",
					options: {
	              customBodyRender: (value, tableMeta, updateValue) => {
	              	const {rowData}= tableMeta;
	              	const id=rowData[0];
	              return (
	 
	                <IconButton fontSize="sm" onClick={() => {this.hanldeDelete(id)}}><DeleteIcon /></IconButton>
	            
	              );
	              }
	          }
			}     
		];

		const branchesTableMobOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const branchesTableMobColumns = [
		{name: "branchId",
	        options: {
	          display: false,
	        }
	    },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (<div>{value}</div>);
              }
          }
			},
			{name: "Short"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}= tableMeta;
	              	const id=rowData[0];
              return (
 
                <IconButton fontSize="sm" onClick={() => {this.hanldeDelete(id)}}><DeleteIcon /></IconButton>
            
              );
              }
          }
		}     
		];

		const FormComponent =  (props) => {
			const {newBranchInfo, handleOnChangeAddBranchInfo}= props;
			return(
				<React.Fragment>
	      	<GridContainer style={{
	      	backgroundColor: "white",
					paddingTop: "0px",
	      	width: "100%", 
	      	margin: "10px 0px 0px",
	      	marginTop: "0px"}}
	      	spacing={24} >	
						<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
			  			<form className={classes.form}>

	      				<TextField variant="outlined" label="Branch Name" fullWidth placeholder="Enter Branch Name" required 
	      				className={classes.inputLabel}
	      				value={newBranchInfo["name"]}
	      				name="newBranchInfo_name"
	      				onChange={handleOnChangeAddBranchInfo}
	      				/>

              	<TextField variant="outlined" label="Branch Short  Name" fullWidth placeholder="Enter Short Name" required 
	      				className={classes.inputLabel}
	      				value={newBranchInfo["short"]}
	      				name="newBranchInfo_short"
	      				onChange={handleOnChangeAddBranchInfo}
	      				/>

              	<TextField variant="outlined" label="Branch Description" fullWidth placeholder="Enter Branch Description" required
              	multiline className={classes.inputLabel} 
              	value={newBranchInfo["description"]}
	      				name="newBranchInfo_description"
	      				onChange={handleOnChangeAddBranchInfo}
	      				/>
              </form>
	          </GridItem>
          </GridContainer>
        </React.Fragment>	
			);
		}
		return(
		
			<React.Fragment>
		  		<GridContainer style={{
		      		backgroundColor: "white",
					paddingTop: "0px",
		      		paddingBottom: "20px",
		      		width: "100%", 
		      		margin: "10px 0px 0px",
		      		marginTop: "0px"}}
		      		spacing={24}>
		      		{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage Branches</h1>
		      			<hr/>
		      		</GridItem>
		      		<GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			      			<UserGroupModal formComponent={<FormComponent newBranchInfo={this.state.newBranchInfo} 
			      			handleOnChangeAddBranchInfo={this.handleOnChangeAddBranchInfo} />} titleModal={"Add New Branch"} 
			      			buttonTitle={"Add Branch"} handleClick={this.handleAddNewBranch} open={this.state.isOpen}
			      			handleToggleModal={this.handleToggleModal}/>		
			      	</GridItem>	
			      	<Hidden smDown>	      		
			      		<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Branches</p>}
			      			  	options={branchesTableOptions}
			      			  	columns={branchesTableColumns}  
			      			  	data={this.state.branchesTableData}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
	      			<Hidden mdUp>
	      				<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Branches</p>}
			      			  	options={branchesTableMobOptions}
			      			  	columns={branchesTableMobColumns}  
			      			  	data={this.state.branchesTableDataMob}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
		      	</GridContainer>
		    </React.Fragment>
		);
	}
}

export default withStyles(styles)(ManageBranches);
