import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import {  withStyles } from '@material-ui/core/styles';
import ComboBox from "./core/ComboBox.jsx"
import ReportListItem from "./core/ReportListItem.jsx"
import Grid from '@material-ui/core/Grid';
import {formatDateWithoutTime} from "components/helpers/DateTimeHelper.jsx";
import qs from 'query-string';


const styles = ((theme) => ({
  
  }));

class AnalystTestHome extends Component {

	state = {
		selectedTestPaper: "",
		allSchedules: [],
		selectedTestPaperOption: null
	}

	componentDidMount(){
		const paper_id = qs.parse(this.props.location.search).paper_id; 
		this.setState({selectedTestPaper: paper_id})
    	$.ajax({
          type: 'GET',
          url: getBaseURL() + '/analysis/schedules.json',
        }).done((data, status, jqXHR)=>{
          	this.processSchedulesData(data);
        }).fail((jqXHR, textStatus, errorThrown) => {
          //TODO: handle properly
        })
  	}

  	processSchedulesData(data){
  		var schedules = data["schedules"];
  		var allSchedules = []
  		const paper_id = qs.parse(this.props.location.search).paper_id;
  		var selectedTestPaperOption = null; 
  		if(schedules){
	  		for(let schedule of schedules){
	  			allSchedules.push({
	  				name: schedule.name,
	  				paper_id: schedule.sch_papers[0].id, //TODO hardcoded to first paper only
	  				start_time: formatDateWithoutTime(schedule.sch_papers[0].start_time),
	  			})
	  			if(paper_id && paper_id == schedule.sch_papers[0].id){
	  				selectedTestPaperOption = {
	  					name: schedule.name,
	  					paper_id: schedule.sch_papers[0].id, //TODO hardcoded to first paper only
	  					start_time: formatDateWithoutTime(schedule.sch_papers[0].start_time),
	  				}
	  			}
	  		
	  		}
	  		this.setState({allSchedules: allSchedules, selectedTestPaperOption: selectedTestPaperOption})
  		}
  	}

	goToMarksList = (e) => {
		var selectedTestPaper = this.state.selectedTestPaper;
		if(selectedTestPaper){
			this.props.history.push('/analysis/test/marks?paper_id='+selectedTestPaper);
		}else{
			alert("Please select a Test")
		}
	}

	goToCampusAttendanceAnalysis = (e) => {
		var selectedTestPaper = this.state.selectedTestPaper;
		if(selectedTestPaper){
			this.props.history.push('/analysis/test/attendance?paper_id='+selectedTestPaper);
		}else{
			alert("Please select a Test")
		}
	}

	goToSubjectWiseSectionAnalysis = (e) =>{
		var selectedTestPaper = this.state.selectedTestPaper;
		if(selectedTestPaper){
			this.props.history.push('/analysis/test/subjects/sections?paper_id='+selectedTestPaper);
		}else{
			alert("Please select a Test")
		}
	}

	goToToppersList = (e) => {
		var selectedTestPaper = this.state.selectedTestPaper;
		if(selectedTestPaper){
			this.props.history.push('/analysis/test/toppers?paper_id='+selectedTestPaper);
		}else{
			alert("Please select a Test")
		}
	}

	onSelectSchedule = (event,option) => {
		this.setState({selectedTestPaper: option.paper_id, selectedTestPaperOption: option})
	}

	allSchedulesOptionLabel = (option) =>  option.name + " (" + option.start_time+")"
	
  render () {
  	const {classes} = this.props;
  	const {selectedTestPaper,allSchedules,selectedTestPaperOption} = this.state;
  	return(
  		<React.Fragment>  		
  			<ComboBox variant="controlled" value={selectedTestPaperOption} optionLabel={this.allSchedulesOptionLabel} options={allSchedules} onChange={this.onSelectSchedule} label={"Select Test"}/>
  			<div style={{padding: "30px 0px", minHeight: "10vh"}}>
	  		<Grid container spacing={4}>
		  		<Grid item sm={12} md={4}>
		  			 <ReportListItem isDisabled={selectedTestPaper ? false : true} onView={this.goToMarksList} role={{title: "Marks List", subtitle: "Marks and Ranks list for all students"}}/>
		  		</Grid>
		  		<Grid item sm={12} md={4}>
		  			 <ReportListItem isDisabled={selectedTestPaper ? false : true} onView={this.goToCampusAttendanceAnalysis} role={{title: "Attendance Analysis", subtitle: "Campus wise attendance analysis"}}/>
		  		</Grid>
		  		<Grid item sm={12} md={4}>
		  			 <ReportListItem isDisabled={selectedTestPaper ? false : true} onView={this.goToSubjectWiseSectionAnalysis} role={{title: "Subject Wise Analysis", subtitle: "Subject Wise Sections Average Analysis"}}/>
		  		</Grid>
		  		<Grid item sm={12} md={4}>
		  			 <ReportListItem isDisabled={selectedTestPaper ? false : true} onView={this.goToToppersList} role={{title: "Toppers List", subtitle: "Top performers list from all branches"}}/>
		  		</Grid>
	  		</Grid>
  		</div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystTestHome);