

export function getBaseURL() {
    return (process.env.HOST_BASE_URL);
}

export function getMobileAppPlayStoreURL() {
    return (process.env.APP_PLAY_STORE_URL);
}

export function getCSRFToken() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
} 