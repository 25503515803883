import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";

import LinearProgress from '@material-ui/core/LinearProgress';

import Info from '@material-ui/icons/Info';
import List from '@material-ui/icons/List';

//import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

import CustomInput from "CustomInput/CustomInput.jsx";


//////////////////////////////


import { container, title } from "assets/jss/material-kit-react.jsx";

import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.jsx";
import popoverStyles from "assets/jss/material-kit-react/popoverStyles.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

import brand from "assets/img/images/brand_black.png";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import {getTestPasswordEnhacement} from "components/helpers/EnhancementConfigHelper.jsx"


const javascriptStyles = {
  section: {
    padding: "70px 0 0"
  },
  ...container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "4px"
  },
  ...modalStyle,
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0"
  },
  ...tooltipsStyle,
  ...popoverStyles,
  ...imagesStyle
};


//////////////////////////////////


function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const style2={
	fontWeight: "400",
	fontFamily: "serif-sans",
	fontSize: 24
	};

 class TestHeader extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
		    value: 0,
		    modal: false,
		    test_id: '',
		    test_paper_id: '',
		    password: "",
		    test_seconds_to_end : '',
		    progressTimer: 0,
		    timer_diff: 0,
		    textTimer: "00:00:00",
		    submitTestModal: false,
		    testAutoSubmitSeconds: 0,
		    testAutoSaveTimerSeconds: 0,
		    submissionInProcess: "",
		    localTimersStartTime: "",
		    intialTimeElapsedSeconds: 0,
		    initial_test_seconds_to_end: "",
		    modalErrorMessage: ""
		};
		//this.state = state;
		this.timer = null;
		this.testAutoSubmitTimer = null;
		this.testAutoSaveTimer = null;
		this.TEST_AUTO_SAVE_FREQUENCY = 300; //5 minutes - User Test Data gets auto saved every 5 minutes
	  }

		componentDidMount() {
			if(!isUserSessionActive())
			{
			 	this.props.history.push('/login');
			 	return
			}
			const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
			const test_id = qs.parse(this.props.location.search).test_id;
			if(test_paper_id){
				 this.setState({test_paper_id: test_paper_id});
			}
			if(test_id){
				 this.setState({test_id: test_id});
			}
			if(!this.props.staticMode && !this.props.showSolutions){
			  	$.ajax({
			  	  type: 'GET',
			  	  url: getBaseURL() + '/get-live-test-paper-time.json?test_paper_id='+test_paper_id,
			  	  dataType: "JSON",
			  	  cache: false
			      //headers: JSON.parse(localStorage.user)
			  	}).done((data)=>{
			  		if(data.is_test_live){ //TODO Error handling
			  			var progressTimer = (100*(data.seconds_to_end-data.seconds_to_end_now)/data.seconds_to_end);
				  		const localTimersStartTime = new Date().getTime();
				  		this.setState({
				  			test_seconds_to_end: data.seconds_to_end_now, 
				  			timer_diff: (100/data.seconds_to_end),
				  			progressTimer: progressTimer,
				  			localTimersStartTime: localTimersStartTime,
				  			time_elapsed_till_now: data.seconds_to_end-data.seconds_to_end_now,
				  			total_test_seconds_to_end: data.seconds_to_end
				  			})
	    				this.timer = setInterval(this.progress, 1000);
	    				//Autosave on refresh if the test progress is between 
				    	if(localStorage.activeTestId && progressTimer > 5 && progressTimer <= 99){
				    		//Save after 3 seconds for testData to Load first
				    		setTimeout(function(){
					             this.props.handleTestAutoSave();
					        }.bind(this),3000);
				    	}
	    				this.testAutoSaveTimer = setInterval(this.testAutoSaveTimerProgress, 1000);// 1 second progress
				  		 //TODO Need to handle case when the test seconds remaining are 0
				  	}else{
				  		//TODO Need to handle error password scenario
				  		this.finishTest();
				  		localStorage.removeItem("testUserData");
         				localStorage.removeItem("activeTestId");
         				localStorage.removeItem("activeTestQuestionNumber");
         				localStorage.removeItem("activeTestAutoSaved");
				  	}
			  	})
			}
	  	}	

	  	componentWillUnmount() {
	    	clearInterval(this.timer);
	    	clearInterval(this.testAutoSubmitTimer);
	    	clearInterval(this.testAutoSaveTimer);
	  	}

	  handleInput = (e) => {
	    e.preventDefault();
	    var id = e.target.id;
	    var obj ={};
	    obj[id] = e.target.value;
	    this.setState(obj);
	  }

	  handleClickOpen(modal) {
	    var x = [];
	    x[modal] = true;
	    this.setState(x);
	  }
	  handleClose(modal) {
	    var x = [];
	    x[modal] = false;
	    this.setState(x);
	  }

	  handleClickOpenSubmit(modal) {
	    this.setState({submitTestModal: true});
	  }
	  handleCloseSubmit(modal) {
	   this.setState({submitTestModal: false});
	  }

	  handleLocalStorageForAutoSavedTests(){
	  	if(localStorage.activeTestId && localStorage.activeTestAutoSaved){
		  	$.ajax({
		  	  type: 'GET',
		  	  url: getBaseURL() + '/get-live-test-paper-time.json?test_paper_id='+localStorage.activeTestId,
		  	  dataType: "JSON",
		      //headers: JSON.parse(localStorage.user)
		  	}).done((data)=>{
		  		if(data.is_test_live){ //TODO Error handling
		  			alert("Another active test is in progress. Please complete the active test before starting the new test.");
			  		return
			  	}else{
			  		//Test AutoSave And Not Live anymore -> clear all sessiond data
			  		localStorage.removeItem("testUserData");
         			localStorage.removeItem("activeTestId");
         			localStorage.removeItem("activeTestQuestionNumber");
         			localStorage.removeItem("activeTestAutoSaved");
			  	}
		  	})
	  	}
	  }

	  handleStartTest(modal) {
	  	const test_id =this.state.test_id;
	  	const test_paper_id = this.state.test_paper_id;
	  	const password = this.state.password;

	  	if(localStorage.activeTestId && localStorage.activeTestId != test_paper_id){
	  		if(localStorage.activeTestAutoSaved){
	  			this.handleLocalStorageForAutoSavedTests()
	  		}else{
	  			alert("Another active test is in progress. Please complete the active test before starting the new test.");
	  			return
	  		}
	  	}

	  	$.ajax({
	  	  type: 'GET',
	  	  url: getBaseURL() + '/validate-test-paper-password.json?test_paper_id='+test_paper_id +'&password='+ password,
	  	  dataType: "JSON",
	      //headers: JSON.parse(localStorage.user)
	  	}).done((data)=>{
	  		if(data.is_valid){ //TODO Error handling
		  		var x = [];
		  		x[modal] = false;
		  		this.setState(x);
		  		this.props.history.push('/home/test?test_id=' + test_id +'&test_paper_id=' + test_paper_id);
		  		 //1 second increase in progress
		  	}else{
		  		this.setState({modalErrorMessage: "Test Validation Failed. Test cannot be started."})
		  		//TODO Need to handle error password scenario
		  	}
	  	
	  	})
	  }

	  progress = () => {
	    if (this.state.progressTimer === 100) {
	      //TODO business logic when test ends
	      this.handleClickOpenSubmit("modal");
	      clearInterval(this.timer);

	      this.testAutoSubmitTimer = setInterval(this.testAutoSubmitCounter, 1000);
	    } else {
	    	var currentTime = new Date().getTime();
	    	var localTimeElapsed = currentTime - this.state.localTimersStartTime;
	    	//this time include both server side time elapsed and client side time elapsed
	    	var timeElapsedInSeconds = Math.max(Math.floor(localTimeElapsed/1000),1) + this.state.time_elapsed_till_now;
	      	//var timeElapsedInSeconds = 1
	      	this.setState({progressTimer: Math.min(this.state.timer_diff*timeElapsedInSeconds, 100),
	      	textTimer: this.secondsToHms(Math.max(this.state.total_test_seconds_to_end-timeElapsedInSeconds, 0)),
	      	test_seconds_to_end: (this.state.total_test_seconds_to_end-timeElapsedInSeconds)
	      });
	    }

	  };

	  testAutoSubmitCounter = () =>{
	  	if(this.state.testAutoSubmitSeconds === 30){
	  		this.finishTest()
	  	}else{
	  		this.setState({testAutoSubmitSeconds: (this.state.testAutoSubmitSeconds+1)});
	  	}
	  }

	  testAutoSaveTimerProgress =() =>{
	  	var testAutoSaveTimerSeconds = this.state.testAutoSaveTimerSeconds;
	  	if(testAutoSaveTimerSeconds > 0 && (testAutoSaveTimerSeconds%this.TEST_AUTO_SAVE_FREQUENCY == 0)){
	  		this.props.handleTestAutoSave();
	  	}
  		var currentTime = new Date().getTime();
  		var localTimeElapsed = currentTime - this.state.localTimersStartTime;
    	var timeElapsedInSeconds = Math.max(Math.floor(localTimeElapsed/1000),1);
  		this.setState({testAutoSaveTimerSeconds: timeElapsedInSeconds});

	  }

	  finishTest = () => {
	  	this.setState({submissionInProcess: "Y"})
	  	this.props.submitTestData();
	  	clearInterval(this.timer);
	    clearInterval(this.testAutoSubmitTimer);
	    clearInterval(this.testAutoSaveTimer);
	  }

	 secondsToHms(d) {
	      d = Number(d);
	      var h = Math.floor(d / 3600);
	      var m = Math.floor(d % 3600 / 60);
	      var s = Math.floor(d % 3600 % 60);
	      var hDisplay = h > 0 ? h < 10 ? "0"+h+":" : h+":" : "00:";
	      var mDisplay = m > 0 ? m < 10 ? "0"+m+":" : m+":" : "00:";
	      var sDisplay = s > 0 ? s < 10 ? "0"+s : s : "00";
	      return hDisplay + mDisplay + sDisplay; 
	  };


	   handleGoBack() {
	    const test_id =this.state.test_id;
	    this.props.history.push('/home/enterprise/testHome?test_id='+test_id);
	  };

	  handleResumeTest() {
	  	const test_id =this.state.test_id;
	  	const test_paper_id =this.state.test_paper_id;
	    this.props.history.push('/home/test?test_id=' + test_id +'&test_paper_id=' + test_paper_id);
	    //TODO error handling on Instructions Page and also on Test Page
	  };
	  gotoInstructions(){
	  	const test_id =this.state.test_id;
	  	const test_paper_id =this.state.test_paper_id;
		this.props.history.push('/home/test/instructions?test_id=' + test_id +'&test_paper_id=' + test_paper_id);
	  };

	  handleEnterKeyPressToStartTest = (e) => {
	    //e.preventDefault();
	    if(e.key === 'Enter'){
	      this.handleStartTest("modal");
	    }
	  }

render() {
	const { staticMode, classes, submitTestData, isTestActive, showSolutions,testSubmissionFailed} = this.props;
	const { progressTimer,textTimer} = this.state;
	return(
		<div>
			<GridContainer style={{margin: "auto", minHeight: "50px"}} alignItems="center">
				<GridItem  xs={12} sm={12} md={12} lg={2}>
					{/*<h4 style={style2}>AssessCore</h4>*/}
					<img
					  name="home"
					  src={brand}
					  alt="..."
					  style={{height: "60px"}}
					  className={
					    classes.imgRounded +
					    " " +
					    classes.imgFluid
					  }
					/>
				</GridItem>
				<GridItem  xs={12} sm={12} md={12} lg={1}>
					{/*<h4 style={style2}> Test Name | Paper</h4>*/}
				</GridItem>
				<GridItem  xs={12} sm={12} md={12} lg={2}>
				{ staticMode || showSolutions? null : <LinearProgress variant="determinate" value={progressTimer}/> }
				</GridItem>
				<GridItem  xs={12} sm={12} md={12} lg={1}>
				{ staticMode || showSolutions ? null : textTimer }
				</GridItem>
				<GridItem  xs={12} sm={12} md={12} lg={3}>
				{ staticMode || showSolutions ? null : 
					<div>
						{/*TODO question paper removed as of current scope*/}
						{/*<Button size={"sm"} color="transparent"><List /> Question Paper</Button> */}
						<Button size={"sm"} onClick={() => this.gotoInstructions()} color="transparent"><Info style={{verticalAlign: "middle"}}/> Instructions</Button> 
					</div>
				}
				</GridItem>
				{ !showSolutions ? staticMode && !isTestActive ? <GridItem  xs={12} sm={12} md={12} lg={3}>
					<Button
					  style={{marginRight: "5px"}}
			          color="success"
			          onClick={() => this.handleClickOpen("modal")}>
			          Start Test
			        </Button>
			        <Dialog
			          classes={{	
			            root: classes.center,
			            paper: classes.modal
			          }}
			          open={this.state.modal}
			          TransitionComponent={Transition}
			          keepMounted
			          onClose={() => this.handleClose("modal")}
			          aria-labelledby="modal-slide-title"
			          aria-describedby="modal-slide-description">
			          <DialogTitle
			            id="classic-modal-slide-title"
			            disableTypography
			            className={classes.modalHeader}>
			            <IconButton
			              className={classes.modalCloseButton}
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={() => this.handleClose("modal")}
			              >
			              <Close className={classes.modalClose} />
			            </IconButton>
			            <h4 className={classes.modalTitle}>Start Test</h4>
			          </DialogTitle>
			          <DialogContent
			            id="modal-slide-description"
			            className={classes.modalBody}>
						{this.state.modalErrorMessage && <p style={{color: "red"}}>{this.state.modalErrorMessage}</p>}
			            <h5>You are about to start the Test. All the best!</h5>
			            { getTestPasswordEnhacement() == 'Y' &&
			            <CustomInput
                        labelText="Enter Test Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          value: this.state.password,
                          onChange: this.handleInput,
                          onKeyPress: this.handleEnterKeyPressToStartTest
                        }}
                      /> } 
                      <p>By clicking Start Test button you agree to all our terms and conditions.</p>
			          </DialogContent>
			          <DialogActions
			            className={classes.modalFooter +" " +classes.modalFooterCenter}>
			            <Button
			              onClick={() => this.handleStartTest("modal")}
			              color="success"
			              data-cy="start test modal">
			              Start Test
			            </Button>
			        {/*   <Button
			              onClick={() => this.handleClose("modal")}
			              color="danger"
			            >
			             Exit
			            </Button>*/}
			          </DialogActions>
			        </Dialog>
				 <Button color="primary" onClick={() => this.handleGoBack()}>Go Back</Button>  
				 </GridItem>
				: isTestActive ?
				 <Button color="primary" onClick={() => this.handleResumeTest()}>Resume Test</Button>  
				: <GridItem  xs={12} sm={12} md={12} lg={2}>
					<Button size={"sm"} onClick={() => this.handleClickOpenSubmit("modal")} color="primary" data-cy="finish test">Finish Test</Button> 
					<Dialog
			          classes={{	
			            root: classes.center,
			            paper: classes.modal
			          }}
			          open={this.state.submitTestModal}
			          TransitionComponent={Transition}
			          keepMounted
			          onClose={() => this.handleCloseSubmit("modal")}
			          aria-labelledby="modal-slide-title"
			          aria-describedby="modal-slide-description">
			          <DialogTitle
			            id="classic-modal-slide-title"
			            disableTypography
			            className={classes.modalHeader}>
			            <IconButton
			              className={classes.modalCloseButton}
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={() => this.handleCloseSubmit("modal")}>
			              <Close className={classes.modalClose} />
			            </IconButton>
			            <h4 className={classes.modalTitle}>Finish Test</h4>
			          </DialogTitle>
			          	{this.state.submissionInProcess != "Y" &&
				          <DialogContent
				            id="modal-slide-description"
				            className={classes.modalBody}>
				            {this.state.progressTimer === 100 ?
				             <h5>The duration of the test is complete. 
				               Test will auto submit in {30-(this.state.testAutoSubmitSeconds)} seconds.</h5>
				            : <h5>You are about to finish the test. Are you sure?</h5>
	                       	}
	                       <p>By clicking Finish you agree to all our terms and conditions.</p>
				          </DialogContent>
			      		}
			          <DialogActions
			            className={classes.modalFooter +" " +classes.modalFooterCenter}>
			            {this.state.submissionInProcess != "Y" &&
			            <Button
			              onClick={this.finishTest}
			              color="success"
			              data-cy="finish test modal">
			              Finish Test
			            </Button>
			        	}
			        	{(this.state.submissionInProcess == "Y" && !testSubmissionFailed) ?
			             <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
			             <p>Test submission is taking longer than expected. Please be patient.</p>
			        	 <LinearProgress color="secondary" />
			        	 </div>
			        	 : null}
			        	 {testSubmissionFailed && 
			        	 	<div style={{paddingTop: "20px", paddingBottom: "20px",textAlign: "left", margin:"10px"}}>
			             	<p style={{fontSize: "16px", fontWeight: "500", color: "red"}}>Sorry, your test submission failed.</p>
			             	<p>But don't worry, the test could have been auto-saved and we have alternative methods to submit your final response.</p>
			             	<p>Note 1: We would recommend you to note your answers corresponding to each question and approach your Test Administrator with the same. Don't mark your answers corresponding to question numbers as the questions could have been randomly jumbled.</p>
			             	<p>Note 2: Do not force close the browser or force login/logout until further instructions from Test Administrator.</p>
			        	 </div>	
			        	 }
			        {/*   <Button
			              onClick={() => this.handleClose("modal")}
			              color="danger"
			            >
			             Exit
			            </Button>*/}
			          </DialogActions>
			        </Dialog>
				</GridItem>
				 : <GridItem  xs={12} sm={12} md={12} lg={3}>
				 	<Button color="primary" onClick={() => this.handleGoBack()} data-cy="go back btn">Go Back</Button> 
				 </GridItem> 
				}
			</GridContainer>	
		</div>
		);
}

}


export default  withStyles(javascriptStyles)(TestHeader);