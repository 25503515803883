import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
/*import Button from "@material-ui/core/Button";*/
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "./HeaderStyle.jsx";
//new added by Prateek
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import HeaderRightLinks from "./HeaderRightLinks.jsx";

import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import {List, ListItem, ListItemText} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';


const styles=  {
  navBar:{
    dispaly:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  mobileDrawer:{
    marginTop: "60px"
  },
  drawerMobile:{
    width: "60vw"
  },
  logoutBtn:{
    color: "white", 
    boxShadow: "none", 
    border: "1px solid",
    backgroundColor: '#223845',
    '&:hover':{
      backgroundColor: '#223845'
    }
  },
  links:{
    textDecoration: "none",
    color: "#223845"
  },
  ...headerStyle,

};


class AdminHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      brand: ""
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  logoutUser =(e)=> {
    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_users/sign_out'
    }).done((response, status, jqXHR)=>{
      sessionStorage.removeItem('as_username');
      sessionStorage.removeItem('as_isUserAnalyst');
      sessionStorage.removeItem('as_isUserMetaReadAdmin');
      sessionStorage.removeItem('user');
      this.redirectToLoginPage();
    }) 
  }

  redirectToLoginPage(){
    if(this.props.history){
      this.props.history.push("/");
    }else{
      window.location.href = getBaseURL() + "/"
    }
  }

  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
    this.setState({brand: this.props.brandDefault});
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll, brandDefault, changeBrandOnScroll} = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
        this.setState({brand: changeBrandOnScroll});
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
        this.setState({brand: brandDefault});
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const {
      classes,
      color,
      rightLinks,
      leftLinks,
      brandDefault,
      changeBrandOnScroll,
      fixed,
      absolute,
      scrollToTop
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    /*const brandComponent = (
      <Button className={classes.title}>
        {brand}
      </Button>
    );*/
    const brandComponentLogo = (
    <GridContainer>
    <GridItem lg={12}>

        <img
          onClick={scrollToTop}
          name="home"
          src={this.state.brand}
          alt="..."
          style={{height: "60px"}}
          className={
            classes.imgRaised +
            " " +
            classes.imgRounded +
            " " +
            classes.imgFluid
          }
        />
      
      </GridItem>
      {/*<GridItem lg={3}>
      <h1 className={classes.title} style={{marginBottom: "25px",fontFamily: '"Roboto Slab", "Times New Roman", serif'}}>
        {brand}
      </h1>
     </GridItem>*/}
     </GridContainer>
    );
    return (
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.containerCustom}>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
              >
              <Menu />
              </IconButton>
            </Hidden>
              {brandComponentLogo}
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
        </Toolbar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"left"}
            classes={{paper: classes.drawerMobile}}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
          >
            <div className={classes.mobileDrawer}>
              <List > 
                <ListItem button>
                  <Link to="/manage-users">
                    <ListItemText className={classes.links}>Manage Users</ListItemText>
                  </Link>
                </ListItem>
                <Divider />
                <ListItem button>
                  <Link to="/manage-user-groups">
                    <ListItemText className={classes.links}>Manage User Groups</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button>
                  <Link to="/manage-branches">
                    <ListItemText className={classes.links}>Manage Branches</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button>
                  <Link to="/manage-sections">
                    <ListItemText className={classes.links}>Manage Sections</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button>
                  <Link to="/manage-programs">
                    <ListItemText className={classes.links}>Manage Programs</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button>
                  <Link to="/manage-test-types">
                    <ListItemText className={classes.links}>Manage Test Types</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button>
                  <Link to="/manage-subjects">
                    <ListItemText className={classes.links}>Manage Subjects</ListItemText>
                  </Link>
                </ListItem>
                <Divider/>
                <ListItem button style={{marginTop: "20px"}}>
                  <Button 
                    className={classes.logoutBtn}
                    onClick={this.logoutUser}
                    size="sm"
                  >Log out </Button>
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

AdminHeader.defaultProp = {
  color: "white"
};

AdminHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
    "appTheme"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brandDefault: PropTypes.string,
  changeBrandOnScroll: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(styles)(AdminHeader);
