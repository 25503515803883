import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ScheduleTestCard from "../core/ScheduleTestCard.jsx"
import {  withStyles } from '@material-ui/core/styles';

import MuiTable from '../core/MuiTable.jsx';
import qs from 'query-string';
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import Link from '@material-ui/core/Link';


const styles = ((theme) => ({
  	section:{
  		padding: "16px 0",
      [theme.breakpoints.down('sm')]: {
       padding: "16px 0"
      },
  	},
    backLink:{
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    filters:{
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    filterSection:{
      paddingTop: "32px",
      marginRight: "16px",
      [theme.breakpoints.down('xs')]: {
        margin: "0px",
        padding: "16px 0 0"
      },
    } 
  }));

class AnalystTestAttendance extends Component {

	state ={
		schedule_data: "",
    columns: [],
    rows: [],
    errorMessages: []
	}

  componentDidMount(){
    this.fetchMarksReport();
  }


  fetchMarksReport(){
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    var url = '/analysis/test/branches-attendance.json?paper_id=' + paper_id
    $.ajax({
      type: 'GET',
      url: getBaseURL() + url,
    }).done((data, status, jqXHR)=>{
      this.processDataForTable(data); 
      this.processDataForScheduleCard(data["schedule_data"]); 
    }).fail((jqXHR, textStatus, errorThrown) => {
      //TODO: handle properly
        var errorMessages = this.state.errorMessages;
        errorMessages.push({message: jqXHR["responseJSON"].message})
        this.setState({errorMessages: jqXHR["responseJSON"].message});
    })
  }


  processDataForTable(data){
    var columns=["Campus", "Total", "Present","Absent", "Average(Marks)", "Percentage(Marks)", "Attendance %"]
    var rows = []
    var overallRow = {
      name: "Overall",
      student_count: 0,
      student_attendance: 0,
      average_marks: 0,
      marks_percentage: 0,
      attendance_percentage: 0,
      average_marks_sum: 0,
      marks_percentage_sum: 0
    }
    for (let branch_info of data["attendance_data"]) { 
      rows.push([branch_info.name,branch_info.branch_student_count,branch_info.branch_student_attendance,branch_info.branch_student_count-branch_info.branch_student_attendance,branch_info.branch_average_marks,branch_info.branch_marks_percentage,branch_info.branch_attendance_percentage])
      
      overallRow["student_count"] = overallRow["student_count"] + branch_info.branch_student_count
      overallRow["student_attendance"] = overallRow["student_attendance"] + branch_info.branch_student_attendance
      overallRow["average_marks_sum"] = overallRow["average_marks_sum"] + branch_info.branch_average_marks*branch_info.branch_student_attendance
      overallRow["marks_percentage_sum"] = overallRow["marks_percentage_sum"] + branch_info.branch_marks_percentage*branch_info.branch_student_attendance
    }
    overallRow["average_marks"] = (overallRow["average_marks_sum"]/overallRow.student_attendance);
    overallRow["marks_percentage"] = (overallRow["marks_percentage_sum"]/overallRow.student_attendance);
    overallRow["attendance_percentage"] = (100*overallRow.student_attendance/overallRow.student_count).toFixed(2);
    rows.push([overallRow.name,overallRow.student_count,overallRow.student_attendance,overallRow.student_count-overallRow.student_attendance,overallRow.average_marks,overallRow.marks_percentage,overallRow.attendance_percentage])
    this.setState({rows: rows, columns: columns})
  }


  processDataForScheduleCard(schedule_data){
    var schedule_data = {
      name: schedule_data["schedule_name"],
      program: schedule_data["schedule_program"],
      test_type: schedule_data["schedule_test_type"],
      user_group: schedule_data["schedule_user_group"],
      start_time: formatDate(schedule_data["schedule_start_time"]),
    }
    this.setState({schedule_data: schedule_data})
  }


  goBack = (e) => {
    e.preventDefault();
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    this.props.history.push('/analysis/test?paper_id='+paper_id);
  }

  getReportName(name){
    return (name ? "_" + name : "")
  }

  render () {
  	const {classes} = this.props;  
    const {schedule_data,columns,rows} = this.state;  
  	return(
  		<React.Fragment>
        <Link className={classes.backLink}
          component="button"
          variant="body2" 
          onClick={this.goBack}>
          Back to Test Reports
        </Link>
        { schedule_data &&
  			 <ScheduleTestCard data={schedule_data}/>
  		  }  
          <div className={classes.section}>
            <MuiTable columns={columns} rows={rows} title={"Campus Wise Attendance"} downloadTitle={"Campus Wise Attendance" + this.getReportName(schedule_data["name"])}/>
          </div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystTestAttendance);