import React, { useState } from "react";
import { getBaseURL } from "../helpers/HostURLHelper.jsx";
import $ from "jquery";
import ErrorBoundary from "components/ErrorBoundary.js";

import GridContainer from "../../exports/components/Grid/GridContainer";
import GridItem from "../../exports/components/Grid/GridItem";
import Button from "../../exports/components/CustomButtons/Button.jsx";
import CustomSearchBar from "../misc/CustomSearchBar";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import testFormStyle from "../styles/TestFormStyle.js";
import modalStyle from "../../exports/assets/jss/material-kit-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Close from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Hidden from "@material-ui/core/Hidden";
import SettingsIcon from "@material-ui/icons/Settings";
import PageviewIcon from "@material-ui/icons/Pageview";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const getMuiThemeForTable = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    MUIDataTableToolbarSelect: {
      title: {
        display: "none",
      },
      root: {
        backgroundColor: "none",
        boxShadow: "none",
        justifyContent: "normal",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 16px",
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.85rem",
        height: "48px",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        color: "rgba(0,0,0,0.54)",
        padding: "4px 16px",
        fontSize: "0.75rem",
        fontWeight: "500",
        height: "56px",
        textAlign: "center",
      },
      fixedHeader: {
        zIndex: "none",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "0px",
      },
      caption: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.75rem",
      },
      select: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.75rem",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const getMuiThemeForSearchBar = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        border: "1px solid #aaacb1",
      },
      rounded: {
        borderRadius: "10px",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const getMuiThemeForModal = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "600px",
        height: "390px",
      },
    },
    MuiModal: {
      root: {
        zIndex: "90000",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const getMuiThemeForEditModal = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "600px",
        height: "600px",
      },
    },
    MuiModal: {
      root: {
        zIndex: "90000",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton: {
    padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton: {
    textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  ...testFormStyle,
  cancelButton: {
    textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right",
  },
  ...modalStyle,
  tableDetails: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    "& > *": {
      margin: "8px",
    },
  },
  select: {
    marginTop: "8px",
    [theme.breakpoints.down("md")]: {
      margin: "8px",
      width: "96%",
    },
  },
  modalBodyMob: {
    paddingTop: "24px",
    paddingRight: "12px",
    paddingBottom: "16px",
    paddingLeft: "12px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  modalHeaderMob: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
    },
  },
  inputLabel: {
    [theme.breakpoints.down("md")]: {
      width: "96%",
    },
  },
  responsive: {
    paddingTop: "0px",
    paddingBottom: "0px",
    [theme.breakpoints.down("md")]: {
      padding: "12px",
    },
  },
  registerBtn: {
    textTransform: "none",
    backgroundColor: "#3f51b5",
    padding: "8px 20px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  accordion: {
    boxShadow: "none",
    borderRadius: "10px",
  },
  modalPaper: {
    borderRadius: "6px",
    height: "470px",
  },
  autoComplete: {
    width: "100%",
    margin: "16px 8px",
    [theme.breakpoints.down("md")]: {
      width: "96%",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdvancedSearchMode: false,
      isModalOpen: false,
      isManageUserOpen: false,
      userSearchResults: [],
      showPassword: false,
      userInfo: {
        username: "",
        name: "",
        phone: "",
        email: "",
        branchId: "",
        programId: "",
        sectionId: "",
      },
      editUserInfo: {
        name: "",
        phone: "",
        email: "",
        password: "",
        branchId: "",
        programId: "",
        sectionId: "",
      },
      branches: [],
      sections: [],
      programs: [],
      userRoles: [],
      selectedUserRoles: [],
      newRoleId: null,
      selectedUserId: 0,
      alertOpen: false,
      successMessage: "",
      errorMessage: "",
      showPassword: false,
    };
  }

  handleShowPassword = (e) => {
    var showPassword = this.state.showPassword;
    this.setState({ showPassword: !showPassword });
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ successMessage: "" });
    this.setState({ errorMessage: "" });
    this.setState({ alertOpen: false });
  };

  fetchData = () => {
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/branches.json",
    }).done((data) => {
      var branches = [];
      branches = data["global_branches"];
      this.setState({ branches });
    });

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/global_programs.json",
    }).done((data) => {
      var programs = [];
      programs = data["programs"];
      this.setState({ programs });
    });

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/sections.json",
    }).done((data) => {
      var sections = [];
      sections = data["global_sections"];
      this.setState({ sections });
    });

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/roles.json",
    }).done((data) => {
      var userRoles = [];
      userRoles = data["roles"];
      this.setState({ userRoles });
    });
  };

  populateUsersFromResults(data) {
    var globalUsers = data;
    var userSearchResults = [];

    if (globalUsers && globalUsers.length > 0) {
      for (var i in globalUsers) {
        userSearchResults.push([
          globalUsers[i].id,
          globalUsers[i].username,
          globalUsers[i].name,
          globalUsers[i].branch_name,
          globalUsers[i].section_name,
          globalUsers[i].program_name,
          globalUsers[i].email,
          globalUsers[i].phone,
          globalUsers[i].deleted_at,
        ]);
      }
      this.setState({ userSearchResults });
    } else {
      this.setState({ userSearchResults: [] });
    }
  }

  fetchUsers = () => {
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/search_users.json?limit=50&offset=0",
    }).done((data) => {
      this.populateUsersFromResults(data);
    });
  };

  changeSearchMode = (e) => {
    var currentMode = this.state.isAdvancedSearchMode;
    this.setState({ isAdvancedSearchMode: !currentMode });
  };

  goToCreateNewUser = (e) => {
    this.props.history.push("/create-new-user");
  };

  handleOpenModal = (id, email) => {
    this.setState({ isModalOpen: true });
    this.setState({ selectedUserId: id });
    $.ajax({
      type: "GET",
      url: getBaseURL() + `/search_users.json?limit=50&offset=0&email=${email}`,
    }).done((data) => {
      var {
        name,
        email,
        phone,
        global_branch_id,
        global_program_id,
        global_section_id,
      } = data[0];
      var editUserInfo = {
        name,
        email,
        phone,
        branchId: global_branch_id,
        programId: global_program_id,
        sectionId: global_section_id,
      };

      this.setState({ editUserInfo });
    });
  };

  handleChangeEditUserInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      editUserInfo: { ...this.state.editUserInfo, [name]: value },
    });
  };

  handleEditInfo = (id) => {
    const { name, email, phone, password, branchId, programId, sectionId } =
      this.state.editUserInfo;

    const postData = {
      name,
      email,
      phone,
      password,
      branch_id: branchId,
      program_id: programId,
      section_id: sectionId,
    };

    $.ajax({
      type: "POST",
      url: getBaseURL() + `/update-user?user_id=${id}`,
      data: postData,
    })
      .done((response) => {
        var message = "User Edited Successfully";
        this.setState({ successMessage: message });
        this.setState({ alertOpen: true });
        this.setState({
          editUserInfo: {
            name: "",
            phone: "",
            email: "",
            password: "",
            branchId: "",
            programId: "",
            sectionId: "",
          },
        });
        this.handleCloseModal();
        this.fetchUsers();
      })
      .fail((error) => {
        const { responseJSON } = error;
        var errorMessage = responseJSON.message;
        this.setState({ errorMessage });
        this.setState({ alertOpen: true });
      });
  };

  handleCloseModal = (e) => {
    this.setState({ isModalOpen: false });
  };

  fetchSelectedUserRoles = (id) => {
    $.ajax({
      type: "GET",
      url: getBaseURL() + `/search_user_roles.json?user_id=${id}`,
    }).done((data) => {
      var selectedUserRoles = data;
      this.setState({ selectedUserRoles });
    });
  };

  handleOpenManageModal = (id) => {
    this.setState({ isManageUserOpen: true });
    this.setState({ selectedUserId: id });
    this.fetchSelectedUserRoles(id);
  };

  handleGrantRole = (userId, roleId) => {
    const postData = { user_role: { user_id: userId, role_id: roleId } };
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/user_role",
      data: postData,
    })
      .done((data) => {
        this.fetchSelectedUserRoles(userId);
        this.setState({ newRoleId: null });
      })
      .fail((error) => {
        var errorMessage = "Something went wrong";
        this.setState({ errorMessage });
        this.setState({ alertOpen: true });
      });
  };

  handleDeleteRole = (userId, roleId) => {
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        `/remove_user_role.json?user_id=${userId}&role_id=${roleId}`,
    }).done((data) => {
      this.fetchSelectedUserRoles(userId);
    });
  };

  handleCloseManageModal = (e) => {
    this.setState({ selectedUserId: 0, newRoleId: 0 });
    this.setState({ isManageUserOpen: false });
  };

  handleOnChangeAddUserInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ userInfo: { ...this.state.userInfo, [name]: value } });
  };

  handleAssociateUser = (id) => {
    const postData = { reactivateUser: "Y" };
    $.ajax({
      type: "POST",
      url: getBaseURL() + `/update-user?user_id=${id}`,
      data: postData,
    }).done((data) => {
      this.fetchUsers();
    });
  };

  handleDissociateUser = (id) => {
    const postData = { deactivateUser: "Y" };
    $.ajax({
      type: "POST",
      url: getBaseURL() + `/update-user?user_id=${id}`,
      data: postData,
    }).done((data) => {
      this.fetchUsers();
    });
  };

  handleTestSearch = (searchText) => {
    if (searchText && searchText.length > 3) {
      //Minimum three characters to search
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => this.searchUsers(searchText), 1000);
    } else {
      this.setState({ userSearchResults: [] });
    }
  };

  searchUsers(searchText) {
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        `/search_users.json?limit=50&offset=0&search=${searchText}`,
    })
      .done((data) => {
        this.populateUsersFromResults(data);
      })
      .fail((error) => {});
  }

  handleTestAdvSearch = (e) => {
    e.preventDefault();
    var userInfo = this.state.userInfo;

    var url = getBaseURL() + "/search_users.json?limit=50&offset=0";

    if (userInfo["name"]) {
      url = url + "&name=" + userInfo["name"];
    }

    if (userInfo["username"]) {
      url = url + "&username=" + userInfo["username"];
    }

    if (userInfo["email"]) {
      url = url + "&email=" + userInfo["email"];
    }

    if (userInfo["phone"]) {
      url = url + "&phone=" + userInfo["phone"];
    }

    if (userInfo["programId"]) {
      url = url + "&program_id=" + userInfo["programId"];
    }

    if (userInfo["branchId"]) {
      url = url + "&branch_id=" + userInfo["branchId"];
    }

    if (userInfo["sectionId"]) {
      url = url + "&section_id=" + userInfo["sectionId"];
    }

    $.ajax({
      type: "GET",
      url: url,
    })
      .done((data) => {
        this.populateUsersFromResults(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {});
  };

  handleTestAdvSearchReset = (e) => {
    e.preventDefault();
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        username: "",
        name: "",
        phone: "",
        email: "",
      },
    });

    this.fetchUsers();
  };

  handleProgramChange = (event, newValue) => {
    if (newValue != null) {
      this.setState({
        userInfo: { ...this.state.userInfo, programId: newValue.id },
      });
    } else {
      this.setState({ userInfo: { ...this.state.userInfo, programId: "" } });
    }
  };

  handleBranchChange = (event, newValue) => {
    if (newValue != null) {
      this.setState({
        userInfo: { ...this.state.userInfo, branchId: newValue.id },
      });
    } else {
      this.setState({ userInfo: { ...this.state.userInfo, branchId: "" } });
    }
  };

  handleSectionChange = (event, newValue) => {
    if (newValue != null) {
      this.setState({
        userInfo: { ...this.state.userInfo, sectionId: newValue.id },
      });
    } else {
      this.setState({ userInfo: { ...this.state.userInfo, sectionId: "" } });
    }
  };

  Transition = (props) => {
    return <Slide direction="down" {...props} />;
  };

  componentDidMount() {
    this.fetchUsers();
    this.fetchData();
  }

  render() {
    const { classes } = this.props;
    const { userInfo, editUserInfo, showPassword } = this.state;

    const titleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px",
    };
    const tableTitleStyle = {
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      padding: "0px",
      paddingTop: "10px",
    };
    const usersTableOptions = {
      filter: false,
      selectableRows: "none",
      search: false,
      print: false,
      download: false,
      elevation: 0,
      viewColumns: false,
      responsive: "simple",
      expandableRows: false,
      setRowProps: (row) => {
        return {
          style: { textAlign: "center" },
        };
      },
    };

    const usersTableColumns = [
      {
        name: "id",
        options: {
          display: false,
        },
      },
      {
        name: "UserID",
        options: {
          customBodyRender: (value, updateValue) => {
            return <div>{value}</div>;
          },
        },
      },
      {
        name: "Full Name",

        options: {
          customBodyRender: (value, updateValue) => {
            return <div>{value}</div>;
          },
        },
      },
      { name: "Branch" },
      { name: "Section" },
      { name: "Program" },
      {
        name: "Email",
        options: {
          display: false,
          // customBodyRender: (value, updateValue) => {
          // 	return(
          // 		<div>
          // 			{value}
          // 		</div>
          // 	);
          // }
        },
      },
      { name: "Phone" },
      {
        name: "Active",
        options: {
          display: false,
        },
      },
      {
        name: " ",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowData } = tableMeta;
            const id = rowData[0];
            return (
              <div style={{ width: "10px" }}>
                <IconButton
                  style={{ color: "black" }}
                  fontSize="sm"
                  onClick={() => {
                    this.handleOpenManageModal(id);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </div>
            );
          },
        },
      },
      {
        name: " ",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowData } = tableMeta;
            const id = rowData[0];
            const email = rowData[6];
            return (
              <div style={{ width: "10px" }}>
                <IconButton
                  style={{ color: "black" }}
                  onClick={(e) => {
                    this.handleOpenModal(id, email);
                  }}
                  fontSize="sm"
                >
                  <EditIcon />
                </IconButton>
              </div>
            );
          },
        },
      },
      {
        name: " ",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowData } = tableMeta;
            const id = rowData[0];
            const active = rowData[8];
            return (
              <div style={{ width: "10px" }}>
                {active ? (
                  <IconButton
                    style={{ color: "green" }}
                    onClick={(e) => {
                      this.handleAssociateUser(id);
                    }}
                    fontSize="sm"
                  >
                    <AddCircleIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{ color: "red" }}
                    onClick={(e) => {
                      this.handleDissociateUser(id);
                    }}
                    fontSize="sm"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                )}
              </div>
            );
          },
        },
      },
    ];

    const ManageUserRoleForm = (props) => {
      const {
        selectedUserRoles,
        userRoles,
        handleNewRole,
        newRoleId,
        selectedUserId,
      } = props;
      return (
        <React.Fragment>
          <GridContainer
            style={{
              backgroundColor: "white",
              paddingTop: "0px",
              width: "100%",
              margin: "10px 0px 0px",
              marginTop: "0px",
            }}
            spacing={24}
          >
            <GridItem xs={12} sm={12} md={5} style={{ padding: "12px" }}>
              <h4>Associated Roles</h4>
              {selectedUserRoles.map((role) => {
                return (
                  <div style={{ paddingBottom: "10px" }}>
                    <Chip
                      icon={<FaceIcon />}
                      label={role.name}
                      onClick={this.handleClickRole}
                      onDelete={(e) => {
                        this.handleDeleteRole(selectedUserId, role.id);
                      }}
                    />
                  </div>
                );
              })}
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={2}
              style={{ padding: "12px" }}
            ></GridItem>
            <GridItem xs={12} sm={12} md={5} style={{ padding: "12px" }}>
              <h4>Grant New Role</h4>
              <form>
                <TextField
                  variant="outlined"
                  label="Role"
                  select
                  helperText="Select Role for User"
                  className={classes.inputLabel}
                  fullWidth
                  name="roleId"
                  value={newRoleId}
                  onChange={(e) => {
                    this.setState({ newRoleId: e.target.value });
                  }}
                >
                  {userRoles.map((role) => {
                    return (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </form>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    };

    return (
      <React.Fragment>
        <GridContainer
          style={{
            backgroundColor: "white",
            paddingTop: "0px",
            paddingBottom: "20px",
            width: "100%",
            margin: "10px 0px 0px",
            marginTop: "0px",
          }}
          spacing={24}
        >
          {this.state.successMessage && (
            <Snackbar
              open={this.state.alertOpen}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={3000}
              onClose={this.handleCloseAlert}
            >
              <Alert
                onClose={this.handleCloseAlert}
                severity="success"
                style={{ marginTop: "50px" }}
              >
                {this.state.successMessage}
              </Alert>
            </Snackbar>
          )}
          {this.state.errorMessage && (
            <Snackbar
              open={this.state.alertOpen}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={3000}
              onClose={this.handleCloseAlert}
            >
              <Alert
                onClose={this.handleCloseAlert}
                severity="error"
                style={{ marginTop: "50px" }}
              >
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          )}
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <h1 style={titleStyle}>Manage Users</h1>
            <hr />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={this.goToCreateNewUser}
              startIcon={<AddIcon />}
              className={classes.registerBtn}
            >
              Register New User
            </Button>
          </GridItem>
          <React.Fragment>
            <GridItem
              xs={12}
              sm={12}
              md={2}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></GridItem>
            <GridItem xs={12} sm={12} md={10} className={classes.responsive}>
              <Hidden smDown>
                <div>
                  <h3 style={subtitleStyle}>Search Users</h3>
                </div>
              </Hidden>
            </GridItem>
            <Hidden smDown>
              <GridItem
                xs={12}
                sm={12}
                md={2}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              ></GridItem>
            </Hidden>
            <GridItem
              style={{ backgroundColor: "#ffffff", padding: "8px 12px" }}
              xs={12}
              sm={12}
              md={7}
            >
              <div>
                <MuiThemeProvider theme={getMuiThemeForSearchBar}>
                  <CustomSearchBar
                    inputPlaceholder={"Enter UserID or Name or Mobile or Email"}
                    child1={
                      <Hidden smDown>
                        <Button
                          onClick={this.changeSearchMode}
                          style={{
                            textTransform: "none",
                            backgroundColor: "#3f51b5",
                            padding: "8px 20px",
                            margin: "0px",
                          }}
                        >
                          {this.state.isAdvancedSearchMode
                            ? "Basic Search"
                            : "Advanced Search"}
                        </Button>
                      </Hidden>
                    }
                    handleSearch={this.handleTestSearch}
                    hintText={"Please enter minimum 4 characters"}
                  />
                </MuiThemeProvider>
              </div>
            </GridItem>
            <Hidden smDown>
              <GridItem
                xs={12}
                sm={12}
                md={2}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              ></GridItem>
            </Hidden>
            {this.state.isAdvancedSearchMode && (
              <React.Fragment>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={3}
                  style={{ padding: "0px 12px" }}
                >
                  <form className={classes.form}>
                    <TextField
                      variant="outlined"
                      label="User Name"
                      placeholder="Enter User Name"
                      fullWidth
                      className={classes.inputLabel}
                      name="username"
                      value={userInfo.username}
                      onChange={this.handleOnChangeAddUserInfo}
                    />

                    <TextField
                      variant="outlined"
                      label="Full Name"
                      placeholder="Enter Full Name"
                      fullWidth
                      className={classes.inputLabel}
                      name="name"
                      value={userInfo.name}
                      onChange={this.handleOnChangeAddUserInfo}
                    />

                    <TextField
                      variant="outlined"
                      label="Email"
                      placeholder="Enter Email"
                      fullWidth
                      className={classes.inputLabel}
                      name="email"
                      value={userInfo.email}
                      onChange={this.handleOnChangeAddUserInfo}
                    />

                    <TextField
                      variant="outlined"
                      label="Phone"
                      placeholder="Enter Phone"
                      fullWidth
                      className={classes.inputLabel}
                      name="phone"
                      value={userInfo.phone}
                      onChange={this.handleOnChangeAddUserInfo}
                    />
                  </form>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={3}
                  style={{ padding: "0px 12px" }}
                >
                  <form className={classes.form}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.programs}
                      onChange={this.handleProgramChange}
                      classes={{ root: classes.autoComplete }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Program"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.branches}
                      onChange={this.handleBranchChange}
                      classes={{ root: classes.autoComplete }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Branch"
                          variant="outlined"
                        />
                      )}
                    />

                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.sections}
                      onChange={this.handleSectionChange}
                      classes={{ root: classes.autoComplete }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Section"
                          variant="outlined"
                        />
                      )}
                    />
                  </form>
                  <Button
                    onClick={this.handleTestAdvSearch}
                    style={{
                      textTransform: "none",
                      backgroundColor: "#3f51b5",
                      padding: "8px 20px",
                      margin: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Advanced Search
                  </Button>
                  <Button
                    onClick={this.handleTestAdvSearchReset}
                    style={{
                      textTransform: "none",
                      backgroundColor: "#3f51b5",
                      padding: "8px 20px",
                      margin: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Reset
                  </Button>
                </GridItem>
              </React.Fragment>
            )}
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ padding: "12px", margin: "0px 12px" }}
            >
              <Hidden smDown>
                <MuiThemeProvider theme={getMuiThemeForTable}>
                  <MUIDataTable
                    title={<p style={tableTitleStyle}>Users</p>}
                    options={usersTableOptions}
                    columns={usersTableColumns}
                    data={this.state.userSearchResults}
                  />
                </MuiThemeProvider>
              </Hidden>
              <Hidden mdUp>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={tableTitleStyle}>Users</h4>
                </GridItem>
                {this.state.userSearchResults.map((column) => {
                  return (
                    <GridItem xs={12} style={{ padding: "6px 0px" }}>
                      <Accordion className={classes.accordion} square>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{column[1]}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.tableDetails}>
                          <Typography>Name: {column[2]}</Typography>
                          <Typography>Branch: {column[3]}</Typography>
                          <Typography>Section: {column[4]}</Typography>
                          <Typography>Program: {column[5]}</Typography>
                          {/*<Typography>
									            Email: {column[6]}
									          </Typography>*/}
                          <Typography>Mobile: {column[7]}</Typography>
                        </AccordionDetails>
                        <AccordionActions>
                          {/*									         <IconButton style={{color: 'black'}} fontSize="sm" onClick={(e)=>{this.handleOpenManageModal(column[0])}}><SettingsIcon /></IconButton>
                           */}{" "}
                          <IconButton
                            style={{ color: "black" }}
                            fontSize="sm"
                            onClick={(e) => {
                              this.handleOpenModal(column[0], column[6]);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          {column[8] ? (
                            <IconButton
                              style={{ color: "green" }}
                              onClick={(e) => {
                                this.handleAssociateUser(column[0]);
                              }}
                              fontSize="sm"
                            >
                              <AddCircleIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{ color: "red" }}
                              onClick={(e) => {
                                this.handleDissociateUser(column[0]);
                              }}
                              fontSize="sm"
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          )}
                        </AccordionActions>
                      </Accordion>
                    </GridItem>
                  );
                })}
              </Hidden>
              <div>
                <MuiThemeProvider theme={getMuiThemeForEditModal}>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modalPaper,
                    }}
                    open={this.state.isModalOpen}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={this.handleCloseModal}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ margin: "auto 0" }}>Edit User Details</h4>
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.handleCloseModal}
                        >
                          <Close className={classes.modalClose} />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent
                      style={{ paddingBottom: "0px", overflowY: "visible" }}
                      id="modal-slide-description"
                      className={classes.modalBodyMob}
                    >
                      <React.Fragment>
                        <GridContainer
                          style={{
                            backgroundColor: "white",
                            paddingTop: "0px",
                            width: "100%",
                            margin: "10px 0px 0px",
                            marginTop: "0px",
                          }}
                          spacing={24}
                        >
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{ padding: "6px" }}
                          >
                            <form className={classes.form}>
                              <TextField
                                variant="outlined"
                                label="Full Name"
                                placeholder="Enter Full Name"
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="name"
                                value={editUserInfo.name}
                                onChange={this.handleChangeEditUserInfo}
                              />

                              <TextField
                                variant="outlined"
                                label="Phone No."
                                placeholder="Enter Phone Number"
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="phone"
                                value={editUserInfo.phone}
                                onChange={this.handleChangeEditUserInfo}
                              />

                              <TextField
                                variant="outlined"
                                label="Email"
                                placeholder="Enter Email"
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="email"
                                value={editUserInfo.email}
                                onChange={this.handleChangeEditUserInfo}
                              />

                              <TextField
                                variant="outlined"
                                label="New Password"
                                required
                                placeholder="Enter New Password"
                                fullWidth
                                className={classes.inputLabel}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={this.handleShowPassword}
                                      >
                                        {showPassword ? (
                                          <Visibility fontSize="small" />
                                        ) : (
                                          <VisibilityOff fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                name="password"
                                value={editUserInfo.password}
                                onChange={this.handleChangeEditUserInfo}
                              />
                            </form>
                          </GridItem>
                          <Hidden smDown>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={1}
                              style={{ padding: "6px" }}
                            ></GridItem>
                          </Hidden>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{ padding: "6px" }}
                          >
                            <form className={classes.form}>
                              <TextField
                                variant="outlined"
                                label="Branch"
                                select
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="branchId"
                                value={editUserInfo.branchId}
                                onChange={this.handleChangeEditUserInfo}
                              >
                                {this.state.branches.map((branch) => {
                                  return (
                                    <MenuItem key={branch.id} value={branch.id}>
                                      {branch.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <TextField
                                variant="outlined"
                                label="Program"
                                select
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="programId"
                                value={editUserInfo.programId}
                                onChange={this.handleChangeEditUserInfo}
                              >
                                {this.state.programs.map((program) => {
                                  return (
                                    <MenuItem
                                      key={program.id}
                                      value={program.id}
                                    >
                                      {program.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <TextField
                                variant="outlined"
                                label="Section"
                                select
                                fullWidth
                                required
                                className={classes.inputLabel}
                                name="sectionId"
                                value={editUserInfo.sectionId}
                                onChange={this.handleChangeEditUserInfo}
                              >
                                {this.state.sections.map((section) => {
                                  return (
                                    <MenuItem
                                      key={section.id}
                                      value={section.id}
                                    >
                                      {section.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </form>
                          </GridItem>
                        </GridContainer>
                      </React.Fragment>
                    </DialogContent>
                    <DialogActions
                      style={{ padding: "14px", paddingRight: "30px" }}
                      className={
                        classes.modalFooter + " " + classes.modalFooterRight
                      }
                    >
                      <Button
                        size="sm"
                        onClick={(e) => {
                          this.handleEditInfo(this.state.selectedUserId);
                        }}
                        className={classes.searchButton}
                      >
                        Save
                      </Button>
                      <Button
                        size="sm"
                        onClick={this.handleCloseModal}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </MuiThemeProvider>
              </div>
              <div>
                <MuiThemeProvider theme={getMuiThemeForModal}>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    open={this.state.isManageUserOpen}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={this.handleCloseManageModal}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeaderMob}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleCloseManageModal}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                      <h4 className={classes.modalTitle}>Manage Roles</h4>
                    </DialogTitle>
                    <DialogContent
                      style={{ paddingBottom: "0px", overflowY: "visible" }}
                      id="modal-slide-description"
                      className={classes.modalBodyMob}
                    >
                      {
                        <ManageUserRoleForm
                          selectedUserRoles={this.state.selectedUserRoles}
                          userRoles={this.state.userRoles}
                          newRoleId={this.state.newRoleId}
                          selectedUserId={this.state.selectedUserId}
                          handleNewRole={this.handleNewRole}
                        />
                      }
                    </DialogContent>
                    <DialogActions
                      style={{ padding: "14px", paddingRight: "30px" }}
                      className={
                        classes.modalFooter + " " + classes.modalFooterRight
                      }
                    >
                      <Button
                        size="sm"
                        onClick={(e) => {
                          this.handleGrantRole(
                            this.state.selectedUserId,
                            this.state.newRoleId
                          );
                        }}
                        className={classes.searchButton}
                      >
                        Grant New Role
                      </Button>
                      <Button
                        size="sm"
                        onClick={this.handleCloseManageModal}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </MuiThemeProvider>
              </div>
            </GridItem>
          </React.Fragment>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ManageUsers);
