import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ScheduleTestCard from "../core/ScheduleTestCard.jsx"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {  withStyles } from '@material-ui/core/styles';

import ExpandableTable from '../core/ExpandableTable.jsx';
import MuiTable from '../core/MuiTable.jsx';
import qs from 'query-string';
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import Link from '@material-ui/core/Link';
import ComboBox from "../core/ComboBox.jsx"


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

const styles = ((theme) => ({
  	section:{
  		padding: "16px 0",
      [theme.breakpoints.down('sm')]: {
       padding: "16px 0"
      },
  	},
    backLink:{
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    filters:{
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    filterSection:{
      paddingTop: "32px",
      marginRight: "16px",
      [theme.breakpoints.down('xs')]: {
        margin: "0px",
        padding: "16px 0 0"
      },
    } 
  }));

class AnalystTestMarks extends Component {

	state ={
		paperTab: 0,
    columns: [],
    rows: [],
    errorMessages: [],
    schedule_data: "",
    selectedBranch: "",
    selectedSection: "",
    allBranches: [],
    allSections: [],
    testAverageData: {},
    avgRows: [],
    avgColumns: [],
    selectedBranchName: "",
    selectedSectionName: ""
	}

  componentDidMount(){
    this.fetchMarksReport();
    this.fetchBranchesAndSections();
  }

  fetchMarksReport(){
    const branch_id = qs.parse(this.props.location.search).branch_id; 
    const section_id = qs.parse(this.props.location.search).section_id; 
    this.fetchMarksReport(branch_id,section_id);
  }

  fetchMarksReport(branch_id,section_id){
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    var url = '/analysis/test/marks-report.json?paper_id=' + paper_id
    if(branch_id){
      url = url+'&branch_id='+branch_id
    }
    if(section_id){
      url = url+'&section_id='+section_id
    }
    $.ajax({
      type: 'GET',
      url: getBaseURL() + url,
    }).done((data, status, jqXHR)=>{
      this.processDataForTable(data); 
      this.processDataForScheduleCard(data["schedule_data"]); 
    }).fail((jqXHR, textStatus, errorThrown) => {
      //TODO: handle properly
        var errorMessages = this.state.errorMessages;
        errorMessages.push({message: jqXHR["responseJSON"].message})
        this.setState({errorMessages: jqXHR["responseJSON"].message});
    })
  }

  fetchBranchesAndSections(){
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    var url = '/analysis/branches-sections.json'
    if(paper_id){
      url = url + '?paper_id='+paper_id
    }
    $.ajax({
      type: 'GET',
      url: getBaseURL() + url,
    }).done((data, status, jqXHR)=>{
      this.processBranchesAndSections(data);
    }).fail((jqXHR, textStatus, errorThrown) => {
      //TODO: handle properly
    })
  }

  processBranchesAndSections(data){
    var allBranches = []
    var branches = data["branches"]
    allBranches.push({
      name: "All",
      branch_id: ""
    })
    for(let branch of branches){
      allBranches.push({
        name: branch.name,
        branch_id: branch.id
      })
    }

    var allSections = []
    var sections = data["sections"]
    allSections.push({
      name: "All",
      section_id: ""
    })
    for(let section of sections){
      allSections.push({
        name: section.name,
        section_id: section.id
      })
    }

    this.setState({allBranches: allBranches, allSections: allSections})
  }


  processDataForTable(data){
    var schedule_data = data["schedule_data"]
    var testAverageData = {}
    testAverageData["full_marks"] = (schedule_data.total_marks*schedule_data.attended_students)
    testAverageData["attended_students"] = schedule_data.attended_students
    testAverageData["total_awarded_marks"] = 0
    testAverageData["subjects_info"] = []

    var subArray = []
    var columns = []    
    columns.push("UserId")
    columns.push("Name")
    columns.push("Campus")
    columns.push("Section")
    if (schedule_data && schedule_data["subjects_info"]){
      for (let sub_info of schedule_data["subjects_info"]) { 
          columns.push(sub_info.sub_short_name + " ("+sub_info.sub_total_marks+")");
          subArray.push(sub_info.sub_short_name)
          testAverageData["subjects_info"].push({
            sub_short_name: sub_info.sub_short_name,
            full_marks: (sub_info.sub_total_marks*schedule_data.attended_students),
            sub_total_marks: sub_info.sub_total_marks,
            total_awarded_marks: 0
          })
      }
    }
    columns.push("Total")
    columns.push("Campus Rank")
    columns.push("Section Rank")
    columns.push("Overall Rank" + " ("+schedule_data.attended_students+")")

    
    var rows = []
    var user_results = data["user_results"]
    if (user_results){
      for (let user_result of user_results) { 
          var row = []
          row.push(user_result.username)
          row.push(user_result.name)
          row.push(user_result.branch_name)
          row.push(user_result.section_name)
          if(user_result["subject_results"]){
            var subIndx = 0;
            for (let sub_result of user_result["subject_results"]) {
              if(sub_result.total_marks && subArray[subIndx] == sub_result.sub_short_name) {
                row.push(sub_result.total_marks + " ("+sub_result.rank+")")
                testAverageData["subjects_info"][subIndx]["total_awarded_marks"] = testAverageData["subjects_info"][subIndx]["total_awarded_marks"] + (sub_result.total_marks ? sub_result.total_marks : 0)
              }else{
                row.push("-")
              }
              subIndx++;
            }
          } 
          row.push(user_result.total_marks ? user_result.total_marks : "-" )
          row.push(user_result.branch_rank ? user_result.branch_rank : "-")
          row.push(user_result.section_rank ? user_result.section_rank : "-")
          row.push(user_result.rank ? user_result.rank : "-")
          rows.push(row)
          //average data
          testAverageData["total_awarded_marks"] = testAverageData["total_awarded_marks"]+ (user_result.total_marks ? user_result.total_marks: 0) 
      }
    }
    this.processDataForAverageSummary(testAverageData);
    this.setState({columns: columns, rows: rows, testAverageData: testAverageData})
  }

  processDataForAverageSummary(testAverageData){

    var avgColumns = ["Subject","Full Marks", "Secured Marks","Average","Percentage"]
    
    var avgRows = [];
    for (let sub of testAverageData["subjects_info"]) {
      var subAvg = (sub.total_awarded_marks/testAverageData.attended_students).toFixed(2);
      var subPercentage = (100*sub.total_awarded_marks/sub.full_marks).toFixed(2);
      avgRows.push([sub.sub_short_name, sub.full_marks, sub.total_awarded_marks, subAvg, subPercentage])
    }
    var avg = (testAverageData.total_awarded_marks/testAverageData.attended_students).toFixed(2);
    var percentage = (100*testAverageData.total_awarded_marks/testAverageData.full_marks).toFixed(2);
    avgRows.push(["Overall", testAverageData.full_marks, testAverageData.total_awarded_marks, avg, percentage])
    this.setState({avgColumns: avgColumns,avgRows: avgRows})
  }

  processDataForScheduleCard(schedule_data){
    var schedule_data = {
      name: schedule_data["schedule_name"],
      program: schedule_data["schedule_program"],
      test_type: schedule_data["schedule_test_type"],
      user_group: schedule_data["schedule_user_group"],
      start_time: formatDate(schedule_data["schedule_start_time"]),
      students_in_group: schedule_data["students_in_group"],
      attended_students: schedule_data["attended_students"],
      attendance_percent: Math.round(100*(schedule_data["students_in_group"]-schedule_data["attended_students"])/schedule_data["students_in_group"])
    }
    this.setState({schedule_data: schedule_data})
  }

	handlePaperTabChange = (event, newValue) => {
    	this.setState({paperTab: newValue});
  };

  goBack = (e) => {
    e.preventDefault();
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    this.props.history.push('/analysis/test?paper_id='+paper_id);
  }


  onSelectBranch = (event,option) => {
    this.setState({selectedBranch: option.branch_id, selectedBranchName: option.name})
    this.fetchMarksReport(option.branch_id,this.state.selectedSection)
  }

  onSelectSection = (event,option) => {
    this.setState({selectedSection: option.section_id,selectedSectionName: option.name})
    this.fetchMarksReport(this.state.selectedBranch,option.section_id)
  }

  getReportName(name){
    return (name ? "_" + name : "")
  }

  render () {
  	const {classes} = this.props;  
    const {schedule_data,columns,rows,allBranches,allSections, avgColumns,avgRows,selectedBranchName,selectedSectionName} = this.state;  
  	return(
  		<React.Fragment>
        <Link className={classes.backLink}
          component="button"
          variant="body2" 
          onClick={this.goBack}>
          Back to Test Reports
        </Link>
        { schedule_data &&
  			 <ScheduleTestCard data={schedule_data}/>
  		  }
        <div className={classes.filters}>
          <div className={classes.filterSection}>
            <ComboBox options={allBranches} onChange={this.onSelectBranch} label="Select Branch"/>
          </div>
          <div className={classes.filterSection}>
            <ComboBox options={allSections} onChange={this.onSelectSection} label="Select Section"/>
          </div>
        </div>
        {/*<div className={classes.section}>
	  			<Tabs value={this.state.paperTab} onChange={this.handlePaperTabChange}>
		          <Tab label="Total" {...a11yProps(0)} />
		          <Tab label="Paper-1" {...a11yProps(1)} />
		          <Tab label="Paper-2" {...a11yProps(2)} />
		      </Tabs>
	      </div>*/}
	        {/*<div>{ this.state.data && this.state.data["user_results"] &&
	        	  <ExpandableTable data={this.state.data["user_results"]}/>
            }
	        </div>*/}
          <div className={classes.section}>
            <MuiTable columns={columns} rows={rows} title={"Marks List"} downloadTitle={"Marks List" + this.getReportName(schedule_data["name"]) + this.getReportName(selectedBranchName) + this.getReportName(selectedSectionName) }/>
          </div>
          <div className={classes.section}>
            <MuiTable columns={avgColumns} rows={avgRows} title={"Average Summary"} downloadTitle={"Average Summary" + this.getReportName(schedule_data["name"]) + this.getReportName(selectedBranchName) + this.getReportName(selectedSectionName) }/>
          </div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystTestMarks);