import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import withStyles from "@material-ui/core/styles/withStyles";
import navigationBarStyle from "./styles/NavigationBarStyle.js";


import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Button from "CustomButtons/Button.jsx";

//import { Route, Switch } from "react-router";


import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Home from "@material-ui/icons/Home";
import Menu from "@material-ui/icons/Menu";
import School from "@material-ui/icons/School";

import brand_white from "assets/img/images/brand_white.png";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import AccountCircle from "@material-ui/icons/AccountCircle";


class AdminNavigationBar extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      drawerOpen: true,
	      activePage: ""
	    };
	}

	componentDidMount(){
		const currentUrl = this.props.location.pathname;
		if(currentUrl == "/manage-users" || currentUrl == "/create-new-user"){
			this.setState({activePage: "manageUsers"});
		}else if(currentUrl == "/manage-user-roles"){
			this.setState({activePage: "manageUserRoles"});
		}else if(currentUrl == "/manage-user-groups"|| currentUrl == "/user-group-mappings"){
			this.setState({activePage: "manageUserGroups"});
		}else if(currentUrl == "/manage-branches"){
			this.setState({activePage: "manageBranches"});
		}else if(currentUrl == "/manage-sections"){
			this.setState({activePage: "manageSections"});
		}else if(currentUrl == "/manage-programs"){
			this.setState({activePage: "managePrograms"});
		}else if(currentUrl == "/manage-test-types"){
			this.setState({activePage: "manageTestTypes"});
		}else if(currentUrl == "/manage-subjects"){
			this.setState({activePage: "manageSubjects"});
		}else if(currentUrl == "/manage-chapters"){
			this.setState({activePage: "manageChapters"});
		}

	}

	goToManageUsers = (e) => {
	  		this.props.history.push('/manage-users');
	  	}
		
  	goToManageUserGroups = (e) => {
  		this.props.history.push('/manage-user-groups');
  	}

  	goToManageBranches = (e) => {
  		this.props.history.push('/manage-branches');
  	}

  	goToManageSections= (e) => {
  		this.props.history.push('/manage-sections');
  	}

  	goToManagePrograms = (e) => {
  		this.props.history.push('/manage-programs');
  	}

  	goToManageTestTypes = (e) => {
  		this.props.history.push('/manage-test-types');
  	}

  	goToManageSubjects = (e) => {
  		this.props.history.push('/manage-subjects');
  	}

  	goToManageChapters = (e) => {
  		this.props.history.push('/manage-chapters');
  	}


	render() {
		 const { classes} = this.props;
		 return (
			  	<Drawer open={this.state.drawerOpen}
		 	         variant="permanent"
		 	        anchor="left"
		 	        classes={{
		 	          paper: classes.drawerPaper
		 	        }}
		 	      	>
		 	        <div className={classes.toolbar} style={{paddingTop: "20px", paddingBottom: "20px",  textAlign: "center"}}> 
		 	        <GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
          				<GridItem xs={12} sm={12} md={12}> 
		 	        		<AccountCircle style={{fontSize: "96px", width: "100%"}} />
		 	        		<p style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={classes.robotoFontRegular}>ROOT APP ADMIN</p>
		 	        	</GridItem> 
		 	         </GridContainer>	
		 	        </div>
		 	        	<React.Fragment>  
			 	        <List className={classes.list}>
			 	        	<Button
						          color="transparent"
						          onClick={this.goToManageUsers}
						          className={this.state.activePage == "manageUsers"? classes.navLinkActive : classes.navLink}
						        >
						        <Menu/> Manage Users
						    </Button>
					    </List>		 	
						
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageUserGroups}
					          className={  	
					          	this.state.activePage == "manageUserGroups"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage User Groups
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageBranches}
					          className={  	
					          	this.state.activePage == "manageBranches"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Branches
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageSections}
					          className={  	
					          	this.state.activePage == "manageSections"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Sections
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManagePrograms}
					          className={  	
					          	this.state.activePage == "managePrograms"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Programs
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageTestTypes}
					          className={  	
					          	this.state.activePage == "manageTestTypes"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Test Types
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageSubjects}
					          className={  	
					          	this.state.activePage == "manageSubjects"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Subjects
					        </Button>				        
						</List>
						
						</React.Fragment> 
 	      		</Drawer>
 	      		);
		
	}
}

export default withWidth()(withStyles(navigationBarStyle)(AdminNavigationBar));


