import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";

class RepoFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      open: false,
      loading: false,
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
  };

  componentDidMount() {}

  getFilterData = () => {
    let filterName = this.props.filterName;
    let url = "/repo/get-" + filterName + ".json";
    let parentName = this.props.parentName;
    let parentValue = this.props.parentValue;
    if (parentName && parentValue) {
      url = url + "?" + parentName + "=" + parentValue.replace("&", "%26");
    }
    $.ajax({
      type: "GET",
      url: getBaseURL() + url,
    })
      .done((response, status, jqXHR) => {
        let filterData = response;
        filterData.unshift("");
        this.setState({
          filterData: filterData,
          loading: false,
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.setState({
          filterData: [],
          loading: false,
        });
        alert(
          jqXHR?.responseJSON?.message
            ? jqXHR?.responseJSON?.message
            : "Something went wrong. Please try again or contact administrator!"
        );
        return;
      });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.parentName != prevProps.parentName ||
      this.props.parentValue != prevProps.parentValue
    ) {
      this.getFilterData();
    }
    if (this.props.refreshParentValue1 != prevProps.refreshParentValue1) {
      this.setState({ filterData: [] });
    }
  }

  handleOpen = () => {
    let filterData = this.state.filterData;
    if (filterData?.length < 1) {
      this.setState({ loading: true });
      this.getFilterData();
    }
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      name,
      value,
      index,
      change,
      formControlWidth,
      parentName,
      parentValue,
    } = this.props;
    return (
      <FormControl
        style={formControlWidth ? { width: formControlWidth } : null}
      >
        <Select
          open={this.state.open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          value={value}
          name={name}
          onChange={change}
          style={{ fontSize: "inherit" }}
        >
          {!this.state.loading ? (
            this.state?.filterData?.map((filterValue, index) => (
              <MenuItem
                key={index}
                style={{ fontSize: "14px" }}
                value={filterValue}
              >
                {filterValue ? filterValue : "None"}
              </MenuItem>
            ))
          ) : (
            <MenuItem style={{ fontSize: "14px" }} value={""}>
              {"Loading..."}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

export default RepoFilters;
