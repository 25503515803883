import React from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class ReportListItem extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
        	expandJob: false
        }
  	}

  	expandRole = () => {
  		this.setState({expandJob: !this.state.expandJob})
  	}

  	applyJob(link){
  		var win = window.open(link, '_blank');
  		win.focus();
  	}

  	render() {
  	const {role, onView,isDisabled} = this.props;
    return (
    	<React.Fragment>
  			<Card variant="outlined" style={{width: "100%"}}>
	  			<CardContent style={{paddingBottom: "16px"}}>
		  			<div style={{padding: "20px"}}>
			          	<Typography style={{marginTop: "-10px"}} 
			          	variant="h6" gutterBottom>
					       {role.title}
					    </Typography>
					    <Typography
			          	variant="body2" gutterBottom>
					       {role.subtitle}
					    </Typography>
					    <div style={{paddingTop: "1em", width: "fit-content"}}>  
					    	<Button
					    	disabled={isDisabled} 
					    	onClick={onView}
					    	variant="contained" color="primary" style={isDisabled? {borderRadius: "20px"}:{backgroundColor: "green", borderRadius: "20px"}}>
								{isDisabled ? "Select Input" :"View Report"}
					    	</Button>
					    </div>
					</div>    
			    </CardContent>
		    </Card>
    	</React.Fragment>
    )}
}

export default ReportListItem;
