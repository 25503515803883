import React, {Component} from "react";

class AnalystHome extends Component {

  render () {
  	return(
  		<React.Fragment>
  			<p>Overall Home</p>
  		</React.Fragment>
  	);}
}

export default AnalystHome;