import React, {Component} from "react";
import ErrorBoundary from "../ErrorBoundary.js";
import AnalystAppBar from "./AnalystAppBar.jsx"

class AnalystAppLayout extends Component {

  render () {
    const{yieldComponent} = this.props;
    return (
      <React.Fragment>
        <ErrorBoundary>
        <AnalystAppBar {...this.props} yieldComponent={yieldComponent}/>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default AnalystAppLayout;