import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';

class TestTypes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      testTypes: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    //index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){

    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/get-test-types.json'
    }).done((response, status, jqXHR)=>{
        var testTypes = response["test_types"];
        this.setState({
          testTypes: testTypes
        });

    });
  }

  getIdForLOV(value){
    const testTypes = this.state.testTypes;
    const filteredTestTypes = testTypes.filter(testType => testType.short_name == value);
    if (filteredTestTypes && filteredTestTypes.length > 0){
     return {id: filteredTestTypes[0].id, 
      subject_test_flag: filteredTestTypes[0].subject_test_flag == "1" ? "true" : "false" };
    }
    return;
  }

  getValueFromId(id){
    const testTypes = this.state.testTypes;
    const filteredTestTypes = testTypes.filter(testType => testType.id == id);
    if (filteredTestTypes && filteredTestTypes.length > 0){
     return filteredTestTypes[0].short_name
    }
    return;
  }

  render() {
    const { name, value,valueId, change, formControlWidth } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value ? value : valueId ? this.getValueFromId(valueId) :null} name={name} onChange={(e) => change(e,this.getIdForLOV(e.target.value))} 
          style={{fontSize: 'inherit'}} data-cy="select test type">
          { this.state.testTypes.map((testType, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={testType.short_name} data-cy="select one test type">{testType.short_name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default TestTypes;