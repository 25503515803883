import React, {Component} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import MUIDataTable from "mui-datatables";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Hidden from '@material-ui/core/Hidden';
import Slide from "@material-ui/core/Slide";

import UserGroupModal from './UserGroupModal';
import TestModelFormStyle from "../styles/TestModelFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";

const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...TestModelFormStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  inputLabel:{
  	width: '96%' 
  }
};

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class ManageSections extends Component{
	constructor(props){
		super(props);
		this.state={
			sectionsTableData:[],
			sectionsTableDataMob:[],
			newSectionInfo:{},
			branches:[],
			isOpen: false,
			successMessage:"",
			errorMessage:"",
			alertOpen:false
		}
	}

	handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successMessage:""});
    this.setState({errorMessage:""})
    this.setState({alertOpen: false});
  };


	handleOnChangeAddSectionInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var newSectionInfo = this.state.newSectionInfo;
 	newSectionInfo[attr] = e.target.value;
}

	handleToggleModal = (e) => {
		var open = this.state.isOpen;
		this.setState({newSectionInfo:{}});
		this.setState({isOpen: !open});
	}

	handleAddNewSection = () => {
		var {name,short,branchId,description}= this.state.newSectionInfo;

		var postData= {global_section:{name, short_name: short,global_branch_id:branchId, description}};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL()+'/global_sections',
	    data:postData
    }).done((data)=>{
    	var message="New Section Added Successfully";
    	this.setState({successMessage:message});
    	this.setState({alertOpen: true})
    	this.handleToggleModal();
			this.fetchSections();
    }).fail((error)=>{
	    var errorMessage = "Something went wrong!";
	    this.setState({errorMessage});
    	this.setState({alertOpen: true});
	    })
	}

	handleDelete = (id) => {
		$.ajax({
	      type: 'DELETE',
	      url: getBaseURL()+'/global_sections/'+id.toString()+'.json',
	     	headers: {
	      	'X-CSRF-Token': getCSRFToken()
	      }
	    }).done((response) => {
	    	var message="Section Deleted Successfully";
	    	this.setState({successMessage:message});
	    	this.setState({alertOpen: true})
	    	this.fetchSections();
	    }).fail((error)=>{
	    	const {responseJSON}=error;
	    	var errorMessage = responseJSON.message;
		    this.setState({errorMessage});
	    	this.setState({alertOpen: true})
	    })

	}


	fetchSections =() => {
		$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/sections.json',
    }).done((data)=>{
    	this.populateSectionsFromResults(data);
    })
	}

	fetchBranches =() => {
		$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/branches.json',
    }).done((data)=>{
    	var branches=data["global_branches"]
    	this.setState({branches})
    })
	}


	Transition =(props) => {
  return (<Slide direction="down" {...props} />);
	}


	populateSectionsFromResults(data){
		var globalSections = data["global_sections"];

		var branches=this.state.branches;
		var sectionsTableData = [];
		var sectionsTableDataMob = [];
	
	if(globalSections && globalSections.length > 0){
		for(var i in globalSections){
			var branch= this.state.branches.find(e => e.id === globalSections[i].global_branch_id);
			console.log(globalSections[i].name);
			sectionsTableData.push([
				globalSections[i].id,
				globalSections[i].name,
				globalSections[i].short_name,
				branch.name,
				globalSections[i].description,
			]);

			sectionsTableDataMob.push([
				globalSections[i].id,
				globalSections[i].name,
				branch.name
			]);
		}
		this.setState({sectionsTableData});
		this.setState({sectionsTableDataMob});
	}else{
		this.setState({sectionsTableData: []});
	}
}

	componentDidMount(){
		this.fetchBranches();
		this.fetchSections();
			
	}

	render(){

		const {classes}= this.props;

		const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
		};
		const subtitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			paddingBottom: "10px",
		};
		const tableTitleStyle={
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px",
		};
		const sectionsTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: true,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const sectionsTableColumns = [
			{name: "sectionId",
	        options: {
	          display: false,
	        }
	    },
			{name: "Name"},
			{name: "Short Name"},
			{name: "Branch"},
			{name: "Description"},
			{	
					name: " ",
					options: {
	              customBodyRender: (value, tableMeta, updateValue) => {
	              	const {rowData}=tableMeta;
	              	const id=rowData[0];
	              return (
	 
	                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
	            
	              );
	              }
	          }
			}     
		];

		const sectionsTableMobOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const sectionsTableMobColumns = [
			{name: "sectionId",
	        options: {
	          display: false,
	        }
	    },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (<div>{value}</div>);
              }
          }
			},
			{name: "Branch"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
	              const id=rowData[0];
              return (
 
                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
            
              );
              }
          }
		}     
		];
		const FormComponent = (props) => {
			
			const {newSectionInfo, handleOnChangeAddSectionInfo}=props;
				return(
					<React.Fragment>
		      	<GridContainer style={{
		      	backgroundColor: "white",
						paddingTop: "0px",
		      	width: "100%", 
		      	margin: "10px 0px 0px",
		      	marginTop: "0px"}}
		      	spacing={24} >	
							<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
				  			<form className={classes.form}>
				  				<GridItem xs={12} style={{padding: "0px"}}>
				  					<TextField variant="outlined" label="Section Name"  placeholder="Enter Section Name" 
				  					required style={{width: "48%"}}
				  					value={newSectionInfo["name"]}
				  					name="newSectionInfo_name"
				  					onChange={handleOnChangeAddSectionInfo}
				  					/>
				  					<TextField variant="outlined" label="Short Name"  placeholder="Enter Short Name" 
				  					required style={{width: "48%", paddingLeft: "8px"}}
				  					value={newSectionInfo["short"]}
				  					name="newSectionInfo_short"
				  					onChange={handleOnChangeAddSectionInfo}/>
				  				</GridItem>
	              	<TextField variant="outlined" label="Branch" select fullWidth required className={classes.inputLabel}
	              		value={newSectionInfo["branchId"]}
				  					name="newSectionInfo_branchId"
				  					onChange={handleOnChangeAddSectionInfo}>
									  {
									  	this.state.branches.map((branch) => {
									  		return(<MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>);
									  	})
									  }
									</TextField>
	              	<TextField variant="outlined" label="Section Description" fullWidth placeholder="Enter Section Description" required
	              	multiline className={classes.inputLabel}
	              	value={newSectionInfo["description"]}
				  				name="newSectionInfo_description"
				  				onChange={handleOnChangeAddSectionInfo} 
	              	/>
	              </form>
		          </GridItem>
	          </GridContainer>
        </React.Fragment>
				);		
    };
		return(
			<React.Fragment>
		  		<GridContainer style={{
		      		backgroundColor: "white",
					paddingTop: "0px",
		      		paddingBottom: "20px",
		      		width: "100%", 
		      		margin: "10px 0px 0px",
		      		marginTop: "0px"}}
		      		spacing={24}>
		      		{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage Sections</h1>
		      			<hr/>
		      		</GridItem>

		      		<GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			      			<UserGroupModal formComponent={<FormComponent newSectionInfo={this.state.newSectionInfo} 
			      			handleOnChangeAddSectionInfo={this.handleOnChangeAddSectionInfo}/>} 
			      			titleModal={"Add New Section"} buttonTitle={"Add Section"}
			      			handleClick={this.handleAddNewSection} open={this.state.isOpen}
			      			handleToggleModal={this.handleToggleModal}/>		
			      	</GridItem>	
			      	<Hidden smDown>	      		
			      		<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Sections</p>}
			      			  	options={sectionsTableOptions}
			      			  	columns={sectionsTableColumns}  
			      			  	data={this.state.sectionsTableData}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
	      			<Hidden mdUp>
	      				<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Sections</p>}
			      			  	options={sectionsTableMobOptions}
			      			  	columns={sectionsTableMobColumns}  
			      			  	data={this.state.sectionsTableDataMob}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
		      	</GridContainer>
		    </React.Fragment>
		);
	}
}

export default withStyles(styles)(ManageSections);

