import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

const styles = theme => ({
  typography: {
      useNextVariants: true,
  },
  root: {
    width: '90%',
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  }
});

class HorizontalStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  componentDidMount(){
    if( this.props.activeStepProp && this.props.activeStepProp != 0 ){
      this.setState({activeStep: this.props.activeStepProp})
    }
  }


  componentDidUpdate(prevProps){
    if( prevProps.activeStepProp && this.props.activeStepProp != prevProps.activeStepProp ){
      this.setState({activeStep: this.props.activeStepProp})
    }
  }

  handleNext = () => {
    const newActiveStep = this.state.activeStep + 1;
    var validationsPassed = this.props.validateSteps(newActiveStep);
    if(validationsPassed){
      this.setState({
        activeStep: newActiveStep,
      });
      this.props.activeStepChanged(newActiveStep);
    }
  };

  handleBack = () => {
    const newActiveStep = this.state.activeStep - 1;
    var validationsPassed = this.props.validateSteps(newActiveStep);
    if(validationsPassed){
      this.setState({
        activeStep: newActiveStep,
      });
      this.props.activeStepChanged(newActiveStep);
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
    this.props.activeStepChanged(0);
  };

  getStepContent(stepIndex) {
    var stepContent = this.props.stepContent;
    if (stepContent && stepContent[stepIndex]){
      return stepContent[stepIndex];
    }
  }

  render() {
    const { classes, steps,stepContent, activeStepProp, activeStepChanged,showFinishButton, validateSteps} = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
      <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px"}}
          spacing={24} >
        <GridItem xs={12} sm={12} md={3} style={{padding: '12px'}}>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{padding: '12px'}}>
        <Stepper style={{padding: "0px"}}  activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>      
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{padding: '12px'}}>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                { activeStep === steps.length - 1 && showFinishButton ?
                <Button variant="contained" color="primary" onClick={this.handleNext}>
                  Finish
                </Button>
                : null }
                { activeStep != steps.length - 1 ?
                <Button variant="contained" color="primary" onClick={this.handleNext} data-cy="create test next btn">
                  Next
                </Button>
                : null }
              </div>
            </div>
          )}
        </div>
        </GridItem>
        </GridContainer>
      </div>
    );
  }
}

HorizontalStepper.propTypes = {
  classes: PropTypes.object,
};

HorizontalStepper.defaultProps = {
  showFinishButton: false,
};

export default withStyles(styles)(HorizontalStepper);