import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';

class QuestionPatterns extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      questionPatterns: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){

    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/get-question-patterns'
    }).done((response, status, jqXHR)=>{
        var questionPatterns = JSON.parse(response)["questionPatterns"];

        this.setState({
          questionPatterns: questionPatterns
        });

    });
  }

  render() {
    const { name, value, index, change } = this.props;
    return (
      <FormControl>
        <Select value={value} name={name} onChange={change} 
          style={{fontSize: 'inherit'}} data-cy="select pattern">
          { this.state.questionPatterns.map((questionPattern, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={questionPattern.short_name} data-cy="select one pattern">{questionPattern.short_name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default QuestionPatterns;