import React, {Component} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';

import Button from "../../exports/components/CustomButtons/Button.jsx";

import MUIDataTable from "mui-datatables";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

import Hidden from '@material-ui/core/Hidden';
import Slide from "@material-ui/core/Slide";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import UserGroupModal from './UserGroupModal';
import TestModelFormStyle from "../styles/TestModelFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";

const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...TestModelFormStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  inputLabel:{
  	width: '96%' 
  }
};

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class ManagePrograms extends Component{
	constructor(props){
		super(props);
		this.state={
			programsTableData:[],
			programsTableDataMob:[],
			newProgramInfo:{},
			isOpen: false,
			successMessage:"",
			errorMessage:"",
			alertOpen:false
		}
	}

	handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successMessage:""});
    this.setState({errorMessage:""})
    this.setState({alertOpen: false});
  };

	handleOnChangeAddProgramInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var newProgramInfo = this.state.newProgramInfo;
 	newProgramInfo[attr] = e.target.value;
}

	handleAddNewProgram = (e) => {
		var {name,short,description}= this.state.newProgramInfo;

		var postData= {global_program:{name, short_name: short, description}};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL()+'/global_programs',
	    data:postData
    }).done((data)=>{
    	var message="New Program Added Successfully";
    	this.setState({successMessage:message});
    	this.setState({alertOpen: true})
    	this.handleToggleModal();
			this.fetchPrograms();
    }).fail((error)=>{
	    var errorMessage = "Something went wrong!";
	    this.setState({errorMessage});
    	this.setState({alertOpen: true});
	    })

	}

	handleToggleModal = (e) => {
		var open = this.state.isOpen;
		this.setState({newProgramInfo:{}});
		this.setState({isOpen: !open});
	}

	handleDelete = (id) => {
		$.ajax({
	      type: 'DELETE',
	      url: getBaseURL()+'/global_programs/'+id.toString()+'.json',
	     	headers: {
	      	'X-CSRF-Token': getCSRFToken()
	      }
	    }).done((response) => {
	    	var message="Program Deleted Successfully";
	    	this.setState({successMessage:message});
	    	this.setState({alertOpen: true})
	    	this.fetchPrograms();
	    }).fail((error)=>{
	    	const {responseJSON}=error;
	    	var errorMessage = responseJSON.message;
		    this.setState({errorMessage});
	    	this.setState({alertOpen: true})
	    })
	}

	fetchPrograms = () => {
		$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/global_programs.json',
    }).done((data)=>{
    	this.populateProgramsFromResults(data);
    })
	}

	Transition =(props) => {
  return (<Slide direction="down" {...props} />);
	}

	componentDidMount(){
		this.fetchPrograms();
	}

	populateProgramsFromResults(data){
	var globalPrograms = data["programs"];
	
	var programsTableData = [];
	var programsTableDataMob = [];
	if(globalPrograms && globalPrograms.length > 0){
		for(var i in globalPrograms){
			programsTableData.push([
				globalPrograms[i].id,
				globalPrograms[i].name,
				globalPrograms[i].short_name,
				globalPrograms[i].description
			]);

			programsTableDataMob.push([
				globalPrograms[i].id,
				globalPrograms[i].name,
				globalPrograms[i].short_name
			]);
		}
		this.setState({programsTableData});
		this.setState({programsTableDataMob});
	}else{
		this.setState({programsTableData: []});
	}
}

	render(){

		const {classes}= this.props;

		const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
		};
		const subtitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			paddingBottom: "10px",
		};
		const tableTitleStyle={
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px",
		};
		const programsTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: true,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const programsTableColumns = [
			{name: "programId",
	        options: {
	          display: false,
	        }
	    },
			{name: "Name"},
			{name: "Short Name"},
			{name: "Description"},
			{	
					name: " ",
					options: {
	              customBodyRender: (value, tableMeta, updateValue) => {
	              	const {rowData}= tableMeta;
	              	const id= rowData[0];
	              	return (<IconButton onClick={(e) => {this.handleDelete(id)}}><DeleteIcon /></IconButton>);
	              }
	          }
			}     
		];

		const programsTableMobOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const programsTableMobColumns = [
			{name: "programId",
	        options: {
	          display: false,
	        }
	    },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (<div>{value}</div>);
              }
          }
			},
			{name: "Short"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (
 
                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
            
              );
              }
          }
		}     
		];
		const FormComponent =  (props) => {

			const {newProgramInfo, handleOnChangeAddProgramInfo}=props;
				return(
					<React.Fragment>
		      	<GridContainer style={{
		      	backgroundColor: "white",
						paddingTop: "0px",
		      	width: "100%", 
		      	margin: "10px 0px 0px",
		      	marginTop: "0px"}}
		      	spacing={24} >	
							<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
				  			<form className={classes.form}>
		      				<TextField variant="outlined" label="Program Name" fullWidth placeholder="Enter Program Name" 
		      				required className={classes.inputLabel}
		      				value={newProgramInfo["name"]}
	      					name="newProgramInfo_name"
	      					onChange={handleOnChangeAddProgramInfo}/>

	              	<TextField variant="outlined" label="Program Short Name" fullWidth placeholder="Enter Program Short Name" 
	              	required className={classes.inputLabel}
	              	value={newProgramInfo["short"]}
	      					name="newProgramInfo_short"
	      					onChange={handleOnChangeAddProgramInfo}/>

	              	<TextField variant="outlined" label="Program Description" fullWidth placeholder="Enter Program Description" required
	              	multiline className={classes.inputLabel} 
	              	value={newProgramInfo["description"]}
	      					name="newProgramInfo_description"
	      					onChange={handleOnChangeAddProgramInfo}/>
	              </form>
		          </GridItem>
	          </GridContainer>
        </React.Fragment>		
				);
    };
		return(
			<React.Fragment>
		  		<GridContainer style={{
		      		backgroundColor: "white",
					paddingTop: "0px",
		      		paddingBottom: "20px",
		      		width: "100%", 
		      		margin: "10px 0px 0px",
		      		marginTop: "0px"}}
		      		spacing={24}>
		      		{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage Programs</h1>
		      			<hr/>
		      		</GridItem>
		      		
		      		<GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			      			<UserGroupModal formComponent={<FormComponent newProgramInfo={this.state.newProgramInfo}
			      			 handleOnChangeAddProgramInfo={this.handleOnChangeAddProgramInfo} />}
			      			 titleModal={"Add New Program"} buttonTitle={"Add Program"} handleClick={this.handleAddNewProgram}
			      			 open={this.state.isOpen} handleToggleModal={this.handleToggleModal}
			      			/>		
			      	</GridItem>	
			      	<Hidden smDown>	      		
			      		<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Programs</p>}
			      			  	options={programsTableOptions}
			      			  	columns={programsTableColumns}  
			      			  	data={this.state.programsTableData}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
	      			<Hidden mdUp>
	      				<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Programs</p>}
			      			  	options={programsTableMobOptions}
			      			  	columns={programsTableMobColumns}  
			      			  	data={this.state.programsTableDataMob}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
		      	</GridContainer>
		    </React.Fragment>
		);
	}
}

export default withStyles(styles)(ManagePrograms);
