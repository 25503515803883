import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import modalStyle from "../../exports/assets/jss/material-kit-react/modalStyle.jsx";
import Close from "@material-ui/icons/Close";
import Button from "../../exports/components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const getMuiThemeForModal =  createMuiTheme({
    overrides: {
    	MuiDialog:{
    		paper:{
    			width: '400px',
    			height: '390px'
    		}
    	},
      MuiModal:{
      	root:{
      		zIndex: "90000"
      	}
    },
	    typography: {
	    	useNextVariants: true,
	  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  });

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...modalStyle,
  modalBodyMob: {
    paddingTop: "10px",
    paddingRight: "12px",
    paddingBottom: "16px",
    paddingLeft: "12px",
    position: "relative",
    [theme.breakpoints.down("md")]:{
    	padding: "3px",
    	paddingBottom: "0px"   
    }
  },
});

function Transition(props) {
  return (<Slide direction="down" {...props} />);
};

class UserGroupModal extends React.Component{

	render() {
		const{classes, formComponent, titleModal, buttonTitle, handleClick,open,handleToggleModal} = this.props;
		return(
			<React.Fragment>
				<Button onClick={handleToggleModal}
				style={{textTransform: "none", backgroundColor: "#3f51b5",padding: "8px 20px",marginRight: "20px"}}>
				{buttonTitle}</Button>
				<div className={classes.root}>
				   	<MuiThemeProvider theme={getMuiThemeForModal}>
				    <Dialog
			          classes={{	
			            root: classes.center,
			            paper: classes.modal
			          }}
			          open={open}
			          TransitionComponent={Transition}
			          keepMounted
			          onClose={handleToggleModal}
			          aria-labelledby="modal-slide-title"
			          aria-describedby="modal-slide-description">
			          <DialogTitle
			            id="classic-modal-slide-title"
			            disableTypography
			            className={classes.modalHeader}>
			            <IconButton
			              className={classes.modalCloseButton}
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={handleToggleModal}>
			              <Close className={classes.modalClose} />
			            </IconButton>
			            <h4 className={classes.modalTitle}>{titleModal}</h4>
			          </DialogTitle>
			          <DialogContent style={{paddingBottom: "0px", overflowY: "visible"}}
			            id="modal-slide-description"
			            className={classes.modalBodyMob}>
			            {formComponent}
			          </DialogContent>
			          <DialogActions
			          style={{padding: "14px", paddingRight: "30px"}}
			            className={classes.modalFooter + " " + classes.modalFooterRight }>
			            <Button    
			              size="sm"
			              onClick={handleClick}
			              type="submit"
			              className={classes.searchButton}>
			              {buttonTitle}
			            </Button>
			            <Button    
			              size="sm"
			              onClick={handleToggleModal}
			              className={classes.cancelButton}>
			              Cancel
			            </Button>
			          </DialogActions>
			        </Dialog>
			        </MuiThemeProvider>
			    </div>
			</React.Fragment>
			
		);
	}
}

export default  withStyles(styles, {withTheme: true})(UserGroupModal);