import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Grid from "@material-ui/core/Grid";

/*const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
*/


export default class QuestionHeader extends React.Component {

render() {
	const {  number, pattern, subject,positiveMarks,negativeMarks,partialMarks } = this.props;
	const style1={
	padding: "0px 20px",
	};
	const style3={
		padding: "0px 12px"
	};

	const style2={
	fontWeight: "bold",
	fontFamily: "serif"
	};
	return(
		<div>
		{/*<div style={{width:"100%"}}/>*/}
		<GridContainer style={{margin: "auto"}}>
		<div style={{padding: "10px 10px", width: "100%" }}/>
			<GridItem  xs={12} sm={12} md={12} lg={12} style={{padding: "0px"}}>
				<Grid container  justify="space-between" style={{margin: "-20px"}}>
					<Grid item style={{padding:"0px 20px", paddingLeft: "40px"}}>
						<h5 style={style2}>Question No: {number}</h5>
					</Grid>
					<Grid item style={style1}>
						<h5 style={style2}>{subject}</h5>
					</Grid> 
					<Grid item style={{padding:"0px", paddingLeft: "40px"}}>
						<h5 style={style2}>{pattern}</h5>
					</Grid>
				</Grid>
				<Grid container spacing={24} justify="space-between" style={{margin: "-12px"}}>
					<Grid item style={{padding: "0px 12px", paddingLeft: "32px"}}>
						<h5 style={style2}>Marking Scheme:</h5>
					</Grid>
					<Grid item style={style3}>
						<h5 style={style2}>Positive: {positiveMarks > 0 ? (+positiveMarks) : "0"} Marks</h5>
					</Grid> 
					<Grid item style={style3}>
						<h5 style={style2}>Negative: {negativeMarks > 0 ? (-negativeMarks) : "0"} Marks</h5>
					</Grid>
					<Grid item style={style3}>
						<h5 style={style2}>Partial: {partialMarks>0 ? partialMarks : "NA"}</h5>
					</Grid> 
					{/*<Grid item xs={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
						<p>This question has single digit integer answer.</p> 
					</Grid> */}
				</Grid>
			</GridItem> 
		</GridContainer>
		</div>
		);
}

}