/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//console.log('Hello World from Webpacker')
// Support component names relative to this directory:
//console.log('Webpacker');

//import WebpackerReact from 'webpacker-react';
//import Turbolinks from 'turbolinks';

// import LoginPage from 'components/LoginPage';
//import AppBar from 'components/AppBar';
// import TestAnalysisDashboard from 'components/analysis/TestAnalysisDashboard';

// import TestModelForm from 'components/TestModelForm';
// //import NavigationBar from 'components/NavigationBar';
// import TestForm from  'components/test/TestForm'
// import ScheduleTestForm from  'components/schedule/ScheduleTestForm'
// import ManageSchedules from  'components/schedule/ManageSchedules'
// import ManageTests from  'components/test/ManageTests'

//Turbolinks.start()

//WebpackerReact.setup({LoginPage,AppBar,TestModelForm,TestForm,ScheduleTestForm,ManageSchedules,ManageTests})
//WebpackerReact.setup({AppBar})

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from 'components/config/routes';
import "core-js/stable";
import 'regenerator-runtime/runtime'


document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Routes />, document.getElementById("root"),
  )
});