import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import ErrorLogo from "@material-ui/icons/Error";
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import Header from "Header/Header.jsx";
import HeaderLinks from "Header/HeaderLinks.jsx";
import Footer from "Footer/Footer.jsx";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";
import CardHeader from "Card/CardHeader.jsx";
import CardFooter from "Card/CardFooter.jsx";
import CustomInput from "CustomInput/CustomInput.jsx";

import loginPageStyle from "styles/LoginPageStyle.js";

import brand_white from "assets/img/images/brand_white.png";
import CheckCircle from "@material-ui/icons/CheckCircle";

import $ from "jquery";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: "",
      password: "",
      email: "",
      errorMessage: "",
      successMessage: "",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({
          cardAnimaton: "",
          username: this.state.username,
          password: this.state.password,
        });
      }.bind(this),
      700
    );
  }

  handleEnterKeyPress = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      this.handleResetPassword(e);
    }
  };

  handleInput = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var obj = {};
    obj[id] = e.target.value;
    this.setState(obj);
  };

  handleResetPassword = (e) => {
    e.preventDefault();
    this.handleResetPasswordRequest();
  };

  handleResetPasswordRequest() {
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/reset-password",
      data: {
        username: this.state.username,
        password: this.state.password,
        email: this.state.email,
      },
    })
      .done((response, status, jqXHR) => {
        this.setState({
          successMessage: "Password Updated. Please login Again.",
          errorMessage: "",
          username: "",
          password: "",
          email: "",
        });
        if (sessionStorage.user) {
          sessionStorage.removeItem("user");
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        if (jqXHR["responseJSON"]["message"]) {
          this.setState({
            errorMessage: jqXHR["responseJSON"]["message"],
            successMessage: "",
          });
        } else {
          this.setState({
            errorMessage: "An unexpected error occurred. Please try later.",
            successMessage: "",
          });
        }
      });
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage:
              "linear-gradient(rgb(255, 255, 255), rgb(32, 54, 66))", //"url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader className={classes.cardHeader}>
                      <img
                        name="home"
                        src={brand_white}
                        alt="..."
                        style={{ height: "50px" }}
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.robotoFontRegular}>
                        Reset Password
                      </h4>
                      {this.state.errorMessage ? (
                        <div style={{ display: "flex" }}>
                          <ErrorLogo
                            className={classes.icon}
                            style={{
                              verticalAlign: "middle",
                              marginRight: "5px",
                              color: "#f44336",
                            }}
                          ></ErrorLogo>
                          <p
                            style={{
                              display: "inline-block",
                              color: "#f44336",
                              fontSize: "0.8rem",
                              overflow: "inherit",
                              whiteSpace: "normal",
                            }}
                            className={classes.robotoFontRegular}
                          >
                            {this.state.errorMessage}
                          </p>
                        </div>
                      ) : null}
                      {this.state.successMessage ? (
                        <div style={{ display: "flex" }}>
                          <CheckCircle
                            className={classes.icon}
                            style={{
                              verticalAlign: "middle",
                              marginRight: "5px",
                              color: "#4CAF50",
                            }}
                          ></CheckCircle>
                          <p
                            style={{
                              display: "inline-block",
                              color: "#4CAF50",
                              fontSize: "0.8rem",
                              overflow: "inherit",
                              whiteSpace: "normal",
                            }}
                            className={classes.robotoFontRegular}
                          >
                            {this.state.successMessage}
                          </p>
                        </div>
                      ) : (
                        <React.Fragment>
                          <CustomInput
                            labelText="Username"
                            id="username"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              ),
                              value: this.state.username,
                              onChange: this.handleInput,
                              onKeyPress: this.handleEnterKeyPress,
                            }}
                          />
                          <CustomInput
                            labelText="Registered Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              ),
                              value: this.state.email,
                              onChange: this.handleInput,
                              onKeyPress: this.handleEnterKeyPress,
                            }}
                          />
                          <CustomInput
                            labelText="New Password (Min. 8 Chars)"
                            id="password"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline
                                    className={classes.inputIconsColor}
                                  />
                                </InputAdornment>
                              ),
                              value: this.state.password,
                              onChange: this.handleInput,
                              onKeyPress: this.handleEnterKeyPress,
                            }}
                          />
                        </React.Fragment>
                      )}
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      {!this.state.successMessage && (
                        <Button
                          style={{
                            background: "#41525c",
                            textTransform: "capitalize",
                          }}
                          onClick={this.handleResetPassword}
                        >
                          Update Password
                        </Button>
                      )}
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(ResetPassword);
