import React, {Component} from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import AdminAppBar from 'components/AdminAppBar';
import AdminNavigationBar from 'components/AdminNavigationBar';
import Hidden from '@material-ui/core/Hidden';
import withStyles from "@material-ui/core/styles/withStyles";
import navigationBarStyle from "components/styles/NavigationBarStyle.js";

const drawerWidth= 260;
const styles= (theme) => ({
  contentShift:{
    width: "100%",
    zIndex: "12",
    backgroundColor: "#eeeeee",
    paddingLeft: drawerWidth,
    minHeight: "93vh",
    height: "auto",
    [theme.breakpoints.down("md")]:{
      paddingLeft: "0px"
    }
  }

}) 

class AdminAppLayout extends Component {

  render () {
    const{yieldComponent, hideNavigator, hideAppBar, classes} = this.props;
    return (
      <React.Fragment>
        <ErrorBoundary>
        { hideAppBar ?
          ""
         : <AdminAppBar {...this.props} />
        }
        <Hidden mdDown>
           { hideNavigator ?
              ""
            : <AdminNavigationBar {...this.props} />
          }
        </Hidden>
         

        <div className={classes.contentShift}
         style={hideNavigator?{paddingLeft: "0px"}:{}} >
        {
          yieldComponent
        }
        </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, {withTheme: true})(AdminAppLayout);
