import React from "react";
import ReactDOM from "react-dom";
import QuestionHeader from "components/test/QuestionHeader.jsx";
// import QuestionText from "components/test/QuestionText.jsx";
// import QuestionImages from "components/test/QuestionImages.jsx";
import SingleAnswerOptions from "components/test/SingleAnswerOptions.jsx";
import MultipleAnswerOptions from "components/test/MultipleAnswerOptions.jsx";
import NumericalAnswerComponent from "components/test/NumericalAnswerComponent.jsx";
import QuestionFooter from "components/test/QuestionFooter.jsx";

import QuestionTimeTakenText from "components/test/QuestionTimeTakenText.jsx";
import MatrixGridListsComponent from "components/test/view/MatrixGridListsComponent.jsx";
import MatrixGridAnswerComponent from "components/test/view/MatrixGridAnswerComponent.jsx";
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";


 export default  class TestQuestionsContainer extends React.Component {

 	componentDidMount(){
 		if(window.MathJax)
 		window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
 	}

 	componentDidUpdate(){
 		if(window.MathJax)
 		window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
 	}

 	getInlineComponentStyles(data, index){
		if(!data || data.length < 1){
			return false
		}
		if (data[index][1].includes("inline")){
			return true
		}
		if(index > 0 && data[index-1][1].includes("inline")){
			return true
		}
		return false
	}

	render() {
		const { testData , nextQ, prevQ ,lastQ, firstQ, userData, handleAnswerChange, 
			handleMultipleAnswerChange,handleMatrixAnswerChange,
			handleMarkForReviewChange, handleClearResponse,showSolutions} = this.props;
		return(
			<div style={{paddingBottom: "50px"}}> {/*FOR THE PAGE TO BE SCROLLABLE WITHOUT FOOTER OCCLUSION*/}
			<QuestionHeader number={testData["question_no"]} 
			pattern={testData["pattern_name"]} 
			subject={testData["subject_name"]}
			positiveMarks={testData["positive_marks"]}
			negativeMarks={testData["negative_marks"]}
			partialMarks={testData["partial_marks"]}
			/>
			<hr/>
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' &&
				<h4 style={{marginLeft: "15px", paddingTop:"4px",
						  fontWeight: "600", fontSize: "16px"}}>Passage</h4>
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' &&
			<div style={{paddingLeft: "15px", paddingRight: "15px"}}>
				{testData["question_content"].passage.map((item, index) => (
					<React.Fragment key={index}> 
						{ item[0] ?
							<QuestionText text={item[0]}/>
						: null
						}
						{ item[1] ?
							<QuestionImages src={item[1]}/>
						: null
						}
					</React.Fragment>
				))}
			</div>
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' &&
				<h4 style={{marginLeft: "15px", paddingTop:"4px",
						  fontWeight: "600", fontSize: "16px"}}>Question</h4>
			}
			{ testData["question_content"] &&
			<div style={{paddingLeft: "15px", paddingRight: "15px"}}>
				{ testData["question_content"].question.map((item, index) => (
					<React.Fragment key={index}> 
						{ item[0] ?
						<QuestionText text={item[0]}/>
						: null
						}
						{ item[1] ?
							<QuestionImages src={item[1]}/>
						: null
						}
					</React.Fragment>
				))}
			</div>
			}
			<div style={{paddingBottom: "20px"}}></div>
			{testData["question_content"] && (testData["pattern_short_name"] == 'MM' || ( testData["pattern_short_name"] == 'MCQ' && testData["question_content"].list1 && testData["question_content"].list2 && testData["question_content"].list1.length > 0 && testData["question_content"].list2.length>0)) &&
				<MatrixGridListsComponent {...this.props}
				list1={testData["question_content"].list1}
				list2={testData["question_content"].list2} />
			}
			{ testData["question_content"] && userData && (testData["pattern_short_name"] == 'MCQ' || testData["pattern_short_name"] == 'PASSAGE') ?
				<SingleAnswerOptions {...this.props}
				answer={userData.answer ? userData.answer : ""} 
				AnswerKey={userData.key ? userData.key : ""}
				isDisabled={(!userData.answer) && (!userData.is_answerable) } 
				options={testData["question_content"].options} 
				isOptionsJumbled={testData["question_content"]?.isOptionsJumbled}
				answerChanged={handleAnswerChange}/>
	 		     : null
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'MAQ' && userData ?
				<MultipleAnswerOptions {...this.props}
				answer={userData.answer ? userData.answer : ""} 
				AnswerKey={userData.key ? userData.key : ""} 
				isDisabled={(!userData.answer) && (!userData.is_answerable) } 
				options={testData["question_content"].options} 
				isOptionsJumbled={testData["question_content"]?.isOptionsJumbled}
				answerChanged={handleMultipleAnswerChange}/>
	 		     : null
			}
			{ testData["question_content"] && (testData["pattern_short_name"] == 'NUMERICAL' ||  testData["pattern_short_name"] == 'INTEGER' ) && userData ?
				<NumericalAnswerComponent {...this.props}
				answer={userData.answer ? userData.answer : ""} 
				AnswerKey={userData.key ? userData.key : ""}
				isDisabled={(!userData.answer) && (!userData.is_answerable) } 
				patternType={testData["pattern_short_name"]} 
				options={testData["question_content"].options} 
				answerChanged={handleMultipleAnswerChange}/>
	 		     : null
			}
			{ testData["question_content"] && (testData["pattern_short_name"] == 'MM') && userData ?
				<MatrixGridAnswerComponent {...this.props}
				answer={userData.answer ? userData.answer : ""} 
				AnswerKey={userData.key ? userData.key : ""}
				isDisabled={(!userData.answer) && (!userData.is_answerable) }  
				list1={testData["question_content"].list1}
				list2={testData["question_content"].list2} 
				answerChanged={handleMatrixAnswerChange}/>
	 		     : null
			}
			{ testData["solution"] && userData && showSolutions ?
				<QuestionTimeTakenText timeTakenInSeconds={userData.time_taken}/>
			: null}
			{ testData["solution"] && showSolutions ?
				<div style={{backgroundColor: "#ff980033", margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",fontWeight: "600"}}>Solution</h4>
				{JSON.parse(testData["solution"]).map((item, index) => (
					<div key={index}
					/*style={
						  this.getInlineComponentStyles(JSON.parse(testData["solution"]),index)
				          ? {display: "inline-block"} : {} }*/>
					{ item[0] ?
					<QuestionText text={item[0]}/>
					: null
					}
					{ item[1] ?
						<QuestionImages src={item[1]} backgroundColor="transparent"/>
					: null
					}
					</div>
				))}
				</div>
	 		     : null  
			}
			{ testData["question_content"] && userData ?
			<QuestionFooter {...this.props} nextQ={nextQ} prevQ={prevQ} lastQ={lastQ} firstQ={firstQ} 
			markedForReview={userData.markedForReview} 
			markForReviewChanged={handleMarkForReviewChange}
			handleClearResponse={handleClearResponse}/>
			: null
			}
			</div>
			);
	}

}
