import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ErrorBoundary from "components/ErrorBoundary.js";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import InteractiveList from 'components/misc/InteractiveList';
import ErrorLogo from "@material-ui/icons/Error";
import LiveUsersList from "components/analysis/tables/LiveUsersList"
import qs from 'query-string';
import Button from "CustomButtons/Button.jsx";
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import $ from 'jquery';

const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
        title:{
          display: "none"
        },
        root:{
          backgroundColor: "none",
          boxShadow: "none",
          justifyContent: "normal"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader:{
          zIndex: "none"
        }
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })


class ScheduleTestLiveUsers extends React.Component {

  state = {
    errorMessages: [],
    scheduledTestPaperId: "",
    liveUsersData: "",
    scheduledTestData: "",
  }

  componentDidMount(){
    const scheduled_test_paper_id = qs.parse(this.props.location.search).scheduled_test_paper_id; 
    this.getLiveUsers(scheduled_test_paper_id)
  }

  getLiveUsers(scheduled_test_paper_id){
    if(scheduled_test_paper_id){ //Process of duplicate only if test_id exists
       $.ajax({
          type: 'GET',
          url: getBaseURL() + '/react/test-paper-live-users.json?scheduled_test_paper_id=' + scheduled_test_paper_id,
        }).done((data, status, jqXHR)=>{
          this.setState({scheduledTestData: data["scheduled_test_info"],
            liveUsersData: data['live_users'],
            scheduledTestPaperId: scheduled_test_paper_id});
        
        }).fail((jqXHR, textStatus, errorThrown) => {
            var errorMessages = this.state.errorMessages;
            errorMessages.push({message: jqXHR["responseJSON"].message})
            this.setState({errorMessages: jqXHR["responseJSON"].message});
        })
    }
  }

  
  handleDoneAction = (e) => {
    e.preventDefault();
    this.props.history.push('/schedule-tests');
  }

  resetStudentAttempt = (e) =>{
    e.preventDefault();
    const scheduled_test_paper_id = this.state.scheduledTestPaperId;
    const user_id = e.currentTarget.name;
    if(scheduled_test_paper_id && user_id){ //Process of duplicate only if test_id exists
       $.ajax({
          type: 'GET',
          url: getBaseURL() + '/react/reset-user-attempt.json?user_id='+user_id+'&scheduled_test_paper_id=' + scheduled_test_paper_id,
        }).done((data, status, jqXHR)=>{
          this.getLiveUsers(scheduled_test_paper_id);
        }).fail((jqXHR, textStatus, errorThrown) => {
            var errorMessages = this.state.errorMessages;
            errorMessages.push({message: jqXHR["responseJSON"].message})
            this.setState({errorMessages});
        })
    }
  }

  render () {
    const{classes} = this.props;
    const{scheduledTestData, scheduledTestPaperId, liveUsersData} = this.state;
    const titleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px"
    };    
    const buttonStyle = {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "capitalize",
    background: "#3f51b5",
      lineHeight: "20px",
      borderRadius: "3px",
      marginBottom: "auto"
  };
    return (
      <React.Fragment>
        <ErrorBoundary>
          <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px"}}
          spacing={24}>
          <GridItem xs={12} sm={12} md={10} style={{padding: "0px 12px"}}>
            <h1 style={titleStyle} >Live Statistics Dashboard</h1>
            
          </GridItem>
          <GridItem xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px", margin: "auto"}}>
            <Button 
              style={buttonStyle}
              onClick={this.handleDoneAction}>Done
            </Button> 
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{padding: "0px 12px"}}>
            <hr style={{marginTop: "10px", marginBottom: "10px"}}/>
          </GridItem>
         
          <React.Fragment>
              <GridItem  xs={12} sm={12} md={1}  style={{padding: "0px 12px"}}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={11}  style={{padding:"0px 12px"}}>
                <h3 style={subtitleStyle} >Test Details</h3>
              </GridItem>
              <GridItem  xs={12} sm={12} md={2} style={{padding:"12px"}}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3} style={{padding:"12px"}}>
              <InputLabel shrink htmlFor="material">
                    Schedule Test Name
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["scheduled_test_name"]}</p>
                </GridItem>
              <GridItem  xs={12} sm={12} md={3} style={{padding:"12px"}}>
                <InputLabel shrink htmlFor="material">
                    Schedule Test Number
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["scheduled_test_identifier"]}</p>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3} style={{padding:"12px"}}>
                <InputLabel shrink htmlFor="material">
                    User Group
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["user_group_indentifier"]}</p>
              </GridItem>
               {this.state.errorMessages && this.state.errorMessages.length > 0 &&
                <React.Fragment>
                  <GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                    </GridItem>
                  <GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                  {this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
                          <div key={index} style={{marginBottom: "-24px", marginTop: "8px"}}>
                          <ErrorLogo style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
                          <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} /*TODO className={classes.robotoFontRegular}*/>{errorMessage["message"]}</p> 
                          </div>
                          ): null
                        }
                      </GridItem>
                  </React.Fragment>
                  }
              {(scheduledTestPaperId )&& liveUsersData &&
                <GridItem  xs={12} sm={12} md={12} style={{padding: "12px 24px"}}>
                  <LiveUsersList reportName={scheduledTestData["scheduled_test_name"]}  data={liveUsersData} paperId={scheduledTestPaperId} resetStudentAttempt={this.resetStudentAttempt}/>
                </GridItem>
              }
            </React.Fragment>
            </GridContainer>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default ScheduleTestLiveUsers;
