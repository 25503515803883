import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from "prop-types"
import $ from 'jquery';


import AdminHeader from "./header/AdminHeader.jsx";
import HeaderLinks from "./header/HeaderLinks.jsx";
import HeaderRightLinks from "./header/HeaderRightLinks.jsx";

import brand_black from "assets/img/images/brand_black.png";
import brand_white from "assets/img/images/brand_white.png";

import ErrorBoundary from "./ErrorBoundary.js";


class AdminAppBar extends Component {

 logoutUser =(e)=> {
    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_users/sign_out'
    }).done((response, status, jqXHR)=>{
      sessionStorage.removeItem('as_username');
      sessionStorage.removeItem('as_isUserAnalyst');
      sessionStorage.removeItem('as_isUserMetaReadAdmin');
      sessionStorage.removeItem('user');
      this.redirectToLoginPage();
    }) 
  }

  redirectToLoginPage(){
    if(this.props.history){
      this.props.history.push("/");
    }else{
      window.location.href = getBaseURL() + "/"
    }
  }

  render () {
    return (
      <React.Fragment>
      <ErrorBoundary>
      <AdminHeader
          color="dark"//"transparent"//"appTheme"
          brandDefault={brand_white}
          changeBrandOnScroll={brand_black}
          //leftLinks={/*<HeaderLinks  />*/}
          rightLinks={<HeaderRightLinks logoutUser={this.logoutUser} />}
          fixed      
        />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default AdminAppBar;
