import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from "prop-types";
import $ from 'jquery';
import qs from 'query-string';


import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import ShortText from "@material-ui/icons/ShortText";
import MUIDataTable from "mui-datatables";

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';


import IconButton from "@material-ui/core/IconButton";
//import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "CustomButtons/Button.jsx";

//import TestModelFormStyle from "components/styles/TestModelFormStyle.js";

import { withStyles } from "@material-ui/core/styles";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import TextStyle from "styles/TextStyle.js";
import classNames from "classnames";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,PieChart, Pie, Text,Sector,
 ReferenceLine, Label,LineChart, Line, AreaChart, Area
} from 'recharts';

import {formatDateWithoutTimeAndYear} from "components/helpers/DateTimeHelper.jsx";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';


const getMuiThemeForModal =  createMuiTheme({
    overrides: {
      MuiModal:{
        root:{
          zIndex: "90000"
        }
    },
      typography: {
        useNextVariants: true,
      },
    },
    typography: {
      useNextVariants: true,
    }
  })

const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
        title:{
          display: "none"
        },
        root:{
          backgroundColor: "none",
          boxShadow: "none",
          justifyContent: "normal"
        }
      },
      MuiTableCell:{
        root:{
          padding: "4px 24px 4px 24px",
          textAlign: "center",
        },
        body:{
           fontSize: "12px"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader:{
          zIndex: "none"
        }
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })



const COLORS = ['#0088FE', '#00a99d', '#FFBB28', '#FF8042'];
const COLORS1 = ['#028f4e', '#F44336', '#7c7c7ca6', '#FF8042'];


const RADIAN = Math.PI / 180;                    
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Text x={x > cx ? x-10: x+10} y={y} fill="white" style={{fontWeight: "500"}} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </Text>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    payload.awarded_marks && payload.awarded_marks > 0 ?
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={-10} style={{fontWeight: "400"}} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={10} style={{fontSize: "0.9rem"}} textAnchor={textAnchor} fill="#333">{payload.awarded_marks} Marks</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={30} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
     : null
  );
};

const renderCustomizedLabel1 = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
   const radius = 20+innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    value > 0 ?
    <Text x={x} y={y} fill={COLORS1[index % COLORS1.length]}  style={{fontWeight: "500"}} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(value).toFixed(0)}`}
    </Text>
    : null
  );
};

const styles = theme => ({
  root: {
    width: '100%'
  },
  mainContainer: {
  width: "100%",
  zIndex: "12",
  backgroundColor: "rgba(73, 123, 52, 0.1)",
  padding: "10px",
  //paddingTop: "60px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
     //fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
    paddingBottom: "5px",
    minWidth: "150px",
    fontWeight: "100",
    fontSize: "0.9rem",
    fontFamily: "'Noto Serif', serif"
  },
  clipContainerStyle:{
    //backgroundColor: "white", 
    margin: "0px",
    padding: "12px"
  },
  clipStyle:{
    backgroundColor: "white", 
    margin: "0px",
    //padding: "10px",
    paddingTop: "0px", 
    paddingBottom: "20px",
    //height: "20vh",
    boxShadow: "0 0 5px rgba(50, 50, 50, 0.25)",
    borderRadius: "2px",
    borderColor: "rgba(34, 56, 69, 0.5)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "3px 0px 0",
  height: "100%"
  },
  clipHeader:{
    paddingTop: "10px",
    marginTop: "0px"
  },
  clipHeaderStyle:{},
  clipBodyStyle:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "1px 0px 0"
  },
  clipInlineDataStyle:{
    marginTop: "20px",
    marginBottom: "10px",
    display: "inline-block",
    lineHeight: "1.5em",
    marginRight: "5px"
  },
  borderRight:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "0px 1px 0",
  marginTop: "10px"
  },
  inlineStyle:{
    display: "inline-block",
    marginRight: "10px"
  },
  bigClipStyle:{
    backgroundColor: "white", 
    margin: "0px",
    //padding: "10px",
    paddingTop: "0px", 
    paddingBottom: "0px",
    //height: "40vh",
    boxShadow: "0 0 5px rgba(50, 50, 50, 0.25)",
    borderRadius: "4px"
  },
  zeroMarginTop:{
    marginTop: "0px"
  },
  subTextAccurayAnalysis:{
     //marginTop: "75px", 
     marginBottom: "0px",
     fontWeight: "500", 
     marginLeft: "85px",
     fontSize: "1rem",
     fontFamily: "'Noto Serif', serif"
     //fontFamily: "Roboto"
  },
  centerTextAccurayAnalysis:{
     //marginTop: "-100px", 
     //marginBottom: "0px",
     fontWeight: "400", 
     //marginLeft: "82px",
     fontSize: "1.4rem",
     //fontFamily: "'Noto Serif', serif"
     //fontFamily: "Roboto"
  },
  selected: {
   backgroundColor: "#3f51b5",
   "color": "white !important"},
  ...imagesStyle,
  ...TextStyle
});


class UserAnalysisDashboard extends React.Component {

constructor(props) {
  super(props);
  this.state = {
    user_id: "",
    activeIndex: [] ,
    testResultsData: "",
    testTypesData: [],
    programsData: [],
    testTypeId: "",
    programId: "",
    relativeUserMarksData: [],
    relativeUserRankData: [],
    recentTestsTableColumns: [],
    recentTestsTableResults: [],
    relativeUserAccuracyData:[],
    areaChartMarksData:[],
    averageResults: [],
    username: ""
  };
}

componentDidMount(){
  const user_id = qs.parse(this.props.location.search).user_id;
  var testTypeId = qs.parse(this.props.location.search).test_type_id;
  var programId = qs.parse(this.props.location.search).program_id;

  if(!user_id){
    alert("User not provided.");
    return
  }
  this.setState({user_id: user_id})

  $.ajax({
       type: "GET",
       url: getBaseURL() + '/student-analysis-dashboard.json?child_id='+user_id ,
       dataType: "JSON",
  }).done((data) => {
    if(data["username"]){
      this.setState({username: data["username"]})
    }
    if(data && data["test_types"] && data["test_types"].length > 0){    
      this.setState({testTypesData: data["test_types"]})
      if(!testTypeId){
        testTypeId = data["test_types"][0].test_type_id;
      }
    }
    if(data && data["programs"] && data["programs"].length > 0){ 
      this.setState({programsData: data["programs"] })
      if(!programId){
        programId = data["programs"][0].program_id;
      }
    }
    if(testTypeId && programId){
      this.setState({testTypeId,programId});
      this.handleMultipleTestsData(testTypeId, programId);
    }

    if(data["results"] && data["results"].length > 0){
      this.setState({averageResults: data["results"]})
      data["results"] = data["results"].filter(results => results.test_type_id == testTypeId && results.program_id == programId )
      if (data["results"].length > 0){
        this.populateSubjectDetailsData(data["results"][0]);
      }
    }

    //TODO need to remove hard coding active indexes of all possible subject
    //the list has 8 entries so that all 8 subjects if present will have activeRenderedShape
    setTimeout(function(){
             this.setState({activeIndex: [0,1,2,3,4,5,6,7]})
        }.bind(this),2000);

  });
  
}

filterAverageResultsAndPopulate(testTypeId, programId){
  var averageResults = this.state.averageResults;
  averageResults = averageResults.filter(results => results.test_type_id == testTypeId && results.program_id == programId )
  this.populateSubjectDetailsData(averageResults[0]);
}

populateSubjectDetailsData(data){
  if(!data){
    this.setState({testResultsData: ""});
    return
  }
  var testResultsData = {}
  var subjDetailedData = [];
  var subjectsInfo =  (data.subjects_info);
  const totalAwardedMarks = data.total_marks;
  var subjectData = "" 
  for (var subj in subjectsInfo){
      subjectData = subjectsInfo[subj]
      subjDetailedData.push({name: subj, 
                awarded_marks: subjectData["marks"],
                percentage: parseInt(this.calcPercentage(parseFloat(subjectData["marks"]),parseFloat(totalAwardedMarks))),
                rank: subjectData["rank"]
                });
    } 
    testResultsData["subjects_info"] = subjDetailedData;
    testResultsData["awarded_marks"] = data.total_marks;
    testResultsData["total_marks"] = data.test_marks;
    //testResultsData["percentage"] = this.calcPercentage(parseFloat(data.total_marks),parseFloat(totalAwardedMarks));
    testResultsData["rank"] = data.rank;
    
    this.setState({testResultsData: testResultsData});
}

calcPercentage(value, totalValue){
  if (value < 0){
    value = 0
  }
  if(totalValue <= 0){
    return 0
  }
  const percent = parseFloat(100*(value/totalValue)).toFixed(2);
  if(percent >= 0){
    return percent
  } else{
    return 0
  }
}


goBack = (e) =>{
  if(this.props.role == "student"){
    this.props.history.push('/home');
  }else{
    this.props.history.push('/overall_analysis?program_id='+this.state.programId+'&test_type_id='+this.state.testTypeId);
  }
}


handleProgramTestTypeChange = (e) => {
  e.preventDefault();
  const attr = e.target.name;
    if(attr == "testType"){
      const testTypeId= e.target.value;
      this.setState({testTypeId})
      this.handleMultipleTestsData(testTypeId, this.state.programId);
      this.filterAverageResultsAndPopulate(testTypeId, this.state.programId);
    }else if(attr == "program"){
      const programId= e.target.value;
      this.setState({programId});
      this.handleMultipleTestsData(this.state.testTypeId, programId)
      this.filterAverageResultsAndPopulate(this.state.testTypeId, programId);
    }
    //Need to refersh data on page when program/testType is changed
}

handleMultipleTestsData(testTypeId, programId){
    var user_id = this.state.user_id;
    var urlExtension = ""
    if(programId ){
      urlExtension = '&program_id='+programId.toString()
    }
    $.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-attempted-tests-results?details_results=Y&onlyActiveTests=Y&g_user_id='+user_id+'&test_type_id='+ testTypeId.toString() +'&limit=10' + urlExtension,
          dataType: "JSON",
        }).done((data) => {
          //TODO else part error handling on ELSE and AJAX Failure
          this.populateRelativeMarksAndRanksData(data)
          this.populateRecentTestsTableData(data);
        });  
  }

  populateRelativeMarksAndRanksData(data){
    if(data && data["tests"] && data["tests"].length > 0){
      var relativeUserMarksData = [];
      var relativeUserMarksJsonData = {};
      var relativeUserRankData = [];
      var relativeUserRankJsonData = {};
      var relativeUserAccuracyData = [];
      var relativeUserAccuracyJsonData = {};
      var lineChartMarksData = []
      var areaChartMarksData = []
      var lineChartHighestMarksData = []
      var lineChartRankData = []
      var lineChartAccuracyData = []
      var testData = {}
      var totalTestsCount = data["tests"].length;
      for(var index in data["tests"]){
        testData = data["tests"][totalTestsCount-index-1]
        lineChartMarksData.push({category: formatDateWithoutTimeAndYear(testData.last_attempted_date), value: testData.awarded_marks});
        areaChartMarksData.push({category: formatDateWithoutTimeAndYear(testData.last_attempted_date), marks: testData.awarded_marks, highest_marks:testData.highest_total_marks});
        lineChartRankData.push({category: formatDateWithoutTimeAndYear(testData.last_attempted_date), value: testData.rank});
        lineChartAccuracyData.push({category: formatDateWithoutTimeAndYear(testData.last_attempted_date), value: testData.accuracy});
      }
      
      relativeUserMarksJsonData["data"] = lineChartMarksData;
      relativeUserMarksJsonData["name"] = data["tests"][0].test_type_name + " Marks"
      relativeUserMarksData.push(relativeUserMarksJsonData);

      relativeUserRankJsonData["data"] = lineChartRankData;
      relativeUserRankJsonData["name"] = data["tests"][0].test_type_name + " Rank"
      relativeUserRankData.push(relativeUserRankJsonData);

      relativeUserAccuracyJsonData["data"] = lineChartAccuracyData;
      relativeUserAccuracyJsonData["name"] = data["tests"][0].test_type_name + " Accuracy"
      relativeUserAccuracyData.push(relativeUserAccuracyJsonData);
      this.setState({relativeUserMarksData, relativeUserRankData, relativeUserAccuracyData,areaChartMarksData});
    } else{
      this.setState({relativeUserMarksData: [], relativeUserRankData: [],relativeUserAccuracyData: [],areaChartMarksData:[]});
    }
  }

  populateRecentTestsTableData(data){
    var recentTestTableResults=[];
    var recentTestTableColumns = []
    var results = data["tests"];
    if(!results || !results.length > 0){
      //TODO error message handling
      this.setState({recentTestTableResults});
      return
    }
    if( results && results.length > 0){
      //Push actual results data to table
      var prev_test_rank = "";
      var prev_test_accuracy = "";
      for(var i=0; i < results.length ; i++){
        var resultRow = results[i]; 
        var testResultRow = []
        testResultRow.push(resultRow["id"]);
        testResultRow.push(resultRow["name"]);
        testResultRow.push(formatDateWithoutTimeAndYear(resultRow["last_attempted_date"]));
        testResultRow.push(resultRow["awarded_marks"]);
        
        
        if (i+1 < results.length){
          prev_test_accuracy = results[i+1]["accuracy"];
          prev_test_rank = results[i+1]["rank"];
        }

        if(prev_test_rank && resultRow["rank"] < prev_test_rank){
          testResultRow.push(
                <div>
                  <p style={{display: "inline-block", color:"#008000", marginBottom: "0px", overflow: "inherit"}} >{resultRow["rank"]}</p> 
                  <ArrowDropUp style={{verticalAlign: "middle", color: "#008000", marginTop: "-3px"}}></ArrowDropUp>
                </div>
          );
        }else if(prev_test_rank && resultRow["rank"] > prev_test_rank){
          testResultRow.push(
                  <div>
                    <p style={{display: "inline-block", color:"#f44336", marginBottom: "0px", overflow: "inherit"}} >{resultRow["rank"]}</p> 
                    <ArrowDropDown style={{verticalAlign: "middle", color: "#f44336", marginTop: "-3px"}}></ArrowDropDown>
                  </div>
            );
        }else{
          testResultRow.push(resultRow["rank"]);
        }


        if(prev_test_accuracy && resultRow["accuracy"] > prev_test_accuracy){
          testResultRow.push(
                <div>
                  <p style={{display: "inline-block", color:"#008000", marginBottom: "0px", overflow: "inherit"}} >{resultRow["accuracy"]+"%"}</p> 
                  <ArrowDropUp style={{verticalAlign: "middle", color: "#008000", marginTop: "-3px"}}></ArrowDropUp>
                </div>
          );
        }else if(prev_test_accuracy && resultRow["accuracy"] < prev_test_accuracy){
          testResultRow.push(
                  <div>
                    <p style={{display: "inline-block", color:"#f44336", marginBottom: "0px", overflow: "inherit"}} >{resultRow["accuracy"]+"%"}</p> 
                    <ArrowDropDown style={{verticalAlign: "middle", color: "#f44336", marginTop: "-3px"}}></ArrowDropDown>
                  </div>
            );
        }else{
          testResultRow.push(resultRow["accuracy"]+"%");
        }
        recentTestTableResults.push(testResultRow);
      }

      //Push column names of table
      recentTestTableColumns.push(
        {name: "scheduledTestId",
            options: {
              display: false,
            }
        }
      ); 
      recentTestTableColumns.push(
        {
          name: "Name",
          options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              const linkButtonStyle = {
                textTransform: "none",
                backgroundColor: "white",
                color: "#3f51b5",
                borderRadius: "3px",
                padding: "0.3rem 0.75rem",
                boxShadow: "none",
                fontSize: "12px"
              }
              return (
              <div>
                  <Button
                    onClick={this.goToScheduledTestAnalysis}
                    name={tableMeta.rowData[0]}
                    style={linkButtonStyle}
                    size="sm"
                    >
                     {value}
                  </Button>
              </div>
              );
              }
          }
        }
      );
      recentTestTableColumns.push({name: "Date"});
      recentTestTableColumns.push({name: "Marks"});
      recentTestTableColumns.push({name: "Rank"});
      recentTestTableColumns.push({name: "Accuracy"});

      this.setState({recentTestTableColumns,recentTestTableResults});
    }
  }

  goToScheduledTestAnalysis =(e) => {
    e.preventDefault();
    const test_id = e.currentTarget.name
    this.props.history.push('/total-test-analysis?viewport=embedded&scheduled_test_id=' +test_id);
  }

  render () {
    const { classes } = this.props;
    const {username,testResultsData,testTypesData,
      programsData, programId, testTypeId,
      relativeUserMarksData, relativeUserRankData,relativeUserAccuracyData,
      recentTestTableResults, recentTestTableColumns,areaChartMarksData } = this.state;
    const testResultsTableOptions = {
      filter: false,
      selectableRows: 'none',
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      responsive: 'stacked',
      expandableRows: false,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
          return; //No Footer needed overriding defaut footer
      }
    }
    return (
      <React.Fragment>
        <div className={classes.mainContainer}>
        <GridContainer style={{
        backgroundColor: "white",
        width: "100%",
        margin: "0px",
        padding: "5px"
      }}
        alignItems="center"
        spacing={24} >
          <GridItem xs={12} sm={12} md={4} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h4 style={{marginRight: "15px", marginLeft: "15px", fontWeight: "500", fontSize: "1.4em"}} className={classNames(classes.robotoFontBold,classes.inlineStyle)} >Overall Analysis</h4>
            {/*<div style={{float:"right", marginTop: "18px"}} className={classNames(classes.inlineStyle)}>
          */}</GridItem>
          <GridItem xs={12} sm={12} md={8} style={{float: "right",paddingTop: "18px", paddingBottom: "0px", marginBottom: "5px"}}>
            <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >{username}</p> 
            <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >|</p>
            <p style={{fontSize: "0.8rem"}}className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >Program</p>
            <MuiThemeProvider theme={getMuiThemeForModal}>
            { programsData && programsData.length > 1 ?
              <FormControl style={{width: "15%"}}>
                <Select value={programId ? programId : programsData[0].program_id} onChange={this.handleProgramTestTypeChange} 
                  style={{fontSize: 'inherit'}}
                  name={"program"}
                  SelectDisplayProps={{style: {marginLeft: "5px"}}}>
                  { programsData.map((program, index) =>
                    <MenuItem key={index}
                    style={{fontSize: "14px"}} value={program.program_id}>{program.program_short_name}</MenuItem>
                  )}
                </Select>
              </FormControl>
              : programsData && programsData.length > 0 &&  <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >{programsData[0].program_short_name}</p> 
              }
              <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} style={{marginLeft: "10px"}} >|</p>
              <p style={{fontSize: "0.8rem"}}className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >Test Type</p>
              { testTypesData && testTypesData.length > 1 ?
              <FormControl style={{width: "15%"}}>
                <Select value={testTypeId ? testTypeId : testTypesData[0].test_type_id} onChange={this.handleProgramTestTypeChange} 
                  style={{fontSize: 'inherit'}}
                  name={"testType"}
                  SelectDisplayProps={{style: {marginLeft: "5px"}}}>
                  { testTypesData.map((testType, index) =>
                    <MenuItem key={index}
                    style={{fontSize: "14px"}} value={testType.test_type_id}>{testType.test_type_short_name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            : testTypesData && testTypesData.length > 0 &&  <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >{testTypesData[0].test_type_short_name}</p> 
            }
            </MuiThemeProvider>
            <Button
              color="transparent"
              size="sm"
              style={{textTransform: "none", float: "right",
              backgroundColor: "#57849b", color: "white", margin: "0px", marginLeft: "20px",
              verticalAlign: "top"}}
              onClick={this.goBack}
              >
              Done
              </Button>
            {/*</div>*/}
          </GridItem>
        </GridContainer>
        <GridContainer style={{
              width: "100%",
              margin: "0px",
              marginTop: "10px",
            }}    spacing={24} >
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer spacing={24}>
              <GridItem xs={12} sm={12} md={6} className={classes.clipContainerStyle}>
                <GridContainer direction="column" className={classes.clipStyle}>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                    <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Marks</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["awarded_marks"] ? testResultsData["awarded_marks"] : "-"}</h1>
                        <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_marks"]} Marks</h1>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                        <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.awarded_marks}</strong></h1>
                        ))}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.clipContainerStyle}>
                <GridContainer direction="column" className={classes.clipStyle}>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                    <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Rank</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["rank"] ? testResultsData["rank"] : "-"}</h1>
                        <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_students"]} Participants</h1>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                         {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                        <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.rank}</strong></h1>
                        ))}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              { testResultsData["awarded_marks"] &&  testResultsData["awarded_marks"] > 0 ?
              <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
                <GridContainer direction="column" className={classes.clipStyle}>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                    <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Subject Distribution</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                    <GridContainer style={{ margin: "auto", marginTop: "25px"}}>
                    <GridItem xs={12} sm={12} md={4}>
                      <PieChart width={500} height={220}
                      style={{display:"inline-block", width: "100%", height:"100%", margin:"auto", textAlign: "center"}}
                      margin={{
                          top: 50, right: 0, left: 250, bottom: 10,
                        }}
                        >
                          <Pie dataKey="percentage"  
                          data={   testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].length > 0  
                          ? testResultsData["subjects_info"] : null} 
                          cx={50} 
                          cy={55} 
                          outerRadius={50}
                          paddingAngle={5}
                          fill="#8884d8" 
                          activeIndex={this.state.activeIndex}
                          activeShape={renderActiveShape} >
                             {
                              testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].length > 0 
                              ? testResultsData["subjects_info"].map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                              : null }
                            </Pie>
                          {/*<Legend layout='vertical' align='right' verticalAlign='middle'
                          />*/}
                          <Tooltip />
                      </PieChart>
                  </GridItem>
                  </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              : null}
              { recentTestTableResults && recentTestTableResults.length > 0 &&
              <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
                <GridContainer direction="column" className={classes.clipStyle}>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                    <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Recent Tests Analysis</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                    <GridContainer style={{ margin: "auto", marginTop: "25px"}}>
                      <MuiThemeProvider theme={getMuiThemeForTable}>
                      <MUIDataTable 
                        options={testResultsTableOptions}
                        columns={recentTestTableColumns}  
                        data={recentTestTableResults}              
                      />
                      </MuiThemeProvider>
                  </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              }
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
              <GridContainer spacing={24}>
                <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
                  <GridContainer direction="column" className={classes.clipStyle}>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                      <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Marks Progress Report</h1>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                      <GridContainer style={{paddingTop: "20px", paddingBottom: "10px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                          {relativeUserMarksData && relativeUserMarksData.length > 0 ?
                          <LineChart width={600} height={260} className={classes.smallRobotoFontRegular}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" type="category" allowDuplicatedCategory={true} />
                                <YAxis dataKey="value" />
                                <Tooltip />
                                <Legend verticalAlign="top" height={36} iconType="square"/>
                                {relativeUserMarksData.map(s => (
                                  <Line dataKey="value" data={s.data} name={s.name} key={s.name} />
                                ))}
                          </LineChart>
                          : <p className={classes.smallRobotoFontRegular}>No data to display.</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {areaChartMarksData && areaChartMarksData.length > 0 ?
                          <AreaChart width={600} data={areaChartMarksData} height={260} className={classes.smallRobotoFontRegular}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" type="category" allowDuplicatedCategory={true} />
                                <YAxis />
                                <Tooltip />
                                <Legend verticalAlign="top" height={36} iconType="square"/>
                                  <Area dataKey="marks" type='monotone'  stroke='#8884d8' fill='#8884d8'   />
                                  <Area dataKey="highest_marks" type='monotone' stroke='#82ca9d' fill='#82ca9d'   />
                          </AreaChart>
                          : <p className={classes.smallRobotoFontRegular}>No data to display.</p>}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
                  <GridContainer direction="column" className={classes.clipStyle}>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                      <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Rank Progress Report</h1>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                      <GridContainer style={{paddingTop: "20px", paddingBottom: "10px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                          {relativeUserRankData && relativeUserRankData.length > 0 ?
                          <LineChart width={600} height={260} className={classes.smallRobotoFontRegular}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" type="category" allowDuplicatedCategory={true} />
                                <YAxis dataKey="value" />
                                <Tooltip />
                                <Legend verticalAlign="top" height={36} iconType="square"/>
                                {relativeUserRankData.map(s => (
                                  <Line dataKey="value" data={s.data} name={s.name} key={s.name} />
                                ))}
                          </LineChart>
                          : <p className={classes.smallRobotoFontRegular}>No data to display.</p>}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
                  <GridContainer direction="column" className={classes.clipStyle}>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                      <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Accuracy Progress Report</h1>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                      <GridContainer style={{paddingTop: "20px", paddingBottom: "10px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                          {relativeUserAccuracyData && relativeUserAccuracyData.length > 0 ?
                          <LineChart width={600} height={260} className={classes.smallRobotoFontRegular}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" type="category" allowDuplicatedCategory={true} />
                                <YAxis dataKey="value" />
                                <Tooltip />
                                <Legend verticalAlign="top" height={36} iconType="square"/>
                                {relativeUserAccuracyData.map(s => (
                                  <Line dataKey="value" data={s.data} name={s.name} key={s.name} />
                                ))}
                          </LineChart>
                          : <p className={classes.smallRobotoFontRegular}>No data to display.</p>}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
          </GridItem>
          </GridContainer>
        </div>
      </React.Fragment>
    );
  }
}

export default  withStyles(styles)(UserAnalysisDashboard);
