import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from "prop-types";
import $ from 'jquery';
import qs from 'query-string';


import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import ShortText from "@material-ui/icons/ShortText";
import MUIDataTable from "mui-datatables";

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';


import IconButton from "@material-ui/core/IconButton";
//import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "CustomButtons/Button.jsx";

//import TestModelFormStyle from "components/styles/TestModelFormStyle.js";

import { withStyles } from "@material-ui/core/styles";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import TextStyle from "styles/TextStyle.jsx";
import classNames from "classnames";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";


import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,PieChart, Pie, Text,Sector,
 ReferenceLine, Label
} from 'recharts';



const COLORS = ['#0088FE', '#00a99d', '#FFBB28', '#FF8042'];
const COLORS1 = ['#028f4e', '#F44336', '#7c7c7ca6', '#FF8042'];


const RADIAN = Math.PI / 180;                    
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Text x={x > cx ? x-10: x+10} y={y} fill="white" style={{fontWeight: "500"}} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </Text>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    payload.awarded_marks && payload.awarded_marks > 0 ?
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={-10} style={{fontWeight: "400"}} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={10} style={{fontSize: "0.9rem"}} textAnchor={textAnchor} fill="#333">{payload.awarded_marks} Marks</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={30} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
     : null
  );
};

const renderCustomizedLabel1 = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
   const radius = 20+innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    value > 0 ?
    <Text x={x} y={y} fill={COLORS1[index % COLORS1.length]}  style={{fontWeight: "500"}} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(value).toFixed(0)}`}
    </Text>
    : null
  );
};

const styles = theme => ({
  root: {
    width: '100%',
  },
  mainContainer: {
  width: "100%",
  zIndex: "12",
  backgroundColor: "rgba(73, 123, 52, 0.1)",
  padding: "10px",
  paddingTop: "70px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    paddingBottom: "5px",
    minWidth: "150px",
    fontWeight: "100",
    fontSize: "0.9rem",
    fontFamily: "'Noto Serif', serif"
  },
  clipContainerStyle:{
    margin: "0px",
    padding: "12px"
  },
  clipStyle:{
    backgroundColor: "white", 
    margin: "0px",
    paddingTop: "0px", 
    paddingBottom: "20px",
    boxShadow: "0 0 5px rgba(50, 50, 50, 0.25)",
    borderRadius: "2px",
    borderColor: "rgba(34, 56, 69, 0.5)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "3px 0px 0",
  height: "100%"
  },
  clipHeader:{
    paddingTop: "10px",
    marginTop: "0px"
  },
  clipHeaderStyle:{},
  clipBodyStyle:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "1px 0px 0"
  },
  clipInlineDataStyle:{
    marginTop: "20px",
    marginBottom: "10px",
    display: "inline-block",
    lineHeight: "1.5em",
    marginRight: "5px"
  },
  borderRight:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "0px 1px 0",
  marginTop: "10px"
  },
  inlineStyle:{
    display: "inline-block",
    marginRight: "10px"
  },
  bigClipStyle:{
    backgroundColor: "white", 
    margin: "0px",
    //padding: "10px",
    paddingTop: "0px", 
    paddingBottom: "0px",
    //height: "40vh",
    boxShadow: "0 0 5px rgba(50, 50, 50, 0.25)",
    borderRadius: "4px"
  },
  zeroMarginTop:{
    marginTop: "0px"
  },
  subTextAccurayAnalysis:{
     //marginTop: "75px", 
     marginBottom: "0px",
     fontWeight: "500", 
     marginLeft: "85px",
     fontSize: "1rem",
     fontFamily: "'Noto Serif', serif"
     //fontFamily: "Roboto"
  },
  centerTextAccurayAnalysis:{
     //marginTop: "-100px", 
     //marginBottom: "0px",
     fontWeight: "400", 
     //marginLeft: "82px",
     fontSize: "1.4rem",
     //fontFamily: "'Noto Serif', serif"
     //fontFamily: "Roboto"
  },
  selected: {
   backgroundColor: "#3f51b5",
   "color": "white !important"},
  ...imagesStyle,
  ...TextStyle
});


class TestResultsDashboard extends React.Component {

constructor(props) {
  super(props);
  this.state = {
    test_id: "",
    test_paper_id: "",
    testMarksData: [],
    testAccuracyData: [] ,
    activeIndex: [] ,
    testResultsData: "",
    subjectAccuracyData: "",
    selectedSubjectIndexForQuestionAnalysis: 0,
    questionAnalysisData: "",
    testPapersData: "",
    fullAnalysisData: "",
    selectedReportInMultiPaperMode: "full"
  };
}

componentDidMount(){
  if(!isUserSessionActive())
  {
    this.props.history.push('/login');
    return
  }

  const test_id = qs.parse(this.props.location.search).test_id;
  const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
  if(!test_id){
    alert("Test not provided.");
    return
  }
  
  this.setState({test_id: test_id,test_paper_id: test_paper_id})

  $.ajax({
        type: "GET",
        url: getBaseURL() + '/get-user-test-papers.json?test_id='+ test_id,
        dataType: "JSON",
      }).done((data) => {
        //TODO Error Handling
        this.setState({testPapersData: data});
      });


  $.ajax({
       type: "GET",
       url: getBaseURL() + '/api/student-test-report.json?scheduled_test_id='+ test_id ,
       dataType: "JSON",
  }).done((data) => {
    //this.populateTestMarksDataForTable(data);
    //this.populateTestAccuracyDataForTable(data);
    this.setState({fullAnalysisData: data});
    //TODO need to add multi paper handiling or showing paper specific results
    //Currently page is hardcoded to first paper of the test
    if(data["paper_results"] && data["paper_results"].length > 1){
      if(test_paper_id){
        var i = 0;
        for(i in data["paper_results"]){
          if(data["paper_results"][i].id == test_paper_id){
            break;
          }
        }
        this.populateSubjectDetailsData(data["paper_results"][i]);
        this.populateQuestionAnalysisData(data["paper_results"][i])
      }else{
        this.populateSubjectDetailsData(data["total_results"]);
        this.setState({ questionAnalysisData: [] });
      }
    }else if(data["paper_results"] && data["paper_results"].length > 0){
      this.populateSubjectDetailsData(data["paper_results"][0]);
      this.populateQuestionAnalysisData(data["paper_results"][0])
    }
    //TODO need to remove hard coding active indexes of all possible subject
    //the list has 8 entries so that all 8 subjects if present will have activeRenderedShape
    setTimeout(function(){
             this.setState({activeIndex: [0,1,2,3,4,5,6,7]})
        }.bind(this),2000);

    //
  });
  //TODO error handling
  
}

populateQuestionAnalysisData(data){
  var questionAnalysisData = []
  var questionWiseData = data["question_wise"]
  

  for(var subIndex = 0; subIndex < questionWiseData.length; subIndex++) {
      var subQ = questionWiseData[subIndex]["q_nos"].split(',');
      var subMarks = questionWiseData[subIndex]["marks"].split(',');
      var subjectQuestions = []
      for(var i = 0; i < subQ.length; i++) {
        subjectQuestions.push({name: subQ[i], Marks: subMarks[i]})
      }
      questionAnalysisData.push(subjectQuestions)
  }
  this.setState({ questionAnalysisData: questionAnalysisData });
}

handleSelectedTab = (event, value) => {
  this.setState({ selectedSubjectIndexForQuestionAnalysis: value });
}

populateSubjectDetailsData(data){
  var testResultsData = {}
  var subjectAccuracyData ={}
  var subjDetailedData = [];
  var subjectsInfo =  (data.subjects_info);
  const totalStudents = data.no_of_students
  const totalAwardedMarks = data.total_marks;
  var subjectData = "" 
  for (var subj in subjectsInfo){
      subjectData = subjectsInfo[subj]
      var subjTotalMarks = data.sub_template_data[subj].sub_total_marks
      var subjTotalQuestions = data.sub_template_data[subj].sub_total_questions
      subjDetailedData.push({name: subj, 
                awarded_marks: subjectData["marks"],
                total_marks: subjTotalMarks,
                percentage: parseInt(this.calcPercentage(parseFloat(subjectData["marks"]),parseFloat(totalAwardedMarks))),
                rank: subjectData["rank"],
                total_students: totalStudents,
                percentile: this.calcPercentile(parseInt(subjectData["rank"]),parseInt(totalStudents)),
                correct_questions: subjectData["correct"],
                incorrect_questions: subjectData["incorrect"],
                unattempted_questions: subjectData["unattempts"],
                total_questions: subjTotalQuestions,
                accuracy: this.calcAccuracy(parseInt(subjectData["correct"]),parseInt(subjTotalQuestions),parseInt(subjectData["unattempts"]))
                });

      subjectAccuracyData[subj] = [
        {name: "Correct", value: parseInt(subjectData["correct"])},
        {name: "Incorrect", value: parseInt(subjectData["incorrect"])},
        {name: "Unattempted", value: parseInt(subjectData["unattempts"])},
      ]
    } 
    testResultsData["subjects_info"] = subjDetailedData;
    testResultsData["awarded_marks"] = data.total_marks;
    testResultsData["total_marks"] = data.test_marks;
    //testResultsData["percentage"] = this.calcPercentage(parseFloat(data.total_marks),parseFloat(totalAwardedMarks));
    testResultsData["rank"] = data.rank;
    testResultsData["total_students"] = totalStudents;
    testResultsData["percentile"] = parseInt(this.calcPercentile(parseInt(data.rank),parseInt(totalStudents)));
    testResultsData["correct_questions"] = data.correct;
    testResultsData["incorrect_questions"] = data.incorrect;
    testResultsData["unattempted_questions"] = data.unattempted;
    testResultsData["total_questions"] = data.total_questions;
    testResultsData["accuracy"] = this.calcAccuracy(parseInt(data.correct),parseInt(data.total_questions),parseInt(data.unattempted));

    this.setState({testResultsData: testResultsData, subjectAccuracyData: subjectAccuracyData});
}

populateTestMarksDataForTable(data){
  var subjMarksData = [];
    var subjectsInfo =  (data.subjects_info);
    //data.
    var subjectData = ""
    var totalStudents = data.no_of_students

    for (var subj in subjectsInfo){
      subjectData = data.subjects_info[subj]
      var subjTotalMarks = data.sub_template_data[subj].sub_total_marks 
      subjMarksData.push([subj, subjectData["marks"] +"/"+ subjTotalMarks,
                this.calcPercentage(parseFloat(subjectData["marks"]),
                parseFloat(subjTotalMarks)),subjectData["rank"] + "/" + totalStudents ,
                this.calcPercentile(parseInt(subjectData["rank"]),parseInt(totalStudents))]);
    }
    subjMarksData.push(["OVERALL", data.total_marks +"/"+ data.test_marks,
                this.calcPercentage(parseFloat(data.total_marks),
                parseFloat(data.test_marks)),data.rank + "/" + totalStudents,
                this.calcPercentile(parseInt(data.rank),parseInt(totalStudents))]);
    this.setState({testMarksData: subjMarksData});
}

populateTestAccuracyDataForTable(data){
  var accuracyData = [];
    var subjectsInfo =  (data.subjects_info);
    //data.
    var subjectData = ""
    var totalStudents = data.no_of_students
    for (var subj in subjectsInfo){
      subjectData = data.subjects_info[subj]
      var subjTotalQuestions = data.sub_template_data[subj].sub_total_questions 
      accuracyData.push([subj, subjectData["correct"] +"/"+ subjTotalQuestions,
                subjectData["incorrect"] +"/"+ subjTotalQuestions,
                subjectData["unattempts"] +"/"+ subjTotalQuestions,
                this.calcAccuracy(parseInt(subjectData["correct"]),parseInt(subjTotalQuestions),parseInt(subjectData["unattempts"]))]);
    }
    accuracyData.push(["OVERALL", data.correct +"/"+ data.total_questions,
                data.incorrect +"/"+ data.total_questions,
                data.unattempted +"/"+ data.total_questions,
                this.calcAccuracy(parseInt(data.correct),parseInt(data.total_questions),parseInt(data.unattempted))]);
    this.setState({testAccuracyData: accuracyData});
}

calcPercentage(value, totalValue){
  if (value < 0){
    value = 0
  }
  if(totalValue <= 0){
    return 0
  }
  const percent = parseFloat(100*(value/totalValue)).toFixed(2);
  if(percent >= 0){
    return percent
  } else{
    return 0
  }
}

calcPercentile(value, totalValue){
  return parseFloat(100*((totalValue-value+1)/totalValue)).toFixed(2);
}

calcAccuracy(correctQ, totalQ, unattemptQ){
  if(totalQ-unattemptQ ==0){
    return 0
  }
  return parseFloat(100*(correctQ/(totalQ-unattemptQ))).toFixed(2);
}

goToTestHome = (e) =>{
    this.props.history.push('/home/enterprise/testHome?test_id=' + e.currentTarget.id); //TODO error handling
}

toggleFullTestAnalysis = (e) =>{
  e.preventDefault();
  const fullAnalysisData = this.state.fullAnalysisData;
  this.populateSubjectDetailsData(fullAnalysisData["total_results"]);
  this.setState({ questionAnalysisData: [], selectedReportInMultiPaperMode: "full" });
  this.props.history.push('/home/analytics/test/results?test_id='+this.state.test_id);
}

toggleTestPaperAnalysis = (e) =>{
  e.preventDefault();
  const comp = e.currentTarget.id.split("_");
  const test_paper_id = comp[0];
  this.setState({selectedReportInMultiPaperMode: test_paper_id})
  const index = comp[1];
  const fullAnalysisData = this.state.fullAnalysisData;
  if(fullAnalysisData["paper_results"] && fullAnalysisData["paper_results"].length > index)
  {
    this.populateSubjectDetailsData(fullAnalysisData["paper_results"][index]);
    this.populateQuestionAnalysisData(fullAnalysisData["paper_results"][index])
  }
  this.props.history.push('/home/analytics/test/results?test_id='+this.state.test_id + '&test_paper_id='+test_paper_id);
}

  render () {
    const { classes } = this.props;
    const { test_id,test_paper_id,testPapersData,testModelInfo, testModelTotalQuestions, testModelTotalMarks,testMarksData, 
      testResultsData,subjectAccuracyData,
      selectedSubjectIndexForQuestionAnalysis,
      questionAnalysisData,selectedReportInMultiPaperMode } = this.state;
    const titleStyle={
        color: "rgb(34, 56, 69)",
        fontWeight: "600",
        fontSize: "20px"
    };
    const subtitleStyle={
        color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif"
    };
    const testMarksColumns = [
    "Subjects",
    "Marks",
    "Percentage",
    "Rank",
    "Percentile"
    ];
    const testAccuracyColumns = [
    "Subjects",
    "Correct Questions",
    "Incorrect Questions",
    "Unattempted Questions",
    "Accuracy"
    ];
    const options = {
      filter: false,
      selectableRows: 'none',
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      pagination: false,
      responsive: 'stacked',
      setRowProps: (row, rowIndex) => {
            if(row[0] == "OVERALL"){
              return {
                style: {textAlign: "center", backgroundColor: "#f4f5f7"}
              };
            } else{
              return {style: {textAlign: "center"}};
            }
          },
    }

    return (
      <React.Fragment>
        <div className={classes.mainContainer}>
        <GridContainer style={{
        backgroundColor: "white",
        width: "100%",
        margin: "0px",
        padding: "5px"
      }}
        spacing={24} >
          <GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h4 style={{marginRight: "15px", marginLeft: "15px", fontWeight: "500", fontSize: "1.4em"}} className={classNames(classes.robotoFontBold,classes.inlineStyle)} >Test Analysis</h4>
            <div style={{float:"right", marginTop: "18px", display: "flex"}} className={classNames(classes.inlineStyle)}>
            <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >{testPapersData && testPapersData["test_data"] ? testPapersData["test_data"].name : null}</p> 
            <p className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >|</p>
            <p style={{fontSize: "0.8rem"}}className={classNames(classes.robotoFontRegular,classes.inlineStyle)} >Total Papers: <strong>{testPapersData && testPapersData["test_data"] ? testPapersData["test_data"].total_papers : null}</strong></p>
            {testPapersData && testPapersData["test_papers"] && testPapersData["test_papers"].length > 1 ?
              <React.Fragment>
              <Button
                  color="transparent"
                  size="sm"
                  style={selectedReportInMultiPaperMode == "full"? 
                  {textTransform: "none", 
                  backgroundColor: "#4caf50", color: "white", margin: "0px", marginLeft: "20px",
                  verticalAlign: "top"}
                  :{textTransform: "none", 
                  backgroundColor: "#57849b", color: "white", margin: "0px", marginLeft: "20px",
                  verticalAlign: "top"}}
                  onClick={this.toggleFullTestAnalysis}
                  >
                  View Overall Analysis
                </Button>
              {testPapersData["test_papers"].map((testPaper, index) =>(
                <Button
                  key={index}
                  color="transparent"
                  id={testPaper.id+"_"+index}
                  size="sm"
                  style={selectedReportInMultiPaperMode == testPaper.id ?
                  {textTransform: "none", 
                  backgroundColor: "#4caf50", color: "white", margin: "0px", marginLeft: "20px",
                  verticalAlign: "top"}
                  :{textTransform: "none", 
                  backgroundColor: "#57849b", color: "white", margin: "0px", marginLeft: "20px",
                  verticalAlign: "top"}}
                  onClick={this.toggleTestPaperAnalysis}
                  >
                  View Paper {testPaper.paper_no} Analysis
                </Button>
              ))}
              </React.Fragment>
            : null
            } 
            <Button
              color="transparent"
              id={test_id}
              size="sm"
              style={{textTransform: "none", 
              backgroundColor: "#57849b", color: "white", margin: "0px", marginLeft: "20px",
              verticalAlign: "top"}}
              onClick={this.goToTestHome}
              >
              Done
              </Button>
            </div>
            {/*<hr className={classes.lineBreak} style={{margin: "0px"}}/>*/}
          </GridItem>
          {/*<GridItem  xs={12} sm={12} md={7} >
          
            <MUIDataTable
              title={<p style={subtitleStyle} >Test Results Table</p>}
              data={this.state.testMarksData}
              columns={testMarksColumns}
              options={options}
            />
          </GridItem>
          <GridItem  xs={12} sm={12} md={6} >
          </GridItem>
          <GridItem  xs={12} sm={12} md={7} >
          
            <MUIDataTable
              title={<p style={subtitleStyle} >Test Accuracy Table</p>}
              data={this.state.testAccuracyData}
              columns={testAccuracyColumns}
              options={options}
            />
          </GridItem>*/}
        </GridContainer>
        <GridContainer style={{
        //backgroundColor: "white",
        width: "100%",
        margin: "0px",
        marginTop: "10px",
        //padding: "5px"
      }}
        spacing={24} >
          <GridItem xs={12} sm={12} md={3} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Marks</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["awarded_marks"]}</h1>
                    <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_marks"]} Marks</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                    <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.awarded_marks}</strong></h1>
                    ))}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Rank</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["rank"]}</h1>
                    <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_students"]} Participants</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                     {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                    <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.rank}</strong></h1>
                    ))}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Questions: Correct</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["correct_questions"]}</h1>
                    <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_questions"]} Questions</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                    <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.correct_questions}</strong></h1>
                    ))}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Questions: Incorrect</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h1 className={classNames(classes.robotoFontBold)}>{testResultsData["incorrect_questions"]}</h1>
                    <h1 style={{fontSize: "0.8rem", marginTop: "0px"}} className={classNames(classes.robotoFontRegular)}>of {testResultsData["total_questions"]} Questions</h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, index) => (
                    <h1 style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={index > 0 ? classNames(classes.robotoFontRegular, classes.zeroMarginTop) :classNames(classes.robotoFontRegular)}>{ subjectData.name ? subjectData.name.toLowerCase() : ""}: <strong>{subjectData.incorrect_questions}</strong></h1>
                    ))}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer style={{
        //backgroundColor: "white",
        width: "100%",
        margin: "0px",
        marginTop: "10px",
        //padding: "5px"
      }}
        spacing={24} >
          {/*<GridItem xs={12} sm={12} md={6}>
            <MUIDataTable
                title={<p style={subtitleStyle} >Test Results Table</p>}
                data={this.state.testMarksData}
                columns={testMarksColumns}
                options={options}
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <MUIDataTable
                title={<p style={subtitleStyle} >Test Accuracy Table</p>}
                data={this.state.testAccuracyData}
                columns={testAccuracyColumns}
                options={options}
              />
          </GridItem>*/}
          { testResultsData["awarded_marks"] &&  testResultsData["awarded_marks"] > 0 ?
          <GridItem xs={12} sm={12} md={5} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Subject Distribution</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
                <GridContainer style={{ margin: "auto", marginTop: "25px"}}>
                <GridItem xs={12} sm={12} md={4}>
                  <PieChart width={450} height={220}
                  style={{display:"inline-block"}}
                  margin={{
                      top: 50, right: 0, left: 190, bottom: 10,
                    }}>
                      <Pie dataKey="percentage"  
                      data={   testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].length > 0  
                      ? testResultsData["subjects_info"] : null} 
                      cx={50} 
                      cy={55} 
                      outerRadius={50}
                      paddingAngle={5}
                      fill="#8884d8" 
                      activeIndex={this.state.activeIndex}
                      activeShape={renderActiveShape} >
                         {
                          testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].length > 0 
                          ? testResultsData["subjects_info"].map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                          : null }
                        </Pie>
                      {/*<Legend layout='vertical' align='right' verticalAlign='middle'
                      />*/}
                      <Tooltip />
                  </PieChart>
              </GridItem>
              </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          : null}
      <GridItem xs={12} sm={12} md={7} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Accuracy Analysis</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle} >
                <GridContainer style={{ margin: "auto", marginTop: "0px"}}>
                  {/*<GridItem xs={12} sm={12} md={2}> 
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}> 
                    <h1 style={{textAlign: "left",fontWeight: "600"}} className={classNames(classes.robotoFontRegular)} ></h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}> 
                  </GridItem>*/}
                  {/*<GridItem xs={12} sm={12} md={1}> 
                  </GridItem>*/}
                  {testResultsData && testResultsData["subjects_info"] &&
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{position: "relative"}}>
                    <div class="recharts-legend-wrapper"
                      style={{position: "absolute", width: "600px", height: "auto", right: "0px", top: "10px"}}>
                      <ul class="recharts-default-legend" 
                        style={{padding: "0px", margin: "0px", textAlign: "right"}}>
                        <li class="recharts-legend-item legend-item-0" 
                          style={{display: "inline-block", marginRight: "10px"}}>
                          <svg class="recharts-surface" width="12" height="12" 
                          viewBox="0 0 32 32" version="1.1" 
                          style={{display: "inline-block", verticalAlign: "middle", 
                                marginRight: "4px"}}>
                          <path fill="#028f4e" class="recharts-symbols" 
                          transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0">
                          </path>
                          </svg>
                          <span class="recharts-legend-item-text">Correct</span>
                        </li>
                        <li class="recharts-legend-item legend-item-1" 
                          style={{display: "inline-block", marginRight: "10px"}}>
                          <svg class="recharts-surface" width="12" height="12" 
                          viewBox="0 0 32 32" version="1.1" 
                          style={{display: "inline-block", verticalAlign: "middle", 
                                marginRight: "4px"}}>
                          <path fill="#F44336" class="recharts-symbols" 
                          transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0">
                          </path>
                          </svg>
                          <span class="recharts-legend-item-text">Incorrect</span>
                        </li>
                          <li class="recharts-legend-item legend-item-2" 
                          style={{display: "inline-block", marginRight: "10px"}}>
                          <svg class="recharts-surface" width="12" height="12" 
                          viewBox="0 0 32 32" version="1.1" 
                          style={{display: "inline-block", verticalAlign: "middle", 
                                marginRight: "4px"}}>
                          <path fill="#7c7c7ca6" class="recharts-symbols" 
                          transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0">
                          </path>
                          </svg>
                          <span class="recharts-legend-item-text">Unattempted</span>
                        </li>
                      </ul>
                    </div>
                    </div>
                  </GridItem>
                  }
                  {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subjectData, subIndex) => (
                  <GridItem xs={12} sm={12} md={4}> 
                    <PieChart width={testResultsData["subjects_info"].length ==1 ? 300 : 200} height={220} onMouseEnter={this.onPieEnter}
                  
                    margin={{
                        top: 10, right: 0, left: 0, bottom: 0,
                      }}>
                        <Pie
                          data={subjectAccuracyData && subjectAccuracyData[subjectData.name] ? (subjectAccuracyData[subjectData.name]) : null}
                          cx={100}
                          cy={/*subIndex == (testResultsData["subjects_info"].length-1) ? 95 :*/ 120}
                          innerRadius={40}
                          outerRadius={55}
                          fill="#8884d8"
                          paddingAngle={2}
                          dataKey="value"
                          labelLine={false}
                          label={renderCustomizedLabel1}
                        >
                          { subjectAccuracyData && subjectAccuracyData[subjectData.name] ?
                             subjectAccuracyData[subjectData.name].map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />)
                          : ""}
                          <Label className={classNames(classes.centerTextAccurayAnalysis)} value={Math.round(subjectData.accuracy)+"%"} offset={0} position="center" />
                        </Pie>
                         <Tooltip />
                        {/*   ((subIndex == (testResultsData["subjects_info"].length-1)) || subIndex == 2) && subIndex < 3 ?
                           <Legend width={ subIndex > 2 ? 400 : subIndex == 0 ? 300 : 200*(subIndex+1)} iconType="circle" iconSize={12} align='right' verticalAlign='top' />
                        : null */}
                       
                    </PieChart>
                   {/* <p  className={classNames(classes.centerTextAccurayAnalysis)}>{Math.round(subjectData.accuracy)}%</p>
                   */}<p  className={classNames(classes.subTextAccurayAnalysis)} >{subjectData.name.substring(0, 3)}</p>                  
                   </GridItem>
                   ))}
                  {/*<GridItem xs={12} sm={12} md={1}> 
                   </GridItem>*/}
              </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
        {questionAnalysisData && questionAnalysisData.length > 0 &&
          <GridItem xs={12} sm={12} md={12} className={classes.clipContainerStyle}>
            <GridContainer direction="column" className={classes.clipStyle}>
              <GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
                <h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Questions Analysis</h1>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle} style={{padding:"20px"}}>
              <Tabs
                value={selectedSubjectIndexForQuestionAnalysis}
                onChange={this.handleSelectedTab}
                indicatorColor="primary"
                textColor="primary"
                fullWidth
              >

              {testResultsData && testResultsData["subjects_info"] && testResultsData["subjects_info"].map((subject, index) => (
                <Tab key={index} label={subject.name} 
                style={{maxWidth: "100px", fontSize: "13px",
                  boxShadow: "0px 2px 10px 0px rgba(153, 153, 153, 0.2), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.2)",
                  padding:"6px 24px", margin: "5px"}}
                  classes={{
                      selected: classes.selected
                    }}
                />
              ))} 
                
                </Tabs>
                <BarChart width={1200} height={300} data={questionAnalysisData[selectedSubjectIndexForQuestionAnalysis]}
                            margin={{top: 50, right: 30, left: 20, bottom: 25}}>
                 <CartesianGrid strokeDasharray="3 3"/>
                 <XAxis dataKey="name"/>
                 <YAxis/>
                 <Tooltip/>
                 <Legend align="center" style={{marginTop: "10px"}}/>
                 <ReferenceLine y={0} stroke='#000'/>
                 <Bar dataKey="Marks" fill="#8884d8" />     
              </BarChart>
              </GridItem>
            </GridContainer>
          </GridItem>
        }
        </GridContainer>
        </div>
      </React.Fragment>
    );
  }
}

export default  withStyles(styles)(TestResultsDashboard);
