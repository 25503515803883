/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox(props) {
  const { options, onChange, width, label, optionLabel,variant, value,onInputChange,required,error,helperText} = props;
  return (
    variant == "controlled" ?
    <Autocomplete
      value={value}
      options={options}
      onChange={onChange}
      getOptionLabel={optionLabel ? optionLabel : (option) =>  option.name}
      style={{ width: width ? width : 300, maxWidth: "100%" }}
      renderInput={(params) => <TextField  {...params} required={required} error={error} helperText={helperText} style={{backgroundColor: "white"}} label={label? label:"Search"} variant="outlined" />}
    />
    :
    <Autocomplete
      options={options}
      onInputChange={onInputChange}
      onChange={onChange}
      getOptionLabel={optionLabel ? optionLabel : (option) =>  option.name}
      style={{ width: width ? width : 300, maxWidth: "100%" }}
      renderInput={(params) => <TextField  {...params} required={required} error={error} helperText={helperText} style={{backgroundColor: "white"}} label={label? label:"Search"} variant="outlined" />}
    />
    
  );
}


