import React from "react";
import ReactDOM from "react-dom";
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
import SingleAnswerOptions from "components/test/view/SingleAnswerOptions.jsx";
import Button from '@material-ui/core/Button';

export default class MetaReadDataListView extends React.Component {

	componentDidMount(){
	  if(window.MathJax)
	  window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
	}

	componentDidUpdate(){
	  if(window.MathJax)
	  	window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
	}

	render(){
		const {projectFragments,handleFetch} = this.props;
		return(
			<React.Fragment>
      <div>
			{projectFragments && projectFragments.map((projectFragment, index) => 
                        <React.Fragment>
                          {projectFragment && projectFragment["content"] && (projectFragment["content"]["question"] || projectFragment["content"]["options"]) &&
                              <div style={{
                                border: "1px solid rgb(247,247,247)",
                                padding: "5%",
                                //marginTop: this.state.offsetHeightForData
                              }}>
                             
                              {projectFragment["content"]["question"] && projectFragment["content"]["question"].map((item, index) => (
                                <React.Fragment key={index}>
                                {item[0] ? 
                                  <div>
                                  <QuestionText text={item[0]}/>
                                  </div>
                                : null}
                                { item[1] ?
                                  <QuestionImages 
                                  src={item[1]} 
                                  name={"question_"+index+"_1"}
                                  isEditMode={false}
                                  
                                  />
                                : null
                                }
                                </React.Fragment>
                                ))
                              }
                              { projectFragment["content"]["options"] && 
                                  <SingleAnswerOptions
                                  dontShowLabel
                                  answer={""} 
                                  options={projectFragment["content"]["options"]} 
                                  isEditMode={false}
                                  noImageAttachment={true}
                                  />
                              }
                               <Button 
                                color="primary"
                                name={"fetch_"+ index}
                                onClick={handleFetch}
                                style={{ color: "white", backgroundColor: "#3f51b5", marginLeft: "auto", display: "block", marginRight: "2%", marginBottom: "8px"}} 
                                size="small"
                              >
                                Fetch
                              </Button>
                              </div>  
                          }
                          </React.Fragment>
                        )}
      </div>
			</React.Fragment>
			);
	}
}