import React, { useState } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function MuiTable(props) {
  console.log(props)
  const [responsive, setResponsive] = useState("simple");

  const columns = props.columns;

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: 'none',
    print: 'false',
    sort: props.sort == "false" ? false : true,
    downloadOptions: {filename: (props.downloadTitle ? props.downloadTitle : props.title)+".csv"},
    responsive,
  };

  const data = props.rows;

  return (
    <React.Fragment>
      <MUIDataTable
        title={props.title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

