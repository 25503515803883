import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import CloudDownload from '@material-ui/icons/CloudDownload';

const styles = theme => ({
  root: {
    flexGrow: 1,
    //maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px'
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
});

function generate(element) {
  return [0].map(value =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

class InteractiveList extends React.Component {
  state = {
    dense: false,
    secondary: true,
  };

  render() {
    const { classes,selectedListIndex, onSelectedListChange } = this.props;
    const { dense, secondary } = this.state;
    const subtitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px"
    };
    return (
      <div className={classes.root}>
        {/*<FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={dense}
                onChange={event => this.setState({ dense: event.target.checked })}
                value="dense"
              />
            }
            label="Enable dense"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={secondary}
                onChange={event => this.setState({ secondary: event.target.checked })}
                value="secondary"
              />
            }
            label="Enable secondary text"
          />
        </FormGroup>*/}
        <Grid container spacing={16}>
         <Grid item  xs={12} sm={12} md={1}  style={{padding: "0px 8px"}}>
         </Grid>
          <Grid item  xs={12} sm={12} md={11}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h3 style={subtitleStyle} >Test Reports</h3>
          </Grid>
          <Grid item xs={12} md={1} style={{padding: '8px'}}>
          </Grid>
          <Grid item xs={12} md={3}>
           {/* <Typography variant="h6" className={classes.title}>
              Reports
            </Typography>*/}
            <div className={classes.demo}>
              <List dense={dense}>
                {generate(
                  <ListItem button id={0} onClick={onSelectedListChange}
                    style={selectedListIndex == 0 ? {backgroundColor: "rgba(190, 190, 190, 0.3)"} : {}}>
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Test Performance"
                      style={{color: 'rgba(0,0,0,0.87)'}}
                      secondary={secondary ? 'Marks and Ranks list' : null}
                    />
                    {/*<ListItemSecondaryAction>
                      <IconButton aria-label="Delete">
                        <CloudDownload />
                      </IconButton>
                    </ListItemSecondaryAction>*/}
                  </ListItem>,
                )}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} md={3} >
            {/*<Typography variant="h6" className={classes.title}>
              Reports - II
            </Typography>*/}
            <div className={classes.demo}>
              <List dense={dense}>
                {generate(
                  <ListItem button id={1} onClick={onSelectedListChange}
                  style={selectedListIndex == 1 ? {backgroundColor: "rgba(190, 190, 190, 0.3)"} : {}}>
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Error Analysis"
                      style={{color: 'rgba(0,0,0,0.87)'}}
                      secondary={secondary ? 'Incorrect questions list' : null}
                    />
                   {/* <ListItemSecondaryAction>
                      <IconButton aria-label="Delete">
                        <CloudDownload />
                      </IconButton>
                    </ListItemSecondaryAction>*/}
                  </ListItem>,
                )}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {/*<Typography variant="h6" className={classes.title}>
              Reports - II
            </Typography>*/}
            <div className={classes.demo}>
              <List dense={dense}>
                {generate(
                  <ListItem button id={2} onClick={onSelectedListChange}
                  style={selectedListIndex == 2 ? {backgroundColor: "rgba(190, 190, 190, 0.3)"} : {}}>
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Unattempted Students"
                      style={{color: 'rgba(0,0,0,0.87)'}}
                      secondary={secondary ? 'Students list' : null}
                    />
                   {/* <ListItemSecondaryAction>
                      <IconButton aria-label="Delete">
                        <CloudDownload />
                      </IconButton>
                    </ListItemSecondaryAction>*/}
                  </ListItem>,
                )}
              </List>
            </div>
          </Grid>
           <Grid item xs={12} md={1} style={{padding: '8px'}}>
          </Grid>
        </Grid>
      </div>
    );
  }
}

InteractiveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InteractiveList);