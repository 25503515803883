import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import ManageTests from  'components/test/ManageTests'
import ManageSchedules from  'components/schedule/ManageSchedules'
import LoginPage from 'components/LoginPage';
import AppLayout from 'components/AppLayout';
import TestForm from  'components/test/TestForm';
import ScheduleTestForm from  'components/schedule/ScheduleTestForm';
import TestModelForm from 'components/TestModelForm';
import TestAnalysisDashboard from 'components/analysis/TestAnalysisDashboard';
import UserTestAnalysisDashboard from 'components/analysis/UserTestAnalysisDashboard';
import TestPreviewRegion from 'components/test/view/TestRegion';
import ImportTest from 'components/test/ImportTest';
import OverallAnalysisRegion from 'components/analysis/OverallAnalysisRegion';

import DatasetMain from 'components/dataset/DatasetMain';

//import UserLoginPage from "user_components/components/LoginPage.jsx";
import UserLandingPage from "user_components/components/UserLandingPage.jsx"; 
import RegistrationLandingPage from "user_components/components/enterprise/registration/RegistrationLandingPage.jsx"; 
import UserRegistration from "user_components/components/user/UserRegistration.jsx"

import UserAnalysisDashboard from 'components/analysis/UserAnalysisDashboard';

import ImportDataset from 'components/dataset/upload/ImportDataset';

import MetaReadHome from 'metaread/MetaReadHome';
import MetaReadDoubts from 'metaread/MetaReadDoubts';
import MetaReadDiagrams from 'metaread/MetaReadDiagrams';
import MetaReadContentStore from 'metaread/MetaReadContentStore';

import ScheduleTestLiveUsers from 'components/schedule/ScheduleTestLiveUsers';

//import "assets/scss/material-kit-react.css?v=1.1.0";
import ResetPassword from 'components/ResetPassword';

//import Super Admin Components
import ManageUsers from 'components/admin/ManageUsers';
import ManageUserGroups from 'components/admin/ManageUserGroups';
import ManageBranches from 'components/admin/ManageBranches';
import ManageSections from 'components/admin/ManageSections';
import ManagePrograms from 'components/admin/ManagePrograms';
import ManageTestTypes from 'components/admin/ManageTestTypes';
import ManageSubjects from 'components/admin/ManageSubjects';
import CreateNewUser from 'components/admin/CreateNewUser';
import UserMapping from 'components/admin/UserMapping';
import AdminAppLayout from 'components/AdminAppLayout';

//import Analyst Components
import AnalystAppLayout from 'components/analyst/AnalystAppLayout';
import AnalystHome from 'components/analyst/AnalystHome';
import AnalystTestHome from 'components/analyst/AnalystTestHome';
import AnalystTestMarks from 'components/analyst/reports/AnalystTestMarks';
import AnalystTestAttendance from 'components/analyst/reports/AnalystTestAttendance';
import AnalystTestSubjectSections from 'components/analyst/reports/AnalystTestSubjectSections';
import AnalystTestToppers from 'components/analyst/reports/AnalystTestToppers';

import AnalystStudentHome from 'components/analyst/AnalystStudentHome';
import AnalystStudentReport from 'components/analyst/reports/AnalystStudentReport';




const App = (props) => (

  <Router>
    <div>
      	<Route exact path='/' 
	      	render={(routeProps) => (
	    		<LoginPage {...routeProps}/>
	  	 	)}  />
      	<Route exact path='/manage-tests' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ManageTests {...routeProps}/>} />
	  	 	)}  />
  	 	<Route exact path='/test_infos/new' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/schedule-tests' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ManageSchedules {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/scheduled_tests/new' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ScheduleTestForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test-live-users' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ScheduleTestLiveUsers {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test_template_infos' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestModelForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/total-test-analysis' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test-paper-analysis' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/student-test-dashboard' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserTestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test_questions/new' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<TestPreviewRegion {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/offline_test' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} yieldComponent={<ImportTest {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/overall_analysis' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} yieldComponent={<OverallAnalysisRegion {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/student-analysis-dashboard' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	 <Route exact path='/home/analytics/myanalysis' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserAnalysisDashboard role="student" {...routeProps}/>} />
	  	 	)}  />
	  	 {/*ROUTES FOR REACT - USER ACCESS -- STARTS HERE*/}
	  	 <Route exact path='/login' 
	      	render={(routeProps) => (
	    		<LoginPage {...routeProps}/> //UserLoginPage deprecated
	  	 	)}  />
	  	 <Route exact path='/reset-password' 
	      	render={(routeProps) => (
	    		<ResetPassword {...routeProps}/> 
	  	 	)}  />		
	  	 <Route path='/home' 
	      	render={(routeProps) => (
	    		<UserLandingPage {...routeProps}/>
	  	 	)}  />
	  	 {/*<Route exact path='/register' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserRegistration {...routeProps}/>} />
	  	 	)}  />*/}
	  	 <Route exact path='/registration/testparticipant/upload/bulk' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<RegistrationLandingPage {...routeProps}/>} />
	  	 	)}  />
	  	 {/*ROUTES FOR REACT - USER ACCESS -- ENDS HERE*/}
	  	 {/*ROUTES FOR REACT - DATASET ACCESS -- STARTS HERE*/}
	  	 <Route exact path='/dataset/preview' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<DatasetMain {...routeProps}/>} />
	  	 	)}  />	
	  	 <Route exact path='/dataset/preview/sample' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true}  yieldComponent={<DatasetMain hideTestHeader={true} {...routeProps}/>} />
	  	 	)}  />
	  	 <Route exact path='/import_dataset' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<ImportDataset {...routeProps}/>} />
	  	 	)}  />	
	  	 {/*ROUTES FOR REACT - DATASET ACCESS -- ENDS HERE*/}

	  	{/*ROUTES FOR REACT - METAREAD ACCESS -- STARTS HERE*/}
  		 <Route exact path='/metaread/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={false} hideAppBar={true} yieldComponent={<MetaReadHome {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/doubts/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<MetaReadDoubts {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/diagrams/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<MetaReadDiagrams {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/metaread/store' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={false} hideAppBar={true} yieldComponent={<MetaReadContentStore {...routeProps}/>} />
  		 	)}  />	
	  	{/*ROUTES FOR REACT - METAREAD ACCESS -- ENDS HERE*/}

	  	<Route exact path='/manage-users' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageUsers {...routeProps}/>} />
  		 	)}  />
  
  	  <Route exact path='/manage-user-groups' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageUserGroups {...routeProps}/>} />
  		 	)}  />
  	  <Route exact path='/manage-branches' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageBranches {...routeProps}/>} />
  		 	)}  />
  	  <Route exact path='/manage-sections' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageSections {...routeProps}/>} />
  		 	)}  />
  	  <Route exact path='/manage-programs' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManagePrograms {...routeProps}/>} />
  		 	)}  />
  	  <Route exact path='/manage-test-types' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageTestTypes {...routeProps}/>} />
  		 	)}  />
  	  <Route exact path='/manage-subjects' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<ManageSubjects {...routeProps}/>} />
  		 	)}  />

  	   <Route exact path='/create-new-user' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<CreateNewUser {...routeProps}/>} />
  		 	)}  />

  	   <Route exact path='/user-group-mappings' 
  	    	render={(routeProps) => (
  	    	<AdminAppLayout {...routeProps} hideNavigator={false} hideAppBar={false} yieldComponent={<UserMapping {...routeProps}/>} />
  		 	)}  />

  	    {/*ROUTES FOR REACT - ANALYST ACCESS -- STARTS HERE*/}
  	    <Route exact path='/analysis' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystHome {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/analysis/test' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystTestHome {...routeProps}/>} />
  		 	)}  />
  		  <Route exact path='/analysis/test/marks' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystTestMarks {...routeProps}/>} />
  		 	)}  />
  		  <Route exact path='/analysis/test/attendance' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystTestAttendance {...routeProps}/>} />
  		 	)}  />
  		  <Route exact path='/analysis/test/subjects/sections' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystTestSubjectSections {...routeProps}/>} />
  		 	)}  />
  		  <Route exact path='/analysis/test/toppers' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystTestToppers {...routeProps}/>} />
  		  )}  />
  		   <Route exact path='/analysis/student' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystStudentHome {...routeProps}/>} />
  		 	)}  />
  		 	<Route exact path='/analysis/student/report' 
  	    	render={(routeProps) => (
  	    	<AnalystAppLayout {...routeProps} yieldComponent={<AnalystStudentReport {...routeProps}/>} />
  		 	)}  />

  	    {/*ROUTES FOR REACT - ANALYST ACCESS -- ENDS HERE*/}

    </div>
  </Router>
)
export default App;