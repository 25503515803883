import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import {  withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import qs from 'query-string';
import Link from '@material-ui/core/Link';
import StudentCard from "../core/StudentCard.jsx"
import MuiTable from '../core/MuiTable.jsx';
import {formatDateWithoutTime} from "components/helpers/DateTimeHelper.jsx";


const styles = ((theme) => ({
	section:{
  		padding: "16px 0",
      [theme.breakpoints.down('sm')]: {
       padding: "16px 0"
      },
  	},
	backLink:{
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
  
  }));

class AnalystStudentReport extends Component {

	state = {
		student_details: "",
		errorMessages: [],
		rows: [],
		columns: []
	}

	componentDidMount(){
		const student_id = qs.parse(this.props.location.search).student_id; 
		this.getStudentReport(student_id);
  	}

  	getStudentReport(student_id){
  		if(student_id){
	  		$.ajax({
	  		  type: 'GET',
	  		  url: getBaseURL() + '/analysis/student/full-report.json?student_id='+student_id,
	  		}).done((data, status, jqXHR)=>{
	  			this.processDataForStudentReport(data);
	  			this.setState({student_details: data["student_details"]})
	  		}).fail((jqXHR, textStatus, errorThrown) => {
	  		  //TODO: handle properly
	  		    var errorMessages = this.state.errorMessages;
	  		    errorMessages.push({message: jqXHR["responseJSON"].message})
	  		    this.setState({errorMessages: jqXHR["responseJSON"].message});
	  		})
  		}
  	}

  	processDataForStudentReport(data){
  		const allResults = data["results"];
  		const subjectCols = data["subject_codes"];
  		var columns = ["Test","Date"]
  		columns.push(...subjectCols)
  		columns.push("Total", "Section Rank", "Campus Rank", "Overall Rank");
  		
  		var rows = [];
  		console.log(allResults)
  		for(let testResult of allResults){
  			var row = []
  			row.push(testResult["test_name"]);
  			row.push(formatDateWithoutTime(testResult["start_time"]));
			var subRowData = Array(subjectCols.length).fill('-');			
  			for(let subjectResult of testResult["subject_results"]){
  				
  				var subIndx = subjectCols.indexOf(subjectResult["sub_code"])
  				if ( subIndx >= 0){
  					subRowData[subIndx] = subjectResult["total_marks"]
  					if(subjectResult["full_marks"]){
  						subRowData[subIndx] = subjectResult["total_marks"]+ "/"+subjectResult["full_marks"]
  					}
  				}
  			}
  			row.push(...subRowData);
  			
  			if(testResult["full_marks"]){
				row.push(testResult["total_marks"]+ "/"+testResult["full_marks"])
			}else{
				row.push(testResult["total_marks"])
			}
  			if(testResult["section_rank"] && testResult["total_section_students"]){
  				row.push(testResult["section_rank"]+"/"+testResult["total_section_students"]);
  			}else{
  				row.push("-")
  			}
  			if(testResult["branch_rank"] && testResult["total_branch_students"]){
  				row.push(testResult["branch_rank"]+"/"+testResult["total_branch_students"]);
  			}else{
  				row.push("-")
  			}
  			
  			if(testResult["total_students"]){
  				row.push(testResult["rank"]+"/"+testResult["total_students"])
  			}else{
  				row.push(testResult["rank"])
  			}
  			rows.push(row)	
  		}
  		this.setState({rows: rows, columns: columns})
  	}


  	goBack = (e) => {
  	  e.preventDefault();
  	  const student_id = qs.parse(this.props.location.search).student_id; 
  	  this.props.history.push('/analysis/student?student_id='+student_id);
  	}

  	getReportName(name){
    	return (name ? "_" + name : "")
  	}

  	render (){
	  	const {classes} = this.props;
	  	const {student_details,rows,columns} = this.state;
	  	return(
	  		<React.Fragment>  
	  		<Link className={classes.backLink}
	          component="button"
	          variant="body2" 
	          onClick={this.goBack}>
	          Back to Student Reports
	        </Link>
	        { student_details &&
	  			<StudentCard data={student_details}/>
	  		}
	  		<div className={classes.section}>
            	<MuiTable columns={columns} rows={rows} sort={"false"} title={"Student Report"}
            	downloadTitle={"Student Full Report"+this.getReportName(student_details["username"])}/>
          		
          	</div>		
	  		</React.Fragment>
	  	);
	}
}

export default withStyles(styles)(AnalystStudentReport);