import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import Button from "CustomButtons/Button.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import testModelFormStyle from "components/styles/TestModelFormStyle.js";


class QuestionHeader extends React.Component {

constructor(props) {
	super(props);
}

render() {
	const { classes, number, pattern, subject,positiveMarks,negativeMarks,
		partialMarks,isEditMode, handleSaveQuestionData,
		handleDiscardQuestionData,handleClearQuestionData,questionSavedIndicator,
		handleTestModeChange } = this.props;
	const style={
  padding:'0px 12px'
	};
	const style2={
		//color: "rgba(0, 0, 0, 0.87)",
	//fontWeight: "bold",
		fontFamily: "serif",
		fontSize: "14px",
		color: "rgb(60, 72, 88)"
	};
	const style3={
		color: "rgb(103, 106, 108)",
		fontWeight: "300"
		//color: "rgba(0, 0, 0, 0.87)",
	//fontWeight: "bold",
		//fontFamily: "serif",
		//fontSize: "14px"
	};
	return(
		<div>
		<GridContainer style={{margin: "auto"}}>
			<div style={{padding: "10px 10px", width: "100%" }}/>
			<GridItem  xs={12} sm={12} md={6} >
				<Grid container  style={{paddingTop: "5px", paddingBottom: "5px", margin: "-12px"}}>
					<Grid item style={style}>
						<h5 style={style2}><strong>Question No: {number}</strong></h5>
					</Grid>
				</Grid>
				<Grid container style={{paddingTop: "5px", paddingBottom: "5px", margin: "-12px"}}>
					<Grid item style={style}>
						<h5 style={style2}><strong style={style3}>Subject:</strong> {subject}</h5>
					</Grid> 
					<Grid item style={style} >
						<h5 style={style2}><strong style={style3}>Pattern:</strong> {pattern}</h5>
					</Grid>
				</Grid>
				<Grid container style={{paddingTop: "5px", paddingBottom: "5px", margin: "-12px"}}>
					<Grid item style={style}>
						<h5 style={style2}><strong style={style3}>Marking Scheme:</strong></h5>
					</Grid>
					<Grid item style={style}>
						<h5 style={style2}><strong style={style3}>Positive:</strong> {positiveMarks > 0 ? (+positiveMarks) : "0"} Marks</h5>
					</Grid> 
					<Grid item style={style}>
						<h5 style={style2}><strong style={style3}>Negative:</strong> {negativeMarks > 0 ? (-negativeMarks) : "0"} Marks</h5>
					</Grid>
					<Grid item style={style}>
						<h5 style={style2}><strong style={style3}>Partial:</strong> {partialMarks>0 ? partialMarks : "NA"}</h5>
					</Grid> 
					{/*<Grid item xs={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
						<p>This question has single digit integer answer.</p> 
					</Grid> */}
				</Grid>
			</GridItem> 
			<GridItem  xs={12} sm={12} md={6} >
				<List className={classes.list} style={{padding: "0px"}}>
                  {isEditMode &&
                  <React.Fragment>
                  <ListItem className={classes.listItem} 
                  style={{paddingTop: "0px", paddingBottom: "0px", borderLeft: "1px solid #e5e5e5"}}>
                    <Button
                      color="transparent"
                      name="view"
                      onClick={handleTestModeChange}
                      style={{ 
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px"}}
                      className={classes.navLink}
                      size="sm"
                    >
                    Preview
                    </Button>
                  </ListItem>
                  <ListItem className={classes.listItem} style={{paddingTop: "0px"}}>
                    {/*<Button
                      color="transparent"
                      onClick={handleDiscardQuestionData}
                      style={{ 
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px"}}
                      className={classes.navLink}
                      size="sm"
                    >
                    Discard
                    </Button>*/}
                    <Button
                      color="transparent"
                      onClick={handleClearQuestionData}
                      style={{ 
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px"}}
                      className={classes.navLink}
                      size="sm"
                    >
                    Clear All
                    </Button>
                  </ListItem>
                  <ListItem className={classes.listItem} style={{paddingTop: "0px"}} >
                    <Button
                      name="save"
                      color="transparent"
                      onClick={handleSaveQuestionData}
                      className={classes.navLink}
                      style={{ 
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px"}}
                      size="sm"
                      data-cy="create test save question btn"
                    >
                    Save
                    </Button>
                  </ListItem>
                  </React.Fragment>
              	  }
                  {!isEditMode &&
                  <ListItem className={classes.listItem} style={{paddingTop: "0px"}}>
                    <Button
                      color="transparent"
                      name="edit"
                      onClick={handleTestModeChange}
                      style={{ 
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "15px",
                      paddingRight: "15px"}}
                      className={classes.navLink}
                      size="sm"
                    >
                    Edit
                    </Button>
                  </ListItem>
                  }
                </List>
				{questionSavedIndicator && 
                	<p style={{color:"#4caf50"}}>Question Saved</p>
                }
			</GridItem> 
		</GridContainer>
		</div>
		);
}

}

export default withStyles(testModelFormStyle)(QuestionHeader);