import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ScheduleTestCard from "../core/ScheduleTestCard.jsx"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {  withStyles } from '@material-ui/core/styles';

import ExpandableTable from '../core/ExpandableTable.jsx';
import MuiTable from '../core/MuiTable.jsx';
import qs from 'query-string';
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

const styles = ((theme) => ({
  	section:{
  		padding: "16px 0",
      [theme.breakpoints.down('sm')]: {
       padding: "16px 0"
      },
  	},
    backLink:{
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    filters:{
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    filterSection:{
      paddingTop: "32px",
      marginRight: "16px",
      [theme.breakpoints.down('xs')]: {
        margin: "0px",
        padding: "16px 0 0"
      },
    },
    root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      backgroundColor: "white"
    },
  },
  }));

class AnalystTestToppers extends Component {

	state ={
		paperTab: 0,
    columns: [],
    rows: [],
    errorMessages: [],
    schedule_data: "",
    topPercentage: 10,
    branchTable: []
	}

  componentDidMount(){
    this.fetchMarksReport(this.state.topPercentage);
  }

  fetchMarksReport(topPercentage){
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    var url = '/analysis/test/marks-report.json?paper_id=' + paper_id
    if(topPercentage){
      url = url+'&top_percentage='+topPercentage
    }
    $.ajax({
      type: 'GET',
      url: getBaseURL() + url,
    }).done((data, status, jqXHR)=>{
      this.processDataForTable(data); 
      this.processDataForBranchList(data["branch_students_list"]); 
      this.processDataForScheduleCard(data["schedule_data"]); 
    }).fail((jqXHR, textStatus, errorThrown) => {
      //TODO: handle properly
        var errorMessages = this.state.errorMessages;
        errorMessages.push({message: jqXHR["responseJSON"].message})
        this.setState({errorMessages: jqXHR["responseJSON"].message});
    })
  }


  processDataForTable(data){
    var schedule_data = data["schedule_data"]

    var subArray = []
    var columns = []    
    columns.push("UserId")
    columns.push("Name")
    columns.push("Campus")
    columns.push("Section")
    if (schedule_data && schedule_data["subjects_info"]){
      for (let sub_info of schedule_data["subjects_info"]) { 
          columns.push(sub_info.sub_short_name + " ("+sub_info.sub_total_marks+")");
          subArray.push(sub_info.sub_short_name)
      }
    }
    columns.push("Total")
    columns.push("Campus Rank")
    columns.push("Section Rank")
    columns.push("Overall Rank" + " ("+schedule_data.attended_students+")")

    
    var rows = []
    var user_results = data["user_results"]
    if (user_results){
      for (let user_result of user_results) { 
          var row = []
          row.push(user_result.username)
          row.push(user_result.name)
          row.push(user_result.branch_name)
          row.push(user_result.section_name)
          if(user_result["subject_results"]){
            var subIndx = 0;
            for (let sub_result of user_result["subject_results"]) {
              if(sub_result.total_marks && subArray[subIndx] == sub_result.sub_short_name) {
                row.push(sub_result.total_marks + " ("+sub_result.rank+")")
              }else{
                row.push("-")
              }
              subIndx++;
            }
          } 
          row.push(user_result.total_marks ? user_result.total_marks : "-" )
          row.push(user_result.branch_rank ? user_result.branch_rank : "-")
          row.push(user_result.section_rank ? user_result.section_rank : "-")
          row.push(user_result.rank ? user_result.rank : "-")
          rows.push(row)
      }
    }
    this.setState({columns: columns, rows: rows})
  }

  processDataForBranchList(branch_students_list){
    var branchTable = []
    var branchNames = []
    var branchStudents = []
    for (let branch of branch_students_list) { 
      branchNames.push(branch["name"])
      branchStudents.push(branch["student_count"])
    }
    branchTable.push(branchNames);
    branchTable.push([branchStudents]);
    this.setState({branchTable: branchTable})
  } 


  processDataForScheduleCard(schedule_data){
    var schedule_data = {
      name: schedule_data["schedule_name"],
      program: schedule_data["schedule_program"],
      test_type: schedule_data["schedule_test_type"],
      user_group: schedule_data["schedule_user_group"],
      start_time: formatDate(schedule_data["schedule_start_time"]),
    }
    this.setState({schedule_data: schedule_data})
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(function () { callback.apply(this, args); }, wait);
    };
  }

  changeTopPercentage = (e) =>{
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        if(e.target.value > 100){
          this.setState({topPercentage: 100})
        }else if(e.target.value == ""){
          this.setState({topPercentage: 0})
        }else{
         this.setState({topPercentage: e.target.value})
        }
        if (!this.debouncedFn) {
          this.debouncedFn =  this.debounce(() => {
             this.fetchMarksReport(this.state.topPercentage);
          }, 500);
        }
        this.debouncedFn();
    }    
  }


  goBack = (e) => {
    e.preventDefault();
    const paper_id = qs.parse(this.props.location.search).paper_id; 
    this.props.history.push('/analysis/test?paper_id='+paper_id);
  }

  getReportName(name){
    return (name ? "_" + name : "")
  }

  render () {
  	const {classes} = this.props;  
    const {schedule_data,columns,rows,topPercentage,branchTable} = this.state;  
  	return(
  		<React.Fragment>
        <Link className={classes.backLink}
          component="button"
          variant="body2" 
          onClick={this.goBack}>
          Back to Test Reports
        </Link>
        { schedule_data &&
  			 <ScheduleTestCard data={schedule_data}/>
  		  }
        <div className={classes.section}>
        <form onSubmit={(e) => e.preventDefault()} className={classes.root} noValidate autoComplete="off">
          <TextField 
          value={this.state.topPercentage} 
          onChange={this.changeTopPercentage}
          label="Top Percentage (%)" 
          variant="outlined" />
        </form>
          <MuiTable columns={columns} rows={rows} title={"Top " +topPercentage+ "% Students Marks List"}
            downloadTitle={"Top " +topPercentage+ "% Students Marks List"+this.getReportName(schedule_data["name"])}/>
        </div>
        <div className={classes.section}>
            <MuiTable columns={branchTable[0]} rows={branchTable[1]} title={"Top " +topPercentage+ "% Branch-wise Students List"}
            downloadTitle={"Top " +topPercentage+ "% Branch-wise Students List"+this.getReportName(schedule_data["name"])}/>
        </div>
  		</React.Fragment>
  	);}
}

export default withStyles(styles)(AnalystTestToppers);