import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import $ from "jquery";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import TestHeader from "components/test/view/TestHeader.jsx";
import TestQuestionsContainerListView from "components/test/view/TestQuestionsContainerListView";

import RepoFilters from "components/lov/RepoFilters";
import InputLabel from "@material-ui/core/InputLabel";

import Button from "CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";

import TestQuestionsContainer from "components/test/view/TestQuestionsContainer";

class DatasetMain extends React.Component {
  state = {
    testData: {},
    testInfo: {},
    totalQuestions: 0,
    embeddedFormMinimizedMode: false,
    isEditMode: false,
    currentQuestionData: {},
    questionSavedIndicator: false,
    isDatabaseEditViewMode: false,
    datasetQuestionsPaginationLimit: 20,
    datasetQuestionPaginationParams: {
      page: 0,
      rowsPerPage: 20,
      rowsPerPageOptions: [20],
      count: 100,
    },
    isAutoFetchMode: false,
    autoFetchSampleSize: 5,
  };

  fetchQuestionFromDatasetWithParams(testInfo, queryParams) {
    var url = getBaseURL() + "/repo/get-questions.json";
    if (queryParams && queryParams["limit"]) {
      url =
        url +
        "?limit=" +
        queryParams["limit"] +
        "&offset=" +
        queryParams["offset"];
    }
    let allFiltersData = {
      subjectFilter: [],
      topicFilter: [],
      subTopicFilter: [],
      levelFilter: [],
      patternFilter: [],
      programFilter: [],
      courseFilter: [],
      sourceFilter: [],
      conceptFilter: [],
      otherTagFilter: [],
    };
    if (testInfo["subject"]) {
      allFiltersData["subjectFilter"].push(testInfo["subject"]);
    }
    if (testInfo["subjectChapter"]) {
      allFiltersData["topicFilter"].push(testInfo["subjectChapter"]);
    }
    if (testInfo["chapterTopic"]) {
      allFiltersData["subTopicFilter"].push(testInfo["chapterTopic"]);
    }
    if (testInfo["questionLevel"]) {
      allFiltersData["levelFilter"].push(testInfo["questionLevel"]);
    }
    if (testInfo["questionPattern"]) {
      allFiltersData["patternFilter"].push(testInfo["questionPattern"]);
    }
    if (testInfo["questionProgram"]) {
      allFiltersData["programFilter"].push(testInfo["questionProgram"]);
    }
    if (testInfo["questionCourse"]) {
      allFiltersData["courseFilter"].push(testInfo["questionCourse"]);
    }
    if (testInfo["questionSource"]) {
      allFiltersData["sourceFilter"].push(testInfo["questionSource"]);
    }
    if (testInfo["questionConcept"]) {
      allFiltersData["conceptFilter"].push(testInfo["questionConcept"]);
    }
    if (testInfo["questionOtherTags"]) {
      allFiltersData["otherTagFilter"].push(testInfo["questionOtherTags"]);
    }

    let payload = { filters: allFiltersData };
    if (this.state.isAutoFetchMode && this.state.autoFetchSampleSize > 0) {
      payload["autoFetch"] = "Y";
      payload["randomSampleSize"] = this.state.autoFetchSampleSize;
    }

    $.ajax({
      type: "POST",
      url: url, //Hard Coded TODO
      //dataType: "JSON",
      data: payload,
    })
      .done((data) => {
        if (data && data["questions"]) {
          var datasetQuestionPaginationParams =
            this.state.datasetQuestionPaginationParams;
          datasetQuestionPaginationParams["count"] = data["total_questions"];

          let processedRepoData = [];
          let repoQuestions = data["questions"];
          for (var qIndex in repoQuestions) {
            let processedRepoQuestion = repoQuestions[qIndex];
            processedRepoQuestion["question_content"] =
              repoQuestions[qIndex]?.question_data;

            let qKey = repoQuestions[qIndex]?.question_data?.key?.trim();
            processedRepoQuestion["key"] = [];
            if (qKey) {
              if (
                isNaN(qKey) &&
                ["A", "B", "C", "D"].includes(qKey?.toUpperCase())
              ) {
                processedRepoQuestion["key"] = [
                  [(qKey?.toUpperCase()?.charCodeAt(0) - 64)?.toString()],
                ];
              } else if (["1", "2", "3", "4"].includes(qKey)) {
                processedRepoQuestion["key"] = [[qKey]];
              } else {
                //integer or numerical questions
                processedRepoQuestion["key"] = [[qKey]];
              }
            }

            processedRepoQuestion["solution"] = JSON.stringify(
              repoQuestions[qIndex]?.question_data?.solution
            );
            processedRepoQuestion["pattern_short_name"] = "MCQ";
            processedRepoQuestion["question_no"] =
              (parseInt(queryParams["offset"])
                ? parseInt(queryParams["offset"])
                : 0) +
              (parseInt(qIndex) ? parseInt(qIndex) : 0) +
              1;
            processedRepoData.push(processedRepoQuestion);
          }
          this.setState({
            testData: { questions: processedRepoData },
            totalQuestions: data["questions"].length,
            datasetQuestionPaginationParams: datasetQuestionPaginationParams,
          });

          if (this.props.isEmbeddedMode) {
            this.props.getDatasetQuestionsCallback(
              data,
              this.state.isAutoFetchMode
            );
          }
        }
      })
      .fail((jqXHR, textStatus, error) => {
        alert(
          jqXHR?.responseJSON?.message
            ? jqXHR?.responseJSON?.message
            : "Something went wrong. Please try again or contact administrator!"
        );
        return;
      });
  }

  fetchQuestionFromDataset(testInfo) {
    const defaultQueryParams = {
      limit: this.state.datasetQuestionsPaginationLimit,
      offset: 0,
    };
    this.fetchQuestionFromDatasetWithParams(testInfo, defaultQueryParams);
    var datasetQuestionPaginationParams = {
      page: 0,
      rowsPerPage: 20,
      rowsPerPageOptions: [20],
      count: 100,
    };
    this.setState({ datasetQuestionPaginationParams });
  }

  handleOnChangeLOVTestInfo(e, obj) {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[1];
    var testInfo = this.state.testInfo;
    testInfo[attr] = e.target.value;
    if (obj && obj.id) {
      //Save Id as for for LOV's
      testInfo[attr + "Id"] = obj.id;
    } else if ((attr = "questionPattern")) {
      testInfo[attr + "Id"] = "";
    }

    if (attr == "subject") {
      testInfo["subjectChapter"] = "";
      testInfo["subjectChapterId"] = "";

      testInfo["chapterTopic"] = "";
      testInfo["chapterTopicId"] = "";
    }
    if (attr == "subjectChapter") {
      testInfo["chapterTopic"] = "";
      testInfo["chapterTopicId"] = "";
    }
    //if(attr=="chapterTopic"){
    //	testInfo["questionLevel"] = "";
    //	testInfo["questionLevelId"] = "";
    //}
    //if(attr=="questionLevel"){
    //	testInfo["questionPattern"] = "";
    //	testInfo["questionPatternId"] = "";
    //}

    //this.fetchQuestionFromDataset(testInfo);

    this.setState({
      testInfo: testInfo,
    });
  }

  handleReportQuestion = (e) => {
    var confirm_flag = confirm("Are you sure?");
    if (!confirm_flag) {
      e.preventDefault();
      return;
    }
    const targetName = e.currentTarget.name;
    const datasetQuestionId = targetName.split("_")[1];
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/report-dataset-question?dataset_question_id=" +
        datasetQuestionId, //Hard Coded TODO
      dataType: "JSON",
    }).done((data) => {
      if (data["isUpdated"]) {
        this.refreshQuestionFromDataset();
      }
    });
  };

  refreshQuestionFromDataset() {
    this.fetchQuestionFromDataset(this.state.testInfo);
  }

  toogleEmbeddedFormMinimizedMode = (e) => {
    if (!this.state.embeddedFormMinimizedMode) {
      this.fetchQuestionFromDataset(this.state.testInfo);
    }

    this.setState({
      embeddedFormMinimizedMode: !this.state.embeddedFormMinimizedMode,
    });
  };

  handleAutoFetchQuestions = (e) => {
    this.fetchQuestionFromDataset(this.state.testInfo);
    this.setState({
      embeddedFormMinimizedMode: !this.state.embeddedFormMinimizedMode,
    });
  };

  handleTestModeChange = (e) => {
    e.preventDefault();
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  handleEditTestData = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    if (!component) {
      component = e.target.name;
    }
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "options") {
      for (var optionIndex in currentQuestionData["question_content"][
        "options"
      ]) {
        if (
          currentQuestionData["question_content"]["options"][optionIndex]
            .label == compArray[1]
        ) {
          currentQuestionData["question_content"]["options"][
            optionIndex
          ].content[compArray[2]] = e.currentTarget.value;
        }
      }
    } else if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm[compArray[1]][compArray[2]] = e.currentTarget.value;
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "key") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      if (currentQuestionData["pattern_short_name"] == "NUMERICAL") {
        if (validateNumberFormatForUpto2DecimalNumbers(e.currentTarget.value)) {
          var str = e.currentTarget.value;
          // if(str && str[str.length -1]!="."){
          //   str = parseFloat(str).toString();
          // }
          jsonForm[compArray[1]][0] = str;
        }
      } else if (currentQuestionData["pattern_short_name"] == "INTEGER") {
        if (validateNumberFormatForIntegerNumbers(e.currentTarget.value)) {
          jsonForm[compArray[1]][0] = e.currentTarget.value;
        }
      } else if (currentQuestionData["pattern_short_name"] == "MM") {
        jsonForm[compArray[1]] = e.target.value;
      } else if (currentQuestionData["pattern_short_name"] == "MAQ") {
        jsonForm[compArray[1]] = e.target.value;
      } else {
        jsonForm[compArray[1]][0] = e.target.value;
      }
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ][compArray[3]] = e.currentTarget.value;
    } else {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ] = e.currentTarget.value;
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleSaveQuestionData = (e) => {
    e.preventDefault();
    if (!this.state.isEditMode) {
      return;
    }

    if (!confirm("This change cannot be reverted. Are you sure?")) {
      return;
    }

    var currentQuestionData = this.state.currentQuestionData;
    var finalPostQuestionData = {};
    var postQuestionData = {};
    postQuestionData["dataset_question_id"] = currentQuestionData["id"];
    postQuestionData["question_content"] = JSON.stringify(
      currentQuestionData["question_content"]
    );
    postQuestionData["question_key"] = currentQuestionData["key"];
    postQuestionData["question_solution"] = currentQuestionData["solution"];
    postQuestionData["base64_encoded"] = JSON.stringify(
      currentQuestionData["base64_encoded"]
    );
    if (!currentQuestionData["base64_encoded"]) {
      postQuestionData["base64_encoded"] = JSON.stringify({});
    }
    finalPostQuestionData["dataset_question"] = postQuestionData;

    var saveActionSourceComponent = "";
    if (e && e.currentTarget && e.currentTarget.name) {
      saveActionSourceComponent = e.currentTarget.name;
    }

    $.ajax({
      type: "POST",
      url: getBaseURL() + "/dataset_questions",
      data: finalPostQuestionData,
    })
      .done((response, status, jqXHR) => {
        if (saveActionSourceComponent == "save") {
          this.setState({ questionSavedIndicator: true });
        }
        this.refreshQuestionFromDataset();
      })
      .fail((jqXHR, textStatus, error) => {
        alert(jqXHR["responseText"]);
        return;
      });
  };

  handleClearQuestionData = (e) => {
    e.preventDefault();
    alert("Clear action not supported on questions from content repository.");
  };

  handleAddTextComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm.splice(compArray[1] + 1, 0, ["", ""]);
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else {
      currentQuestionData["question_content"][compArray[0]].splice(
        compArray[1] + 1,
        0,
        ["", ""]
      );
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleAddListComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"]["list1"].push([["", ""]]);
      currentQuestionData["question_content"]["list2"].push([["", ""]]);
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveListComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"]["list1"].pop();
      currentQuestionData["question_content"]["list2"].pop();
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveTextComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "solution") {
      if (compArray[1] > 0) {
        var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
        jsonForm.splice(compArray[1], 1);
        currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
      }
    } else {
      if (compArray[1] > 0) {
        currentQuestionData["question_content"][compArray[0]].splice(
          compArray[1],
          1
        );
      }
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveImageComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "options") {
      for (var optionIndex in currentQuestionData["question_content"][
        "options"
      ]) {
        if (
          currentQuestionData["question_content"]["options"][optionIndex]
            .label == compArray[1]
        ) {
          currentQuestionData["question_content"]["options"][
            optionIndex
          ].content[compArray[2]] = "";
        }
      }
    } else if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm[compArray[1]][compArray[2]] = "";
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ][1] = "";
    } else {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ] = "";
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handlePasteEvent = (e) => {
    var clipboardData = e.clipboardData;
    if (clipboardData) {
      if (clipboardData.items.length == 0) return;
      $.each(
        clipboardData.items,
        function (i, item) {
          if (item.type.indexOf("image") !== -1) {
            this.insertBinaryImage(e, item.getAsFile());
          }
        }.bind(this)
      );
      return false;
    }
  };

  handleImageFileUpload = (e) => {
    if (
      e.target.files.length > 0 &&
      e.target.files[e.target.files.length - 1].type.indexOf("image") !== -1
    ) {
      this.insertBinaryImage(e, e.target.files[0]);
    }
  };

  insertBinaryImage(e, file) {
    var reader = new FileReader();
    var component = e.target.name;
    reader.addEventListener(
      "loadend",
      function () {
        var img = new Image();
        img.src = reader.result;
        //img.height = "200";
        //img.width="200";

        var compArray = component.split("_");
        var currentQuestionData = this.state.currentQuestionData;
        const test_id = this.state.testId;
        const timestamp = new Date().getUTCMilliseconds();

        var imgExtension = "";
        if (img.src.indexOf("svg+xml") !== -1) {
          imgExtension = ".svg";
          // }else if(img.src.indexOf('wmf') !== -1){
          //   imgExtension = ".wmf"
        } else if (img.src.indexOf("png") !== -1) {
          imgExtension = ".png";
        } else if (img.src.indexOf("jpg") !== -1) {
          imgExtension = ".jpg";
        } else if (img.src.indexOf("jpeg") !== -1) {
          imgExtension = ".jpeg";
        }

        if (!imgExtension) {
          alert(
            "Image format not supported. Please upload images in supported format."
          );
          return;
        }

        var image_path =
          compArray[0] +
          "_" +
          currentQuestionData["id"] +
          "_" +
          compArray[1] +
          "_inline_" +
          timestamp +
          imgExtension;

        if (compArray[0] == "list1" || compArray[0] == "list2") {
          image_path =
            compArray[0] +
            "_" +
            currentQuestionData["id"] +
            "_" +
            compArray[1] +
            "_" +
            compArray[2] +
            "_inline_" +
            timestamp +
            imgExtension;
        }

        var base64_encoded = {};
        if (currentQuestionData["base64_encoded"]) {
          base64_encoded = currentQuestionData["base64_encoded"];
        }
        if (img.src) {
          base64_encoded[image_path] = img.src;
          currentQuestionData["base64_encoded"] = base64_encoded;
          if (compArray[0] == "options") {
            for (var optionIndex in currentQuestionData["question_content"][
              "options"
            ]) {
              if (
                currentQuestionData["question_content"]["options"][optionIndex]
                  .label == compArray[1]
              ) {
                if (
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content.length >
                  parseInt(compArray[2]) + 1
                ) {
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content[parseInt(compArray[2]) + 1] =
                    "/get-image?name=" + image_path;
                } else {
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content.append("/get-image?name=" + image_path);
                }
              }
            }
          } else if (compArray[0] == "solution") {
            var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
            jsonForm[compArray[1]][1] = "/get-image?name=" + image_path;
            currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
          } else if (compArray[0] == "list1" || compArray[0] == "list2") {
            currentQuestionData["question_content"][compArray[0]][compArray[1]][
              compArray[2]
            ][1] = "/get-image?name=" + image_path;
          } else {
            currentQuestionData["question_content"][compArray[0]][
              compArray[1]
            ][1] = "/get-image?name=" + image_path;
          }
          this.setState({ currentQuestionData, questionSavedIndicator: false });
          this.handleSaveQuestionData(e);
        }
      }.bind(this)
    );
    reader.readAsDataURL(file);
  }

  toggleDatabaseViewMode = (e) => {
    this.setState({
      isDatabaseEditViewMode: !this.state.isDatabaseEditViewMode,
    });
  };

  handleEditDatabaseQuestion = (e) => {
    var confirm_flag = confirm("Are you sure?");
    if (!confirm_flag) {
      e.preventDefault();
      return;
    }
    const targetName = e.currentTarget.name;
    const datasetQuestionId = targetName.split("_")[1];
    const testData = this.state.testData;
    const filteredQuestions = testData["questions"].filter(
      (question) => question.id == datasetQuestionId
    );
    if (filteredQuestions && filteredQuestions.length > 0) {
      var currentQuestionData = JSON.parse(
        JSON.stringify(filteredQuestions[0])
      );
      currentQuestionData["key"] = JSON.stringify(currentQuestionData["key"]);
      this.setState({
        currentQuestionData: currentQuestionData,
        isDatabaseEditViewMode: true,
      });
    }
  };

  handleDatasetQuestionsPagination = (e, page) => {
    const limit = this.state.datasetQuestionsPaginationLimit;
    const queryParams = {
      limit: limit,
      offset: limit * page,
    };
    this.fetchQuestionFromDatasetWithParams(this.state.testInfo, queryParams);
    var datasetQuestionPaginationParams =
      this.state.datasetQuestionPaginationParams;
    datasetQuestionPaginationParams["page"] = page;
    this.setState({ datasetQuestionPaginationParams });
  };

  render() {
    const {
      testInfo,
      totalQuestions,
      testData,
      embeddedFormMinimizedMode,
      isEditMode,
      currentQuestionData,
      isDatabaseEditViewMode,
    } = this.state;
    const {
      hideTestHeader,
      isEmbeddedMode,
      getDatasetQuestionsCallback,
      defaultTestInfo,
      fetchedQuestionsSourceIds,
    } = this.props;
    const style3 = {
      fontFamily: "sans-serif",
      paddingTop: "0px",
      paddingBottom: "20px",
      textAlign: "center",
      margin: "auto",
      color: "rgb(60, 72, 88)",
    };
    const embeddedStyle3 = {
      fontFamily: "sans-serif",
      paddingTop: "0px",
      paddingBottom: "10px",
      textAlign: "center",
      margin: "auto",
      fontSize: "1rem",
      color: "rgb(60, 72, 88)",
    };
    const embeddedStyle4 = {
      fontFamily: "sans-serif",
      //paddingTop: "0px",
      //paddingBottom: "10px",
      //textAlign: "center",
      marginLeft: "10%",
      marginRight: "20%",
      marginTop: "10px",
      fontSize: "1rem",
      color: "rgb(60, 72, 88)",
    };
    const embeddedStyle5 = {
      fontFamily: "sans-serif",
      //paddingTop: "0px",
      //paddingBottom: "10px",
      //textAlign: "center",
      marginLeft: "20px",
      marginRight: "20%",
      marginTop: "10px",
      fontSize: "1.2rem",
      fontWeight: "400",
      color: "rgb(60, 72, 88)",
    };
    const buttonStyle = {
      //float: "right",
      color: "white",
      position: "relative",
      fontSize: "12px",
      textTransform: "capitalize",
      borderRadius: "3px",
      background: "#3f51b5",
      display: "inline-flex",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "15px",
      paddingRight: "15px",
      marginBottom: "15px",
      "&:hover,&:focus": {
        color: "white",
        background: "#57849b",
      },
    };
    const buttonStyle2 = {
      //float: "right",
      color: "white",
      position: "relative",
      fontSize: "12px",
      textTransform: "capitalize",
      borderRadius: "3px",
      background: "#3f51b5",
      display: "inline-flex",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "15px",
      paddingRight: "15px",
      marginBottom: "15px",
      marginLeft: "auto",
      marginRight: "20px",
      "&:hover,&:focus": {
        color: "white",
        background: "#57849b",
      },
    };
    const style2 = {
      //color: "rgba(0, 0, 0, 0.87)",
      //fontWeight: "bold",
      fontFamily: "serif",
      fontSize: "14px",
      color: "rgb(60, 72, 88)",
      marginLeft: "10%",
      marginTop: "0px",
      //color: "rgb(60, 72, 88)",
      //lineHeight: "1.55em",
      //marginTop: "10px"
    };
    const style4 = {
      color: "rgb(103, 106, 108)",
      fontWeight: "300",
      //color: "rgba(0, 0, 0, 0.87)",
      //fontWeight: "bold",
      //fontFamily: "serif",
      //fontSize: "14px"
    };
    return (
      <div>
        <GridContainer
          style={{ margin: "auto" }}
          spacing={isEmbeddedMode ? 8 : 16}
        >
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <div style={{ height: "auto", backgroundColor: "white" }}>
              {!hideTestHeader && <TestHeader {...this.props} />}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={isEmbeddedMode ? 12 : 3}>
            {!embeddedFormMinimizedMode && (
              <React.Fragment>
                <div
                  style={
                    isEmbeddedMode
                      ? {
                          paddingTop: "15px",
                          backgroundColor: "white",
                          textAlign: "center",
                        }
                      : {
                          paddingTop: "35px",
                          backgroundColor: "white",
                          textAlign: "center",
                        }
                  }
                >
                  {isEmbeddedMode && (
                    <h3 style={embeddedStyle3}>
                      {"Fetch Questions - Content Repository"}
                    </h3>
                  )}
                  <h3 style={isEmbeddedMode ? embeddedStyle3 : style3}>
                    {"Select Details"}
                  </h3>
                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Programs
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"programs"}
                        name="testInfo_questionProgram"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionProgram"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Courses
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"courses"}
                        name="testInfo_questionCourse"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionCourse"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Subject
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"subjects"}
                        name="testInfo_subject"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["subject"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                        includeId={true}
                      />
                    </div>
                    <div style={{ padding: "10px" }} />
                  </React.Fragment>

                  {(testInfo && testInfo["subject"]
                    ? true
                    : defaultTestInfo && defaultTestInfo["subject"]
                    ? true
                    : false) && (
                    <React.Fragment>
                      <InputLabel shrink htmlFor="material2">
                        Chapter
                      </InputLabel>
                      <div style={{ paddingTop: "5px" }}>
                        <RepoFilters
                          filterName={"topics"}
                          name="testInfo_subjectChapter"
                          formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                          value={testInfo["subjectChapter"]}
                          parentName={"subjects"}
                          parentValue={testInfo["subject"]}
                          change={this.handleOnChangeLOVTestInfo.bind(this)}
                          includeId={true}
                          subject={
                            testInfo && testInfo["subject"]
                              ? testInfo["subject"]
                              : defaultTestInfo && defaultTestInfo["subject"]
                              ? defaultTestInfo["subject"]
                              : ""
                          }
                          source="dataset"
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div
                    style={
                      isEmbeddedMode ? { padding: "10px" } : { padding: "20px" }
                    }
                  />

                  <React.Fragment>
                    <InputLabel shrink htmlFor="material2">
                      Topic
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"sub-topics"}
                        name="testInfo_chapterTopic"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["chapterTopic"]}
                        parentName={"topics"}
                        parentValue={testInfo["subjectChapter"]}
                        refreshParentValue1={testInfo["subject"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                        includeId={true}
                        chapterId={testInfo["subjectChapterId"]}
                      />
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Concept
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"concepts"}
                        name="testInfo_questionConcept"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionConcept"]}
                        parentName={"subtopics"}
                        parentValue={testInfo["chapterTopic"]}
                        refreshParentValue1={
                          testInfo["subject"] + "_" + testInfo["subjectChapter"]
                        }
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Levels
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"levels"}
                        name="testInfo_questionLevel"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionLevel"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                        includeId={true}
                        topicId={testInfo["chapterTopicId"]}
                      />
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Question Patterns
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"patterns"}
                        name="testInfo_questionPattern"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionPattern"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                        includeId={true}
                        topicId={testInfo["chapterTopicId"]}
                        level={testInfo["questionLevelId"]}
                      />
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Sources
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"sources"}
                        name="testInfo_questionSource"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionSource"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div
                      style={
                        isEmbeddedMode
                          ? { padding: "10px" }
                          : { padding: "20px" }
                      }
                    />
                    <InputLabel shrink htmlFor="material2">
                      Other Tags
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <RepoFilters
                        filterName={"other-tags"}
                        name="testInfo_questionOtherTags"
                        formControlWidth={isEmbeddedMode ? "40%" : "60%"}
                        value={testInfo["questionOtherTags"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                  </React.Fragment>
                  {totalQuestions > 0 && !isEmbeddedMode && false && (
                    <React.Fragment>
                      <div style={{ padding: "20px" }} />
                      <InputLabel shrink>
                        {"Total Questions Displayed : " + totalQuestions}
                      </InputLabel>
                    </React.Fragment>
                  )}
                  <div style={{ padding: "10px" }} />
                  {isEmbeddedMode && (
                    <>
                      {this.state.isAutoFetchMode ? (
                        <>
                          <div
                            style={{ paddingTop: "5px", paddingBottom: "5px" }}
                          >
                            <TextField
                              label="Number of Questions"
                              // inputProps={{ style: { padding: "12px 12px" } }}
                              variant="outlined"
                              value={this.state.autoFetchSampleSize}
                              onChange={(e) =>
                                this.setState({
                                  autoFetchSampleSize: e.currentTarget.value,
                                })
                              }
                            />
                          </div>
                          <Button
                            color="transparent"
                            onClick={this.handleAutoFetchQuestions}
                            style={buttonStyle}
                            size="sm"
                          >
                            Auto-Fetch Questions
                          </Button>
                          <Button
                            color="transparent"
                            onClick={() =>
                              this.setState({ isAutoFetchMode: false })
                            }
                            style={buttonStyle}
                            size="sm"
                          >
                            Back to Manual Fetch
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            color="transparent"
                            onClick={() =>
                              this.setState({ isAutoFetchMode: true })
                            }
                            style={buttonStyle}
                            size="sm"
                          >
                            Auto-Fetch
                          </Button>
                          <Button
                            color="transparent"
                            onClick={this.toogleEmbeddedFormMinimizedMode}
                            style={buttonStyle}
                            size="sm"
                          >
                            Get Questions
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </React.Fragment>
            )}
            {embeddedFormMinimizedMode && (
              <React.Fragment>
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <h3 style={embeddedStyle4}>{"Selected Questions"}</h3>
                    <Button
                      color="transparent"
                      onClick={this.toogleEmbeddedFormMinimizedMode}
                      style={buttonStyle}
                      size="sm"
                    >
                      Edit Selection
                    </Button>
                  </div>
                  <div>
                    {/*	<h5 style={style2}><strong style={style4}>Chapter:</strong> {testInfo["subjectChapter"]}</h5>
		              	<h5 style={style2}><strong style={style4}>Topic:</strong> {testInfo["chapterTopic"]}</h5>
		              	<h5 style={style2}><strong style={style4}>Level:</strong> {testInfo["questionLevel"]}</h5>
                    <h5 style={style2}><strong style={style4}>Pattern:</strong> {testInfo["questionPattern"]}</h5>*/}
                  </div>
                </div>
              </React.Fragment>
            )}
          </GridItem>
          {isDatabaseEditViewMode &&
            !isEmbeddedMode &&
            testData &&
            testData["questions"] &&
            testData["questions"].length > 0 && (
              <GridItem xs={12} sm={12} md={9}>
                <div style={{ height: "100%", backgroundColor: "white" }}>
                  <div style={{ display: "flex", paddingTop: "15px" }}>
                    <h3 style={embeddedStyle5}>
                      {"Content Repository: Edit Question"}
                    </h3>
                    <Button
                      color="transparent"
                      onClick={this.toggleDatabaseViewMode}
                      style={buttonStyle2}
                      size="sm"
                    >
                      Done
                    </Button>
                  </div>
                  <hr style={{ margin: "5px" }} />
                  <TestQuestionsContainer
                    testData={currentQuestionData}
                    isEditMode={isEditMode}
                    handleTestModeChange={this.handleTestModeChange.bind(this)}
                    handleEditTestData={this.handleEditTestData}
                    handleSaveQuestionData={this.handleSaveQuestionData}
                    handleClearQuestionData={this.handleClearQuestionData}
                    questionSavedIndicator={this.state.questionSavedIndicator}
                    handleAddTextComponent={this.handleAddTextComponent}
                    handleAddListComponent={this.handleAddListComponent}
                    handleRemoveListComponent={this.handleRemoveListComponent}
                    handleRemoveTextComponent={this.handleRemoveTextComponent}
                    handlePasteEvent={this.handlePasteEvent}
                    handleRemoveImageComponent={this.handleRemoveImageComponent}
                    handleImageFileUpload={this.handleImageFileUpload}
                    noQuestionFooter={true}
                    {...this.props}
                  />
                </div>
              </GridItem>
            )}
          <GridItem xs={12} sm={12} md={isEmbeddedMode ? 12 : 9}>
            {!isDatabaseEditViewMode &&
              (!isEmbeddedMode ||
                (isEmbeddedMode &&
                  embeddedFormMinimizedMode &&
                  testData &&
                  testData["questions"] &&
                  testData["questions"].length > 0)) && (
                <div
                  style={
                    isEmbeddedMode
                      ? {
                          height: "75vh",
                          backgroundColor: "white",
                          overflowY: "scroll",
                        }
                      : { height: "100%", backgroundColor: "white" }
                  }
                >
                  <TestQuestionsContainerListView
                    completeTestData={testData}
                    renderShowSolutionButton={isEmbeddedMode ? false : true}
                    title={"Dataset questions"}
                    isFetchQuestionsView={this.props.isFetchQuestionsView}
                    handleFetchQuestion={this.props.handleFetchQuestion}
                    handleReportQuestion={this.handleReportQuestion}
                    handleEditQuestion={this.handleEditDatabaseQuestion}
                    paginationEnabledFlag={true}
                    handlePagination={this.handleDatasetQuestionsPagination}
                    paginationParams={
                      this.state.datasetQuestionPaginationParams
                    }
                    fetchedQuestionsSourceIds={fetchedQuestionsSourceIds}
                    {...this.props}
                  />
                </div>
              )}
            {isEmbeddedMode &&
              embeddedFormMinimizedMode &&
              testData?.questions?.length < 1 && (
                <React.Fragment>
                  <div
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <h3 style={embeddedStyle4}>{"No Questions Found"}</h3>
                  </div>
                </React.Fragment>
              )}
          </GridItem>
          <GridItem xs={12} sm={12} md={1}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default DatasetMain;
