import React, {Component} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import MUIDataTable from "mui-datatables";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";

import Hidden from '@material-ui/core/Hidden';
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import UserGroupModal from './UserGroupModal';
import TestModelFormStyle from "../styles/TestModelFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";



const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px',
      		// width: '200px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...TestModelFormStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  inputLabel:{
  	width: '96%' 
  }
};

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class ManageTestTypes extends Component{
	constructor(props){
		super(props);
		this.state={
			testTypesTableData:[],
			testTypesTableDataMob:[],
			newTestTypeInfo:{},
			isOpen: false,
			successMessage:"",
			errorMessage:"",
			alertOpen:false
		}
	}

	handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successMessage:""});
    this.setState({errorMessage:""})
    this.setState({alertOpen: false});
  };

	handleOnChangeAddTestTypeInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var newTestTypeInfo = this.state.newTestTypeInfo;
 	newTestTypeInfo[attr] = e.target.value;
}

	handleAddNewTestType = (e) => {
		var {name,short,description}= this.state.newTestTypeInfo;

		var postData= {global_test_type:{name, short_name: short, description}};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL()+'/global_test_types',
	    data:postData
    }).done((data)=>{
    	var message="New Test Type Added Successfully";
    	this.setState({successMessage:message});
    	this.setState({alertOpen: true})
    	this.handleToggleModal();
			this.fetchTestTypes();
    }).fail((error)=>{
	    var errorMessage = "Something went wrong!";
	    this.setState({errorMessage});
    	this.setState({alertOpen: true});
	    })
	}

	handleDelete = (id) => {
		$.ajax({
	      type: 'DELETE',
	      url: getBaseURL()+'/global_test_types/'+id.toString()+'.json',
	     	headers: {
	      	'X-CSRF-Token': getCSRFToken()
	      }
	    }).done((response) => {
	    	var message="Test Type Deleted Successfully";
	    	this.setState({successMessage:message});
	    	this.setState({alertOpen: true})
	    	this.fetchTestTypes();
	    }).fail((error)=>{
	    	const {responseJSON}=error;
	    	var errorMessage = responseJSON.message;
		    this.setState({errorMessage});
	    	this.setState({alertOpen: true})
	    })
	}


	handleToggleModal = (e) => {
		var open= this.state.isOpen;
		this.setState({newTestTypeInfo:{}});
		this.setState({isOpen: !open});
	}

	fetchTestTypes = () => {
		$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/get-test-types.json',
    }).done((data)=>{
    	this.populateTestTypesFromResults(data);
    })
	}

	Transition =(props) => {
  return (<Slide direction="down" {...props} />);
	}

	componentDidMount(){
		this.fetchTestTypes();
	}

	populateTestTypesFromResults(data){
	var globalTestTypes = data["test_types"];
	
	var testTypesTableData = [];
	var testTypesTableDataMob = [];
	if(globalTestTypes && globalTestTypes.length > 0){
		for(var i in globalTestTypes){
			testTypesTableData.push([
				globalTestTypes[i].id,
				globalTestTypes[i].name,
				globalTestTypes[i].short_name,
				globalTestTypes[i].description,
			]);

			testTypesTableDataMob.push([
				globalTestTypes[i].id,
				globalTestTypes[i].name,
				globalTestTypes[i].short_name
			]);
		}
		this.setState({testTypesTableData});
		this.setState({testTypesTableDataMob});
	}else{
		this.setState({testTypesTableData: []});
	}
}

	render(){

		const {classes}= this.props;

		const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
		};
		const subtitleStyle={
		    color: "rgb(34, 56, 69)",
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			paddingBottom: "10px",
		};
		const tableTitleStyle={
			fontWeight: "600",
			fontSize: "18px",
			fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
			padding: "0px", margin: "0px",
			paddingTop: "10px",
		};
		const testTypesTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: true,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const testTypesTableColumns = [
			{name: "testTypeId",
	        options: {
	          display: false,
	        }
	    },
			{name: "Name"},
			{name: "Short Name"},
			/*{name: "Subject Test Flag"},*/
			{name: "Description"},
			{	
					name: " ",
					options: {
	              customBodyRender: (value, tableMeta, updateValue) => {
	              	const {rowData}=tableMeta;
              		const id=rowData[0];
	              return (
	 
	                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
	            
	              );
	              }
	          }
			}     
		];

		const testTypesTableMobOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			setRowProps: (row) => {
		        return {
		          style: {textAlign: 'center'}
		        };
		      }
		};

		const testTypesTableMobColumns = [
			{name: "testTypeId",
	        options: {
	          display: false,
	        }
	    },
			{
				name: "Name",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              return (<div>{value}</div>);
              }
          }
			},
			{name: "Short"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
              	const id=rowData[0];
              return (
 
                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
            
              );
              }
          }
		}     
		];
		const FormComponent =  (props) => {
			const {newTestTypeInfo, handleOnChangeAddTestTypeInfo}=props;
				return(
					<React.Fragment>
		      	<GridContainer style={{
		      	backgroundColor: "white",
						paddingTop: "0px",
		      	width: "100%", 
		      	margin: "10px 0px 0px",
		      	marginTop: "0px"}}
		      	spacing={24}>
							<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
				  			<form className={classes.form}>
	              	<TextField variant="outlined" label="Test Type Name" fullWidth placeholder="Enter Test Type Name" 
	              	required className={classes.inputLabel}
	              	value={newTestTypeInfo["name"]}
	              	name="newTestTypeInfo_name"
	              	onChange={handleOnChangeAddTestTypeInfo}/>

  					<TextField variant="outlined" label="Short Name" placeholder="Enter Short Name" 
  					required className={classes.inputLabel}
  					value={newTestTypeInfo["short"]}
	              	name="newTestTypeInfo_short"
	              	onChange={handleOnChangeAddTestTypeInfo}/>

	              	<TextField variant="outlined" label="Test Type Description" fullWidth placeholder="Enter Test Type Description" required
	              	multiline className={classes.inputLabel} 
	              	value={newTestTypeInfo["description"]}
	              	name="newTestTypeInfo_description"
	              	onChange={handleOnChangeAddTestTypeInfo}/>

	              </form>
		          </GridItem>
	          </GridContainer>
        	</React.Fragment>	
				);	
    };
		return(
			<React.Fragment>
		  		<GridContainer style={{
		      		backgroundColor: "white",
					paddingTop: "0px",
		      		paddingBottom: "20px",
		      		width: "100%", 
		      		margin: "10px 0px 0px",
		      		marginTop: "0px"}}
		      		spacing={24}>
		      		{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage Test Types</h1>
		      			<hr/>
		      		</GridItem>
		 
		      		<GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			      			<UserGroupModal formComponent={<FormComponent newTestTypeInfo={this.state.newTestTypeInfo} 
			      			handleOnChangeAddTestTypeInfo={this.handleOnChangeAddTestTypeInfo}/>} 
			      			titleModal={"Add New Test Type"} buttonTitle={"Add Test Type"}
			      			handleClick={this.handleAddNewTestType} open={this.state.isOpen}
			      			handleToggleModal={this.handleToggleModal}/>		
			      	</GridItem>	
			      	<Hidden smDown>	      		
			      		<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Test Types</p>}
			      			  	options={testTypesTableOptions}
			      			  	columns={testTypesTableColumns}  
			      			  	data={this.state.testTypesTableData}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
	      			<Hidden mdUp>
	      				<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none", paddingTop: "24px"}}>
			      			<MuiThemeProvider theme={getMuiThemeForTable}>
			      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Test Types</p>}
			      			  	options={testTypesTableMobOptions}
			      			  	columns={testTypesTableMobColumns}  
			      			  	data={this.state.testTypesTableDataMob}     
			      				/>
			      			</MuiThemeProvider>
			      		</GridItem>
	      			</Hidden>
		      	</GridContainer>
		    </React.Fragment>
		);
	}
}

export default withStyles(styles)(ManageTestTypes);



