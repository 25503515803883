import React from "react";
import {getBaseURL, getMobileAppPlayStoreURL} from "components/helpers/HostURLHelper.jsx";

import $ from 'jquery';
import Header from 'components/Header.jsx';
//import HeaderLeftLinks from 'components/HeaderLeftLinks.jsx';
import HeaderRightLinks from 'components/HeaderRightLinks.jsx';

import withStyles from "@material-ui/core/styles/withStyles";
import userLandingPageStyle from "styles/UserLandingPageStyle.jsx";

//import AllProductSection from "views/LandingPage/Sections/AllProductSection.jsx";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

/*import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";*/

import Button from "CustomButtons/Button.jsx";

/*import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';*/

import { Route, Switch } from "react-router";

import MyHomeRegion from "components/MyHomeRegion.jsx"; 
//import TestRegion from "components/TestRegion.jsx"; 
//import TestInstructions from "components/test/TestInstructions.jsx"; 
import TestMain from "components/TestMain.jsx"; 

import UserSubscriptionsRegion from "components/views/UserSubscriptionsRegion.jsx"; 
import UserTestsRegion from "components/enterprise/UserTestsRegion.jsx"; 
import TestHomeRegion from "components/enterprise/TestHomeRegion.jsx"; 

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Home from "@material-ui/icons/Home";
import Menu from "@material-ui/icons/Menu";
import School from "@material-ui/icons/School";
import AssessmentIcon from '@material-ui/icons/Assessment';

import brand_white from "assets/img/images/brand_white.png";
import TestResultsDashboard from "components/analysis/TestResultsDashboard.jsx";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";
//import {isViewPortSmall} from "components/helpers/ResponsiveActivityHelper.jsx";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AccountCircle from "@material-ui/icons/AccountCircle";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import playstoreImage from "assets/img/images/playstoreImage.png";

import UserAnalysisDashboard from 'components/analysis/UserAnalysisDashboard';


const dashboardRoutes = [];

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class UserLandingPage extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      userdata: "",
	      drawerOpen: true,
	      headerPresent: false,
	      activePage: "",
	      deviceNotSupported: false
	    };
	  }

	componentDidMount() {
		if(!isUserSessionActive())
		{
		 	this.props.history.push('/login');
		 	return
		}

		if(!isWidthUp('sm', this.props.width)){
			this.setState({deviceNotSupported: true});
			return
		}

	    $.ajax({
	          type: "GET",
	          url: getBaseURL() + '/react/test-login.json',
	          dataType: "JSON",
	          //headers: JSON.parse(localStorage.user)
	        }).done((data) => {
	   			this.setState({userdata: data});
	        });
	        
	        if(this.props.location.pathname.startsWith('/home/test')){
	        	this.setState({drawerOpen: false});
	        	this.setState({headerPresent: false});
	  		} else if(this.props.location.pathname.startsWith('/home/analytics')){
        		this.setState({headerPresent: true});
        		this.setState({drawerOpen: false});
  			}

  		this.setActivePage();
  	}


  	componentDidUpdate(prevProps){
    		if(this.props.location.pathname !== prevProps.location.pathname)
  		{
	  		if(this.props.location.pathname.startsWith('/home/test')){
	        	this.setState({drawerOpen: false});
	        	this.setState({headerPresent: false});
	  		}else if(this.props.location.pathname.startsWith('/home/analytics')){
	        	this.setState({headerPresent: true});
	        	this.setState({drawerOpen: false});
	  		}else if(this.props.location.pathname.startsWith('/home')){
	        	this.setState({drawerOpen: true});
	  		}

	  		this.setActivePage();
  		}
  	}

  	goToTest = (e) =>{
 		const test_number = 50;  //TODO HardCoded Values
  		this.props.history.push('/home/test/instructions?test_id=' + test_number);
  	}

  	goToUserSubscriptions = (e) =>{
  		this.props.history.push('/home/subscriptions');
  	}

  	goToUserTests = (e) =>{
  		this.props.history.push('/home/enterprise/mytests');
  	}

  	goToUserAnalysis = (e) => {
  		var userdata = this.state.userdata;
  		this.props.history.push('/home/analytics/myanalysis?user_id='+this.state.userdata.id);
  	}

  	goToHome = (e) =>{
  		this.props.history.push('/home');
  	}

  	setActivePage() {
  		var pathname = this.props.location.pathname
  		var pageName = (pathname.substr(pathname.lastIndexOf('/') + 1));
  		this.setState({activePage: pageName});
  	}

  	handleLogoutUser =(e)=> {
		if(localStorage.activeTestId){
		  if (window.confirm('There is an active test in progress. All your current progress will be lost if you continue. Do you want to continue?')) {
		    localStorage.removeItem("testUserData");
		    localStorage.removeItem("activeTestId");
		    localStorage.removeItem("activeTestQuestionNumber");
		    localStorage.removeItem("activeTestAutoSaved");
		    this.logoutUser();
		  } else {
		      // Do nothing!
		  }
		} else{
		  this.logoutUser();
		}  
  	}

  	logoutUser(){
  		$.ajax({
  		  type: 'GET',
  		  url: getBaseURL() + '/global_users/sign_out'
  		}).done((response, status, jqXHR)=>{
  		  sessionStorage.removeItem('as_username');
  		  sessionStorage.removeItem('user');
		  this.props.history.push('/login');
  		}) 
  		//localStorage.removeItem("user");
  	}

  	redirectToPlayStore(){
  		window.location.href = getMobileAppPlayStoreURL(); 
  		return
  	}

	render() {
		 const { classes} = this.props;
		return (
			<React.Fragment>
			{ this.state.deviceNotSupported &&
				<Dialog
		          classes={{	
		            root: classes.center,
		            paper: classes.modal
		          }}
		          open={true}
		          TransitionComponent={Transition}
		          keepMounted
		          onClose={() => this.handleClose("modal")}
		          aria-labelledby="modal-slide-title"
		          aria-describedby="modal-slide-description">
		          <DialogTitle
		            id="classic-modal-slide-title"
		            disableTypography
		            className={classes.modalHeader}>
			    <h4 className={classes.modalTitle} style={{fontWeight: "bold"}}>Unsupported Device!</h4>
		          </DialogTitle>
		          <DialogContent
		            id="modal-slide-description"
		            className={classes.modalBody}
		            style={{paddingBottom: "5px"}}>
		            <h5>Your device display is too small to load tests in browser.</h5>
		     
	             {/* <p>Please use AssessCore mobile app to take tests in Mobiles and Tablets</p>
	              <h5 style={{marginTop: "30px"}}>Download the Mobile App from Google play store</h5>
		        */}  </DialogContent>
		          <DialogActions
			            className={classes.modalFooter +" " +classes.modalFooterCenter}>
			          {/*  <Button
				          color="transparent"
				          onClick={this.redirectToPlayStore}
				          >
				          <img
				          	style={{width: "100%"}}
				            src={playstoreImage}	              	
				            alt=""
				            />
				          </Button>*/}
			        </DialogActions>
		        </Dialog>
			}
			{ (!this.state.deviceNotSupported) && 
			<div style={{color: "#223845"}}>
			{this.state.drawerOpen || this.state.headerPresent ?
			<Header
			  color="dark"
			  routes={dashboardRoutes}
			  brand={brand_white}
			  fixed
			  //leftLinks={<HeaderLeftLinks />}
			  rightLinks={<HeaderRightLinks handleLogoutUser={this.handleLogoutUser} profileName={this.state.userdata.username} />}
			  // changeColorOnScroll={{
			  //   //height: 200,
			  //   //color: "white"
			  // }}
			/>
			:null
		}
			<div className={classes.main} style={this.state.drawerOpen ? {top: "50px"} : null}> 
		{/*THIS child SHOULD BE A INDIVIDUAL COMPONENT - NAVBAR*/}
				{this.state.drawerOpen ?
			  	<Drawer open={this.state.drawerOpen}
		 	         variant="permanent"
		 	        anchor={"left"}
		 	        classes={{
		 	          paper: classes.drawerPaper
		 	        }}
		 	      	>
		 	        <div className={classes.toolbar} style={{paddingTop: "20px", paddingBottom: "20px",  textAlign: "center"}}> 
		 	        <GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
          				<GridItem xs={12} sm={12} md={12}> 
		 	        		<AccountCircle style={{fontSize: "96px", width: "100%", color: 'rgb(34,56,69)'}}/>
		 	        		<p className={classes.robotoFontRegular}>{this.state.userdata.username}</p>
		 	        		<p style={{fontSize: "0.8rem", margin: "0px"}} className={classes.robotoFontRegular}>{this.state.userdata.name}</p>
		 	        		<p style={{fontSize: "0.8rem", margin: "0px"}} className={classes.robotoFontRegular}>{this.state.userdata.program}</p>
		 	        		<p style={{fontSize: "0.8rem",margin: "0px"}} className={classes.robotoFontRegular}>{this.state.userdata.branch}</p>
		 	        		<p style={{fontSize: "0.8rem",margin: "0px"}} className={classes.robotoFontRegular}>{this.state.userdata.section}</p>
		 	        	</GridItem> 
		 	         </GridContainer>	
		 	        </div>
		 	        
		 	        <List className={classes.list}>
		 	        	<Button
					          color="transparent"
					          onClick={this.goToHome}
					          className={this.state.activePage == "home"? classes.navLinkActive : classes.navLink}
					        >
					        <Home/> Home
					    </Button>
				    </List>
		 	       {/* <List className={classes.list}>
		 	        	<Button
					          color="transparent"
					          onClick={this.goToTest}
					          className={classes.navLink}
					          disabled
					        >
					        <School />   Start Sample Test
					    </Button>
				    </List>
		 	        <Divider />
		 	        <List className={classes.list}>
				        <Button
				          color="transparent"
				          onClick={this.goToUserSubscriptions}
				          className={classes.navLink}
				        >
				         <Menu/>  My Subscriptions
				        </Button>
				        
					</List> 
					<Divider />*/}
					<List className={classes.list}>
				        <Button
				          color="transparent"
				          onClick={this.goToUserTests}
				          className={  	
				          	this.state.activePage == "mytests"? classes.navLinkActive : classes.navLink
				          }	
				        >
				         <School />  My Tests
				        </Button>
				        
					</List>
					<List className={classes.list}>
				        <Button
				          color="transparent"
				          onClick={this.goToUserAnalysis}
				          className={  	
				          	this.state.activePage == "myanalysis"? classes.navLinkActive : classes.navLink
				          }	
				        >
				         <AssessmentIcon />  My Analysis
				        </Button>
				        
					</List>  
 	      		</Drawer>
 	      		: null}
			 	<div className={classes.containerCustom} style={this.state.drawerOpen ? null : {paddingLeft: "0px"}}>
			 		{/*SWITCHING MAIN COMPONENTS BASED ON FLOW*/}
			 		<Switch>
			 		{/*<Route exact path={"/home/test-instructions"} component={TestInstructions} />
			 		<Route exact path={"/home/test"} component={TestRegion} />*/}
			 		<Route path={"/home/test"} component={isWidthUp('sm', this.props.width) ? TestMain : null} />
			 		<Route exact path={"/home"} component={isWidthUp('sm', this.props.width) ? MyHomeRegion : null } />
			 		<Route exact path={"/home/enterprise/mytests"} component={isWidthUp('sm', this.props.width) ? UserTestsRegion : null} />
			 		<Route exact path={"/home/enterprise/testHome"} component={isWidthUp('sm', this.props.width)  ? TestHomeRegion : null} />
			 		<Route exact path={"/home/analytics/test/results"} component={isWidthUp('sm', this.props.width) ? TestResultsDashboard : null} />
{/*			 		<Route exact path={"/home/subscriptions"} component={isWidthUp('sm', this.props.width)  ? UserSubscriptionsRegion : null} />
*/}			  		</Switch>
			  	</div>
			</div>
			</div>
			}
			</React.Fragment>
			);
	}

}

export default withWidth()(withStyles(userLandingPageStyle)(UserLandingPage));
